import styled from 'styled-components';

export const DownloadableTable = styled.table`
  width: 100%; /* Make the table take all the available width */
  border-collapse: collapse; /* Merge borders to create a borderless effect */

  th, td {
    padding: 8px; /* Add some padding to the cells */
    text-align: left; /* Align text to the left */
    border: none; /* Remove borders from table cells */
  }

  thead {
    background-color: #f2f2f2; /* Add a light background to the header for better readability */
  }

  tbody tr:nth-child(even) {
    background-color: #f9f9f9; /* Add a slight background color to even rows for better readability */
  }
`;

