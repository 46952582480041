import React, {useEffect, useState} from 'react';
import {Footer} from '../ui';
import {ToastContainer} from 'react-toastify';
import {CartContextProvider} from '../cart';
import {NotificationContextProvider} from '../notification/contexts/NotificationContext';
import {FilterContextProvider} from '../catalog/infrastructure/FilterContext';
import {UserContextProvider} from '../user/infrastructure/UserContext';
import {Outlet, useNavigate} from 'react-router-dom';
import {useAuthenticator} from '@aws-amplify/ui-react';
import {fetchAuthSession} from '@aws-amplify/auth';
import {NavbarComposer} from '../high-order-context/NavbarComposer';

const PrivatePage = () => {
  const { user } = useAuthenticator(context => [context.user]);
  const navigate = useNavigate();
  const [shouldLoadPage, setShouldLoadPage] = useState();


  useEffect(() => {
    if (shouldLoadPage === false) {
      window.location.href = '/iniciar-sesion';
    }
  }, [shouldLoadPage, navigate]);

  useEffect(() => {
    const isAuthenticated = async () => {
      if (!!user) {
        return setShouldLoadPage(true);
      }
      const session = await fetchAuthSession();
      return setShouldLoadPage(!!session?.userSub);
    };

    isAuthenticated();
  }, [user]);


  if (shouldLoadPage) {
    return (
      <UserContextProvider>
        <CartContextProvider>
          <NotificationContextProvider>
            <FilterContextProvider>
              <ToastContainer position="top-center" />

              <header className="mb-3">
                <NavbarComposer />
              </header>

              <main className="container">
                <Outlet />
              </main>
              <Footer />
            </FilterContextProvider>
          </NotificationContextProvider>
        </CartContextProvider>
      </UserContextProvider>
    );
  } else {
    return <></>;
  }
};

export default PrivatePage;
