import {useCallback, useEffect, useMemo, useState} from 'react';
import {useErrorLoadingState} from '../../helpers/hooks/useErrorLoadingState';
import {useNavigate} from 'react-router-dom';
import {ProductSwiperUI} from './ProductSwiperUI';
import {getProducts} from '../infrastructure/CatalogApi';

const FEATURED_PRODUCTS_FILTER = {
  featured: true
};

// TODO: this component is similar to the ProductSwiper. Find a way to remove this duplication
const useFeaturedProductsState = ({ amount }) => {
  const [page, setPage] = useState(0); // TODO: use pagination
  const [featuredProducts, setFeaturedProducts] = useState([]);

  const productsRemoteData = useErrorLoadingState();

  const loadFeaturedProducts = useCallback(async (config) => {
    const { content } = await getProducts(FEATURED_PRODUCTS_FILTER, config)
    setFeaturedProducts(content);
  }, []);

  useEffect(() => {
    productsRemoteData.run(loadFeaturedProducts, [{ page, amount }]);
  }, [amount, page]);

  return useMemo(() => ({
    featuredProducts,
    productsLoading: productsRemoteData.loading,
    productsError: productsRemoteData.error,
    setPage
  }), [
    featuredProducts,
    productsRemoteData.error,
    productsRemoteData.loading,
  ]);
};

// TODO: replace with ProductSwiper
export const FeaturedProducts = ({ amount = 10 }) => {
  const {
    featuredProducts,
    productsLoading,
    productsError,
  }
    = useFeaturedProductsState({ amount });
  const navigate = useNavigate();

  return (
    <ProductSwiperUI
      data={{
        title: 'Productos destacados',
        products: { value: featuredProducts, loading: productsLoading, error: productsError }
      }}
      actions={{
        onClick: (details) => navigate(`/articulos/${encodeURIComponent(details.pro_codigo)}`)
      }}
    />
  );
};
