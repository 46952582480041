import React, {useEffect, useState} from 'react';
import {ContainerImgLabs, ContainerLabs, SectionImgLabs} from '../../styles/Laboratorios.elements';
import {Link} from 'react-router-dom';
import {RoutePathBreadcrumb} from '../../../ui';
import {useDocumentTitle} from '../../../components/Theme/useDocumentTitle';
import {ManufacturerService} from '../../services/ManufacturerService';

function ManufacturersOverviewPage() {
  useDocumentTitle('Fabricantes');
  const [manufacturerList, setManufacturerList] = useState([]);

  useEffect(() => {
    const getLabs = async () => {
      setManufacturerList(await ManufacturerService.getAll());
    };
    window.scrollTo(0, 0);
    getLabs();
  }, []);

  return (
    <div className="container">
      <div className="mt-4">
        <h1>Fabricantes</h1>
        <RoutePathBreadcrumb />
      </div>
      <h2 className="text-center mb-4">
        Información sobre los fabricantes
      </h2>
      <ContainerLabs>
        {
          manufacturerList?.map((lab, index) => (
            <ContainerImgLabs key={index}>
              <Link to={`/laboratorios/${lab.id}`}>
                {lab.imageLogo ?
                 <SectionImgLabs className="labs" key={lab.id} src={lab.imageLogo} /> :
                 <span>{lab.nombre}</span>
                }
              </Link>
            </ContainerImgLabs>
          ))
        }
      </ContainerLabs>
    </div>
  );
}

export {ManufacturersOverviewPage};