import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

interface Props {
  data: {
    isFavorite: boolean;
    showButton: boolean;
  };
  actions: {
    changeFavorite: () => void
  };
}

export const AddToFavoritesButtonUI = ({ data, actions }: Props) => {
  const { isFavorite, showButton } = data;
  const { changeFavorite } = actions;
  return (
    showButton ? (
      <button type="button"
              className={'btn text-primary shadow-sm rounded-circle p-1 bg-white'}
              onClick={(e) => {
                e.stopPropagation();
                changeFavorite();
              }}>
        {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
      </button>
    ) : (
      <></>
    )
  );
};
