import {ModalContend, ModalDetalleArticulo} from '../../ui';
import React, {useEffect, useMemo, useState} from 'react';
import {BtnCloseCarrito} from '../../ui/components/navbar/NavbarStyles.elements';
import CloseIcon from '@mui/icons-material/Close';
import {fetchProductDetails} from '../infrastructure/productApi';
import {PurchasePanel} from './PurchasePanel';
import {hasDealsToChoose} from '../domain/ProductDetails.model';
import {initial, isPending, isSuccess} from '../../common/domain/RemoteData.model';
import {buildWithoutDeal} from '../domain/ProductSelection.model';
import {useCartContext} from '../../cart';
import {toast} from 'react-toastify';
import {remoteDataWithSetter} from '../../common/utils/remoteDataWithSetter';
import {AddUpdateButton} from '../../common/ui/AddUpdateButton';

export const AddToCartProxy = ({ data }) => {
  const { details, quantity } = data;
  const [modal, setModal] = useState(false);
  const [productDetailsRemoteData, setProductDetailsRemoteData] = useState(initial(null));
  const { isOnCart, addProduct } = useCartContext();

  const loadProductDetails = async (productCode) => {
    await remoteDataWithSetter(setProductDetailsRemoteData, () => fetchProductDetails(productCode));
  };

  const disabled = useMemo(() =>
      !details.priceInformation || details.priceInformation?.bestPrice <= 0 || quantity <= 0 || isPending(productDetailsRemoteData),
    [details, productDetailsRemoteData, quantity]
  );

  useEffect(() => {
    const addToCart = async () => {
      try {
        await addProduct(buildWithoutDeal(productDetailsRemoteData.value, quantity));
        toast.success(`'${details.pro_nombre || details.pro_codigo}' agregado al carrito exitosamente.`);
      } catch (error) {
        toast.error(`'${details.pro_nombre || details.pro_codigo}' no pudo agregarse al carro. Por favor, reintente más tarde o contacte con nosotros.`);
      }
    }

    if (!!productDetailsRemoteData && isSuccess(productDetailsRemoteData)) {
      if (hasDealsToChoose(productDetailsRemoteData.value)) {
        setModal(true);
      } else {
        addToCart().then();
      }
    }
  }, [productDetailsRemoteData]);

  return (
    <div>
      {
        modal && (
          <ModalContend>
            <ModalDetalleArticulo>
              <div className="d-flex flex-row gap-2 align-content-center">
                <h3>{details.pro_nombre}</h3>
                <BtnCloseCarrito onClick={() => setModal(false)}>
                  <CloseIcon />
                </BtnCloseCarrito>
              </div>

              <PurchasePanel productDetails={productDetailsRemoteData?.value} defaultQuantity={quantity} afterAddToCart={() => {
                setModal(false);
              }} />
            </ModalDetalleArticulo>
          </ModalContend>
        )
      }
      <AddUpdateButton
        shouldAdd={isOnCart(details?.id)}
        loading={isPending(productDetailsRemoteData)}
        disabled={disabled}
        onClick={() => loadProductDetails(details?.pro_codigo)}
      />
    </div>
  );
};