import {useEffect, useState} from 'react';
import {initial} from '../../common/domain/RemoteData.model';
import {remoteDataWithSetter} from '../../common/utils/remoteDataWithSetter';
import {fetchFilterData} from '../infrastructure/FilterApi';

export const useFilterOptionsRemoteData = () => {
  const [filterOptionsRemoteData, setFilterOptionsRemoteData] = useState(initial([]));

  useEffect(() => {
    remoteDataWithSetter(setFilterOptionsRemoteData, fetchFilterData).then();
  }, []);

  return { filterOptionsRemoteData };
};