import {Containermenu} from '../../components/Theme/theme.elements';
import {useFilterContext} from '../infrastructure/FilterContext';
import React, {useEffect, useState} from 'react';
import {VetPrescriptionFilterUI} from './VetPrescriptionFilterUI';
import {ProductFamilyFilterUI} from './FamilyFilterUI';
import {ManufacturersFilterUI} from './ManufacturersFilterUI';
import {SpeciesFilterUI} from './SpeciesFilterUI';
import {useFilterOptionsRemoteData} from '../application/useFilterOptionsRemoteData';
import {getOrElse, mapRemoteData} from '../../common/domain/RemoteData.model';
import styled from 'styled-components';

// TODO: tmp solution
const StylesContainer = styled.div`
  .taxonomy .MuiAccordion-gutters:before {
    display: none;
  }

  .taxonomy .form-select {
    border-radius: 0;
    height: 48px;
  }

  .taxonomy .subcategory {
    margin-top: 0;
  }

  .taxonomy .subsubcategory {
    margin-top: 0;
    border-top: 0;
  }

  .taxonomy .taxonomy-filters {
    margin-top: 32px;
  }

  .taxonomy .taxonomy-dropdowns {
    margin-bottom: 24px;
  }

  .categories-tree {
    margin-bottom: 24px;
    padding: 8px;
    border: 1px solid rgb(222, 226, 230);
  }

  .categories-tree div, .categories-tree select {
    border: none;
    background-color: transparent;
  }

  .accordion {
    border: 1px solid rgb(222, 226, 230);
  }

  .accordion .MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters {
    padding-right: 10px;
  }
`;

// TODO: move to ProductFilter domain and use it from the application layer
/**
 * @param {MacroFamily[]} macroFamilies
 * @param {string} macroFamilyName
 * @returns {Family[]}
 */
const getFamilies = (macroFamilies, macroFamilyName) => {
  return macroFamilies.find(it => it.macrofamilia === macroFamilyName)?.familias || [];
};

// TODO: move to ProductFilter domain and use it from the application layer
/**
 * @param {Family[]} families
 * @param {string} familyName
 * @returns {SubFamily[]}
 */
const getSubFamilies = (families, familyName) => {
  return families.find(it => it.familia === familyName)?.subfamilias || [];
};

export const ProductFilters = () => {
  const { filters, updateFilters, disabled, hide } = useFilterContext();
  const { filterOptionsRemoteData } = useFilterOptionsRemoteData();
  const [macroFamilies, setMacroFamilies] = useState([]);
  const [families, setFamilies] = useState([]);
  const [subFamilies, setSubFamilies] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [species, setSpecies] = useState([]);

  useEffect(() => {
    const macroFamiliesRemoteData = mapRemoteData(filterOptionsRemoteData, it => it?.macrofamilias);
    setMacroFamilies(getOrElse(macroFamiliesRemoteData, []));

    const speciesRemoteData = mapRemoteData(filterOptionsRemoteData, it => it?.especies);
    setSpecies(getOrElse(speciesRemoteData, []));

    const manufacturersRemoteData = mapRemoteData(filterOptionsRemoteData, it => it?.fabricantes);
    setManufacturers(getOrElse(manufacturersRemoteData, []));
  }, [filterOptionsRemoteData]);

  useEffect(() => {
    if (macroFamilies?.length === 0 || !filters?.macroFamily) {
      return;
    }
    setFamilies(getFamilies(macroFamilies, filters.macroFamily));
  }, [macroFamilies, filters?.macroFamily]);

  useEffect(() => {
    if (families?.length === 0 || !filters?.family) {
      return;
    }
    setSubFamilies(getSubFamilies(families, filters.family));
  }, [families, filters?.family]);

  return (
    <StylesContainer>
      <Containermenu className="taxonomy">
        <ProductFamilyFilterUI
          data={{
            selection: filters,
            disabled: disabled,
            macroFamilies: macroFamilies.map(({ macrofamilia }) => macrofamilia),
            families: families.map(({ familia }) => familia),
            subFamilies: subFamilies.map(({ subfamilia }) => subfamilia)
          }} actions={{
          changeMacroFamily: (macroFamily) => {
            updateFilters({ macroFamily: macroFamily, family: null, subFamilies: null });
          },
          changeFamily: (family) => {
            updateFilters({ macroFamily: filters.macroFamily, family: family, subFamilies: null });
          },
          changeSubFamilies: (subFamilies) => {
            updateFilters({
              macroFamily: filters.macroFamily,
              family: filters.family,
              subFamilies: subFamilies
            });
          }
        }}
        />
        <ManufacturersFilterUI
          data={{
            manufacturers: manufacturers,
            filters: filters,
            disabled
          }}
          actions={{
            changeSelection: updateFilters
          }}
        />
        <SpeciesFilterUI
          data={{
            species: species,
            filters: filters,
            disabled: disabled
          }}
          actions={{
            changeSelection: updateFilters
          }}
        />
        <VetPrescriptionFilterUI
          data={{ filters: filters, disabled }}
          actions={{ changeSelection: updateFilters }}
        />
      </Containermenu>
    </StylesContainer>
  );
};