/**
 * @typedef {Object} DealSelection
 * @property {DealType} type
 * @property {Offer | Lot | Promo} value
 */

/**
 * @typedef {Object} DealType
 * @property {string} LOT
 * @property {string} OFFER
 * @property {string} PROMO
 */

export const DealType = {
  LOT: 'LOT',
  OFFER: 'OFFER',
  PROMO: 'PROMO'
};
