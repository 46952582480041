import styled from "styled-components";

// Diseño de tabla
export const ImgCart = styled.img`
  width: 70px;
`
export const NameProductCart = styled.h4`
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
`

export const Laboratorio = styled.span`
  font-size: 12px;
  color: #C6C6C6;
  margin: 0;
  padding: 0;
`

export const MessageCart = styled.div`
  font-size: 12px;
  margin: 0;
  &.warning {
    padding: 8px 12px;
    border-radius: 8px;
    margin-top: 8px;
    background-color: #edf3ba
  }
`

export const MessageCartCompose = styled.span`
  font-size: 12px;
  color: ${(props) => props.background || "#858585"};
  padding: 0;
`

export const SpanRecargo = styled.span`
  padding-left: 10px;
  font-size: 12px;
  color: #858585;
`


// estilos de validacion de pedido
export const Modales = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .5);
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  -webkit-transform: translate3d(0,0,0);
  @media (max-width: 768px) {
    padding: 0;
    align-items: start;
  }
`

export const ModalContent = styled.div`
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  background: #fff;
  position: relative;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  padding: 20px;
  z-index: 999;
  @media (max-width: 768px) {
    width: clamp(350px, 95%, 600px);
    margin: 3rem auto;
  }
`

export const ModalViewAlert = styled(ModalContent)`
  width: clamp(10rem, 40rem, 70rem);

  .divAlert {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }

  .infoAlert {
    text-align: center;
  }

  @media (max-width: 992px) {
    max-width: 95%;
    .divAlert {
      flex-wrap: wrap;
    }
  }
`

export const EncabezadoModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #1766DC;
  }
`
export const Contenido = styled.div`
  p {
    max-width: 36rem;
    margin: auto;
  }
`

export const Details = styled.details`
  list-style: none;
  font-size: 12px;
  summary::-webkit-details-marker {
    display: none;
  }
  summary {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`

export const DetailsDir = styled(Details)`
  cursor: pointer;

  summary {
    text-decoration: underline;
    color: var(--bs-secondary);
  }
  p{
    font-size: 12px;
    margin: 0;
  }

`

export const DetallePrecio = styled.div`
  font-size: 12px;
  font-weight: normal;
`

export const DetalleOferta = styled.div`
  font-size: 12px;
  font-weight: normal;
  color: ${(props) => props.background};;

`

export const ContenidoValidado = styled.div`
  color: #6bb26b;
`

export const UnidadesDisponibles = styled.span`
  //background: var(--white);
  //padding-top: 1rem;
  //margin-top: 1rem;
  //background: crimson;
  color: #178d18;
`


export const UnidadesNoDisponibles = styled.span`
  //background: var(--white);
  //padding-top: 1rem;
  //margin-top: 1rem;
  //background: crimson;
  color: crimson;
`

export const ContenidoparaValidar = styled.div`
`

export const ValidarButton = styled.a`
  width: 100%;
  color: #b26b6b;
`

export const Countcontainer = styled.div`
  display: flex;
  align-items: center;
  height: auto;
`

export const Totales = styled.h1`
  font-size: 16px;
  color: #444444;
  margin-bottom: 0;
  margin-top: 16px;
`
export const Total = styled.h1`
  font-size: 26px;
  color: #444444;
`
export const Valortotales = styled.h2`
  font-size: 18px;
  color: var(--bs-secondary);
  font-weight: bold;
  margin-top: 0px;
`
export const Valortotal = styled.h2`
  font-size: 30px;
  color: var(--bs-primary);
  font-weight: bolder;
  margin-top: 0;
  margin-bottom: 0;
`

export const FinalizarPedido = styled.div`
  display: grid;
  justify-items: center;
  //background: crimson;
`

export const ButtonPedido = styled.button`
  width: max-content;
  background: ${(props) => props.background};
  padding: .5rem 1rem;
  border-radius: 8px;
  border: none;
  color: var(--white);
  font-size: 18px;
  a{
    text-decoration: none;
    color: white;
  }
`

/*------------------ DownLoad PDF ------------------*/
export const ContainerPDF = styled.div`
  position: absolute;
  top: -1000%;
`
export const DivFactura = styled.div`
  width: clamp(890px, 100%, 1280px);
  margin: 0 auto;
  padding: 1rem;
`

export const BillHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ContainerLogo = styled.div`
  width: 10rem;
`

export const TotalContainer = styled.div`
  padding: 1rem;
  border: 1px solid #E5E5E5;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TotalText = styled.h2`
  --color: ${(props) => props.color};
  font-weight: 400;
  font-size: 14px;
  color: #444444;

  span {
    font-weight: 600;
    font-size: 20px;
    color: var(--color);
  }
`

export const SubtotalDiv = styled(TotalContainer)`
  border-top: none;
  padding: .5rem .5rem .5rem 1rem;
  gap: 2rem;
  margin: 0;
`

export const SubtotalText = styled.h3`
  --color: ${(props) => props.color};
  font-weight: 400;
  font-size: 14px;
  color: #444444;
  span{
    font-weight: 600;
    font-size: 12px;
    color: var(--color);
  }
`

export const DirContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Dir = styled.div`
  width: 50%;
  margin: 0 .25rem 0;
`
export const DirTitle = styled.h2`
  font-weight: 400;
  font-size: 16px;
  color: #444444;
`

export const DivDireccion = styled.div`
  padding: 1rem;
  border: 1px solid #E5E5E5;
`
export const DirText = styled.h4`
  font-weight: 400;
  font-size: 14px;
  color: #787878;
  word-spacing: 0.25em;

  span {
    font-weight: 400;
    font-size: 12px;
    color: #444444;
  }
`

export const ProductContainer = styled.div`
  margin: 1rem 0;
  h2{
    font-weight: 400;
    font-size: 16px;
    color: #444444;
  }
`

export const CardOrdenCompra = styled.div`
  min-height: 9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #F2F2F2;
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
`

export const DivTitle = styled.div`
  text-align: right;
`

export const BillTitle = styled.h1`
  font-weight: 400;
  font-size: 20px;
  color: #444444;
  margin: 0;
`

export const BillDate = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: #9C9C9C;
`