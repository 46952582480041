import {ContainerTitle} from '../../../components/Theme/theme.elements';
import {ContainerMenu, Totales, Valortotal, Valortotales} from '../../../ui';

const DeliveryCosts = ({ subtotal, deliveryCostThreshold, hasRefrigeratedProducts }) => {
  let text = '';
  if (subtotal >= deliveryCostThreshold) {
    text = 'Envio gratuito.';
  } else if (hasRefrigeratedProducts) {
    text = 'Coste envío 9,5€.';
  } else {
    text = 'Coste de envio para pedidos de menos de 10 kg es 5€. Para pedidos con más de 10kg es 9,5€.';
  }
  return (
    <small className="text-muted">{text}</small>
  );
};

export const OrderRecapUI = ({ cart, deliveryCostThreshold }) => {
  return (
    <ContainerMenu className="filtroMacroFamilia">
      <ContainerTitle>
        Resumen de tu pedido
      </ContainerTitle>
      <Totales>Descuentos aplicables</Totales>
      <Valortotales>
        {cart.discount?.toFixed(2)}€
      </Valortotales>
      <Totales>Total</Totales>
      <Valortotal>
        {cart.subtotal?.toFixed(2)}€
      </Valortotal>

      <div className="d-flex flex-column mt-2 gap-1">
        <small className="text-muted">
          IVA no includo. Total con IVA: {cart.total?.toFixed(2)}
        </small>
        <DeliveryCosts subtotal={cart.subtotal}
                       deliveryCostThreshold={deliveryCostThreshold}
                       hasRefrigeratedProducts={cart.products.some(({ refrigerated }) => refrigerated)}
        />
      </div>
    </ContainerMenu>
  );
};