import {CartProduct} from './cart-product/CartProduct';
import type {Product} from '../models/Product';

interface Props {
  products: Product[],
  areDeprecated?: boolean,
}

export const ProductListUI = ({ products = [], areDeprecated = false }: Props) => {
  return (
    <div className="d-flex flex-column gap-2">
      {
        products.length === 0 && (
          <div className="alert alert-light w-100">
            <span>No hay productos!</span>
          </div>
        )
      }
      {
        products?.map(product => <CartProduct key={product.id} product={product}  isDeprecated={areDeprecated}/>)
      }
    </div>
  );
};

