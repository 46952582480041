import {CheckoutUI} from './CheckoutUI';
import {DeliveryAddressContextProvider, useDeliveryAddressContext} from '../../context/DeliveryAddressContext';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from 'react-router-dom';
import {OrderConfirmationUI} from '../OrderConfirmationUI';
import {OrderService} from '../../services/OrderService';
import {useCartContext} from '../../../cart';
import React, {useEffect, useState} from 'react';
import {ConfirmAddressUI} from '../ConfirmAddressUI';

export const Checkout = () => {
  return (
    <DeliveryAddressContextProvider>
      <CheckoutContent />
    </DeliveryAddressContextProvider>
  );
};

const CheckoutContent = () => {
  const { cart, loadCart, validateCart } = useCartContext();
  const { deliveryAddress } = useDeliveryAddressContext();
  const [placeOrderDisabled, setPlaceOrderDisabled] = useState(false);
  const [comment, setComment] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    setPlaceOrderDisabled(cart?.deprecatedProducts?.length > 0);
  }, [cart]);

  const navigate = useNavigate();

  const placeOrder = async () => {
    toast.info('Realizando pedido.');
    setPlaceOrderDisabled(true);
    setShowConfirmationModal(false);

    validateCart({
      validCartCallback: async () => {
        try {
          const order = await OrderService.placeOrder(deliveryAddress.id, comment);
          navigate('/');
          toast.success(OrderConfirmationUI(order));
        } catch (error) {
          toast.error('Error al realizar el pedido. Contacte con nosotros.');
        } finally {
          loadCart();
        }
      },
      invalidCartCallback: () => {
        toast.warning('Hay productos desactualizados. Por favor, revise su carrito.');
        loadCart();
      }
    });
  };

  const handlePlaceOrder = () => {
    setShowConfirmationModal(true);
  };

  return (
    <>
      <CheckoutUI
        data={{ placeOrderDisabled: placeOrderDisabled, comment: { value: comment, maxLength: 1000 } }}
        actions={{ handlePlaceOrder: handlePlaceOrder, handleComment: setComment }}
      />
      <ConfirmAddressUI
        data={{ address: deliveryAddress, show: showConfirmationModal }}
        actions={{
          onConfirm: () => placeOrder(),
          onCancel: () => setShowConfirmationModal(false),
        }}
      />
    </>
  );
};