import React from 'react';
import SyncAltTwoToneIcon from '@mui/icons-material/SyncAltTwoTone';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import {Spinner} from 'react-bootstrap';

export const AddUpdateButton = ({ shouldAdd = true, loading = false, disabled, onClick }) => {
  return (
    <button type="button" className="btn btn-primary" disabled={disabled}
            onClick={onClick}>
      <div className="d-flex gap-1 align-items-center">
        {
          shouldAdd === true ? (
            <>
              <SyncAltTwoToneIcon />&nbsp;<span>Actualizar</span>
            </>
          ) : (
            <>
              <ShoppingCartCheckoutIcon />&nbsp;<span>Agregar</span>
            </>
          )
        }
        {loading && <Spinner size="sm" />}
      </div>
    </button>
  );
};