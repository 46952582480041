import {useManufacturerContext} from '../../context/ManufacturerContext';
import {ProductSwiper} from '../../../catalog/ui/ProductSwiper';
import React from 'react';

export const ManufacturerProducts = () => {
  const { details } = useManufacturerContext();

  if (details?.codigo) {
    return (
      <ProductSwiper title="Productos"
                     subtitle="del fabricante"
                     filters={{ manufacturers: [details.codigo] }}
                     config={{ amount: 10, page: 0 }}
      />
    );
  }
};