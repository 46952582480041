import React, {useEffect, useState} from 'react';
import {Carritos, TableFactura} from '../../../user/ui/cuenta.elements';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Indicador, ParrafoIndicador} from '../../../ui';
import {InvoiceService} from '../../services/InvoiceService';
import {useErrorLoadingState} from '../../../helpers/hooks/useErrorLoadingState';
import {Spinner} from 'react-bootstrap';
import {downloadPdf} from '../../../helpers/downloadPdf';
import {spanishDateFormatter} from '../../../ui/components/SpanishDateFormatter';

export const InvoicesView = () => {
  const { loading, error, run } = useErrorLoadingState();
  const [invoices, setInvoices] = useState([]);

  const descargarpdf = async (id, order) => {
    try {
      toast.info(`Descargando factura ${order}.`);
      downloadPdf(await InvoiceService.getPDFInvoice(id), `factura-${order}`);
    } catch (error) {
      toast.error(`Error en la descarga de la factura ${order}.`);
    }
  };

  useEffect(() => {
    const getInvoices = async () => {
      setInvoices(await InvoiceService.getInvoices());
    };

    run(getInvoices);
  }, [run]);

  useEffect(() => {
    if (error) {
      alert('Imposible cargar facturas.');
    }
  }, [error]);

  return (
    <div>
      <Indicador indicador={'Historial de facturas'} />
      <ParrafoIndicador
        parrafo={'Revisa el historial de tus facturas.'} />
      {
        loading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : (
          <Carritos className="row">
            <div className="col-xl-12">
              <TableFactura>
                <thead>
                <tr>
                  <th>No. de orden</th>
                  <th>Fecha de vencimiento</th>
                  <th>Importe</th>
                  <th>Descargar</th>
                </tr>
                </thead>
                <tbody>
                {
                  invoices.map((item, index) => (
                    <tr className="align-middle" key={index}>
                      <td>
                        <h5 className="m-0">{item.orden}</h5>
                      </td>
                      <td>
                        <span className="text-primary">
                          {spanishDateFormatter(item.fecha_vencimiento)}
                        </span>
                      </td>
                      <td>{item.importe_iva}</td>
                      <td>
                        <button type="button"
                                className="btn btn-primary"
                                disabled={!item.id_factura}
                                onClick={() => descargarpdf(item.id_factura, item.orden)}
                        >
                          Descargar
                        </button>
                      </td>

                    </tr>
                  ))
                }
                </tbody>
              </TableFactura>
            </div>
          </Carritos>
        )
      }

    </div>
  );
};