import {BillHeader, ContainerLogo, DivFactura} from '../../../ui';
import React from 'react';
import {useTenantContext} from '../../../tenant/TenantContext';
import {spanishDateFormatter} from '../../../ui/components/SpanishDateFormatter';
import {DownloadableProductTableUI} from '../../../product/ui/DownloadableProductTableUI';
import {DownloadableTable} from '../../../ui/styles/DownloadableTable';
import {DownloadableDeliveryAddressUI} from './DownloadableDeliveryAddressUI';

export const DownloadableOrderRecap = ({ order }) => {
  const { logo } = useTenantContext();

  if (!order) {
    return <></>;
  }

  return (
    <DivFactura>
      <BillHeader className="mb-4">
        <ContainerLogo>
          <img src={logo} alt="logo principal" />
        </ContainerLogo>
      </BillHeader>

      <div className="d-flex justify-content-end my-4">
        <div>
          <strong>Dirección de entrega</strong>
          <DownloadableDeliveryAddressUI address={order.deliveryAddress} />
        </div>
      </div>

      <div>
        <DownloadableTable>
          <thead>
          <tr>
            <th>Num. Pedido</th>
            <th>Fecha pedido</th>
            <th>Estado</th>
            <th>% Dto.</th>
            <th>Subtotal</th>
            <th>% IVA</th>
            <th>Total</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{order.numOrden}</td>
            <td>{spanishDateFormatter(order.fechaPedido)}</td>
            <td>{order.estado}</td>
            <td>{order.descuentos}</td>
            <td>{order.subtotal}€</td>
            <td>{order.iva}</td>
            <td>{order.precioTotal}€</td>
          </tr>
          </tbody>
        </DownloadableTable>
      </div>

      <div className="mt-5">
        <div className="fs-4">Productos</div>
        <DownloadableProductTableUI products={order.articulos} />
      </div>
    </DivFactura>
  );
};
