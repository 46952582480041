import {useState} from 'react';

const LOCAL_STORAGE_TAB_KEY = 'activeTab';

export const useCatalogActiveTab = () => {
  const [activeTab, setActiveTab] = useState(() => {
    return localStorage.getItem(LOCAL_STORAGE_TAB_KEY) || 'grid';
  });

  const changeActiveTab = (tab: 'grid' | 'list') => {
    setActiveTab(tab);
    localStorage.setItem(LOCAL_STORAGE_TAB_KEY, tab);
  };

  return { activeTab, changeActiveTab }
}