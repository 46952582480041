import React, {useCallback, useEffect, useState} from 'react';
import {NavInputSearch, Navsearch} from '../NavbarStyles.elements';
import SearchIcon from '@mui/icons-material/Search';
import {useNavigate} from 'react-router-dom';
import {useTenantContext} from '../../../../tenant/TenantContext';
import {useFilterContext} from '../../../../catalog/infrastructure/FilterContext';
import {SearchLinksFilter} from '../../../../catalog/ui/SearchLinksFilter';
import useIsMobile from '../../../../helpers/isMobile';

export const ProductSearchBar = () => {
  const { primaryColor } = useTenantContext();
  const { updateFilters, filters, disabled } = useFilterContext();
  const [searchString, setSearchString] = useState(filters?.search || '');
  const navigate = useNavigate();
  const isMobile = useIsMobile().isMobile;

  const searchProduct = useCallback((searchTerm) => {
    updateFilters({ search: searchTerm });
    navigate(`/articulos`);
  }, [updateFilters, navigate]);

  const handleChange = (event) => {
    const value = event.target.value;
    event.preventDefault();
    setSearchString(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      searchProduct(searchString);
    }
  };

  useEffect(() => {
    setSearchString(filters?.search || '');
  }, [filters]);

  return (
    <div className="w-100 d-flex flex-column gap-2 align-items-center">
      <Navsearch className="w-100">
        <NavInputSearch
          placeholder="Busca aquí tus productos"
          className="searchHome"
          background={primaryColor}
          value={searchString}
          disabled={disabled}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          type="text"
        />
        <SearchIcon
          className="searchIcon"
          style={{ color: '#9C9C9C' }}
          onClick={() => searchProduct(searchString)}
        />
      </Navsearch>
      <div>{isMobile || <SearchLinksFilter />}</div>
    </div>
  );
};
