import {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {NotificationService} from '../services/NotificationService';

const NotificationContext = createContext();

const compareNotificationByDate = (a, b) => (a.fechanotificacion > b.fechanotificacion) ? 1 : -1;

export const NotificationContextProvider = ({ children }) => {
  const [allNotifications, setAllNotifications] = useState();
  const [pendingNotifications, setPendingNotifications] = useState([]);
  const [readNotifications, setReadNotifications] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadNotifications = useCallback(async () => {
    setLoading(true);
    try {
      const notifications = await NotificationService.getAll()
      setAllNotifications(notifications.sort(compareNotificationByDate));
    } finally {
      setLoading(false);
    }
  }, []);

  const changeReadStatus = useCallback(async (notificationId, readState) => {
    await NotificationService.changeReadStatus(notificationId, readState);
    await loadNotifications();
  }, [loadNotifications]);

  useEffect(() => {
    if (allNotifications) {
      setPendingNotifications(allNotifications?.filter(it => it.notificacionleida === false));
      setReadNotifications(allNotifications?.filter(it => it.notificacionleida === true));
    }
  }, [allNotifications]);

  return (
    <NotificationContext.Provider value={{
      allNotifications,
      pendingNotifications,
      readNotifications,
      loading,
      loadNotifications,
      changeReadStatus,
    }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => useContext(NotificationContext);
