import {useCallback, useMemo, useState} from 'react';
import {retryOnError} from '../retryOnError';

export const useErrorLoadingState = (withRetry = false, delay = 7000) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const run = useCallback(async (asyncFn, params = []) => {
    setLoading(true);
    try {
      if (withRetry) {
        return await retryOnError(() => asyncFn(...params), delay);
      }
      return await asyncFn(...params);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [delay, withRetry]);

  return useMemo(() => ({ error, loading, run }), [error, loading, run]);
}
