import {ContainerTitle, TitleSeccion} from '../../../components/Theme/theme.elements';
import {ContainerMenu, RoutePathBreadcrumb} from '../../../ui';
import React from 'react';
import {DeliveryAddress} from '../delivery-address/DeliveryAddress';
import {OrderRecap} from '../order-recap/OrderRecap';
import {CartView} from '../../../cart';
import {TextAreaUI} from '../../../ui/components/TextAreaUI';

interface Props {
  data: {
    placeOrderDisabled: boolean,
    comment: {
      value: string,
      maxLength: number
    },
  };
  actions: {
    handlePlaceOrder: () => void,
    handleComment: (comment: string) => void
  };
}

export const CheckoutUI = ({ data, actions }: Props) => {
  const { placeOrderDisabled, comment } = data;
  const { handlePlaceOrder, handleComment } = actions;

  return (
    <div className="container">
      <div className="my-4">
        <TitleSeccion>Resumen de tu pedido</TitleSeccion>
        <RoutePathBreadcrumb />
      </div>

      <div className="row gap-4 gap-md-0">
        <div className="col-12 col-md-9">
          <CartView />
        </div>
        <div className="col-12 col-md-3">
          <div className="d-flex flex-column">
            <DeliveryAddress />
            <div>
              <ContainerMenu>
                <ContainerTitle>
                  Observaciones
                </ContainerTitle>
                <TextAreaUI
                  data={{
                    value: comment.value,
                    max: comment.maxLength,
                    placeholder: 'Añada comentario sobre su pedido'
                  }}
                  actions={{ handleChange: handleComment }}
                />
              </ContainerMenu>
            </div>
            <OrderRecap />
            <div className="text-center">
              <button type="button"
                      className="btn btn-primary"
                      disabled={placeOrderDisabled}
                      onClick={handlePlaceOrder}>
                Realizar Pedido
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
