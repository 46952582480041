import React from 'react';
import {BtnFormacion, CardFormacion, FechaFormacion, Modalidad} from './CardFormacion.elements';
import HTMLReactParser from 'html-react-parser';
import {Link} from 'react-router-dom';
import {useTenantContext} from '../../../tenant/TenantContext';

function CourseCard(props) {
    const {data} = props;
    const {primaryColor, secondaryColor} = useTenantContext();
    const formatDate = (date) => {
        let day = date.getDate();
        let month = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"][date.getMonth()];
        let year = date.getFullYear();
        return day + " " + month + " " + year;
    }

    return (
        <>
            {data.map((item, index) => (
                <div key={index}>
                    <CardFormacion className="bs">
                        <div className="Containerinfo">
                            <div className="info">
                                <h4 title={item.titulo_formacion}>{item.titulo_formacion}</h4>
                                <span>{item.organizador}</span>
                                <div className="infoResumen">
                                    <div>{item.descripcion_formacion ? HTMLReactParser(item.descripcion_formacion) : ''}</div>
                                </div>
                                <p>
                                    <span>Hora: </span>
                                    {item.horaformacion}
                                </p>
                            </div>
                            <Link to={`/formacion/${item.identificador}`}>
                                <BtnFormacion color={primaryColor}>
                                    Leer detalles
                                </BtnFormacion>
                            </Link>
                        </div>
                        <div className="imgFormacion">
                            <Link to={`/formacion/${item.identificador}`}>
                                <img src={item.imagen_formacion} alt="Imagen formacion"/>
                            </Link>
                            <Modalidad background={secondaryColor}>
                                <p>{item.formacion}</p>
                            </Modalidad>
                            <FechaFormacion background={primaryColor}>
                                <span className="month">{formatDate(new Date(item.fechaformacion))}</span> <br/>
                            </FechaFormacion>
                        </div>
                    </CardFormacion>
                </div>
            ))}
        </>
    )
}

export {CourseCard}