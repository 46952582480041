import {CartProductUI} from './CartProductUI';
import {useUserContext} from '../../../user/infrastructure/UserContext';
import {calculateDiscountPercentage} from '../../../helpers/calculateDiscountPercentage';
import {useCartContext} from '../../context/CartContext';
import {useNavigate} from 'react-router-dom';
import type {Product} from '../../models/Product';

interface Props {
  product: Product;
  isDeprecated?: boolean;
}

export const CartProduct = ({ product, isDeprecated = false }: Props) => {
  const { changeEquivalenceCharges, removeProduct } = useCartContext();
  const { equivalenceCharges } = useUserContext();
  const navigate = useNavigate();

  const editProduct = (product) => {
    navigate(`/articulos/${encodeURIComponent(product.code)}`);
  };
  return (
    <CartProductUI
      data={{
        product: product,
        productDiscount: product && calculateDiscountPercentage(product.basePrice, product.sellingPrice),
        userEquivalenceCharges: equivalenceCharges,
        isDeprecated: isDeprecated
      }}
      actions={{
        changeProductEquivalenceCharges: () => changeEquivalenceCharges(product),
        edit: () => editProduct(product),
        remove: () => removeProduct(product)
      }}
    />
  );
};