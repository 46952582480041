import React, {useState} from 'react';
import {TagListUI} from '../../common/ui/TagListUI';
import {AddToFavoritesButtonUI} from '../../common/ui/AddToFavoritesButtonUI';
import TruncateTooltipUI from '../../common/ui/TruncateTooltipUI';
import {AvailabilityUI} from '../../common/ui/AvailabilityUI';
import {useNavigate} from 'react-router-dom';
import {useEnhancedItem} from '../application/useEnhancedItem';
import {useItemFavorite} from '../application/useItemFavorite';
import {toast} from 'react-toastify';

export const CatalogItemCard = ({ data, children }) => {
  const [isHovered, setIsHovered] = useState(false);
  const enhancedItem = useEnhancedItem(data.catalogItem);
  const { isFavorite, changeFavorite } = useItemFavorite(data.catalogItem);
  const navigate = useNavigate();

  const allChildren = React.Children.toArray(children);
  const priceComponent = allChildren.find(({ type }) => type === CatalogItemCard.Price);

  const handleFavoriteChange = async () => {
    try {
      await changeFavorite();
    } catch {
      toast.error('No se pudo añadir el producto a favoritos. Intentelo más tarde.');
    }
  };

  return (
    <div className="card border-0 shadow-sm"
         style={{ width: '270px', height: '390px', cursor: 'pointer' }}
         onClick={() => navigate(`/articulos/${encodeURIComponent(enhancedItem.pro_codigo)}`)}
         onMouseEnter={() => setIsHovered(true)}
         onMouseLeave={() => setIsHovered(false)}
    >
      <div className="card-header border-0 bg-white">
        <TagListUI data={{ tags: enhancedItem.tags }} />
      </div>

      <div className="card-img-top">
        <div className="d-flex justify-content-center align-items-center">
          <div className="position-absolute">
            <AddToFavoritesButtonUI
              data={{ showButton: isHovered, isFavorite: isFavorite }}
              actions={{ changeFavorite: handleFavoriteChange }}
            />
          </div>
          <img src={enhancedItem.pro_imagen}
               className="rounded-2 p-2"
               style={{ height: '170px', width: 'auto' }}
               alt="Imagen del producto"
          />
        </div>
      </div>

      <div className="card-body">
        <div className="d-flex flex-align-start flex-column h-100">
          <div className="h-100 mb-auto overflow-visible">
            <div className="card-title">
              <TruncateTooltipUI text={enhancedItem.pro_nombre} maxLength={40} />
            </div>

            <div className="card-subtitle text-muted">
              <small>{enhancedItem.pro_laboratorio}</small>
            </div>
          </div>

          <div className="card-text">
            <div className="d-flex flex-column gap-2">
              <AvailabilityUI data={{ availability: enhancedItem.availability }} />
              {priceComponent}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CatalogItemCard.Price = ({ children }) => children;

