import {DeliveryAddressUI} from './DeliveryAddressUI';
import {Spinner} from 'react-bootstrap';
import {useDeliveryAddressContext} from '../../context/DeliveryAddressContext';

export const DeliveryAddress = () => {
  const { deliveryAddress, loading } = useDeliveryAddressContext();

  return (
    <>
      {
        loading ? (
          <div className="text-center m-4">
            <Spinner />
          </div>
        ) : (
          <>
            <DeliveryAddressUI address={deliveryAddress}></DeliveryAddressUI>
          </>
        )
      }
    </>
  );
};