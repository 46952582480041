import {ContainerTitle} from '../../../components/Theme/theme.elements';
import {BtnModificarDireccion, ContainerDireccion, ContainerMenu, DetailsDir} from '../../../ui';
import {Link} from 'react-router-dom';

export const DeliveryAddressUI = ({ address }) => {
  return (
    <ContainerMenu>
      <ContainerTitle>
        Dirección de entrega
      </ContainerTitle>
      <ContainerDireccion>
        <h5>Dirección</h5>
        <span>{address.mainInfo}</span><br/>
        <DetailsDir>
          <summary>
            Mostrar dirección completa
          </summary>
          <p><b>Nombre: </b>{address.name}</p>
          <p><b>Código postal: </b>{address.zip}</p>
          <p><b>Provincia ó región: </b>{address.country || 'N/A'}</p>
        </DetailsDir>
        <Link to={'/cuenta/direcciones'}>
          <BtnModificarDireccion>
            Modificar dirección de entrega
          </BtnModificarDireccion>
        </Link>
      </ContainerDireccion>
    </ContainerMenu>
  );
};