/**
 * @typedef {Object} Tag
 * @property {'InfoTag' | 'DealTag'} type - The type of tag, either an information tag or a deal tag.
 * @property {string} value - The value associated with the tag.
 */

/**
 * @param value
 * @returns {Tag}
 */
export const buildInfoTag = (value) =>
  ({ type: 'InfoTag', value: value });

/**
 * @param value
 * @returns {Tag}
 */
export const buildDealTag = (value) =>
  ({ type: 'DealTag', value: value });

/**
 * @param {Tag} tag
 * @returns {boolean}
 */
export const isInfoTag = (tag) =>
  tag.type === 'InfoTag';
