import React from 'react';
import parse from 'html-react-parser';


export function NotificationDetailUI({ notification }) {
  return (
    <div className="divAlert">
      <div className="infoAlert">
        <h2><span>{notification.datos.titulonotificacion}</span></h2>
        <h5><span>{notification.datos.asunto}</span></h5>
        <h6><span>{notification.datos.tipo}</span></h6>
        <p>{notification.datos.descripcion ? parse(notification.datos.descripcion) : ''}</p>
      </div>
    </div>
  );
}
