import ApiClient, {requestRemoteData} from '../../API/api';
import {mapRemoteData} from '../../common/domain/RemoteData.model';

const RELATED_PRODUCTS_ENDPOINT = 'product/related';
const CATALOG_ENDPOINT = 'product';
const PRODUCTS_PRICES_ENDPOINT = 'product/prices';

export const fetchRelatedProducts = (productCode, { page, amount }) => {
  return requestRemoteData(
    RELATED_PRODUCTS_ENDPOINT,
    {
      queryParams: { page: page, products_per_page: amount, productCode: productCode }
    }
  );
};

/**
 * @param {Filters} filters
 * @param {Config} config
 * @returns {Promise<RemoteData<ApiResponse<CatalogItem>>>}
 */
export const fetchAll = (filters, config) => {
  return requestRemoteData(
    CATALOG_ENDPOINT,
    {
      method: 'POST',
      queryParams: { page: config.page, products_per_page: config.amount },
      body: {
        busqueda: filters?.search || '',
        prescripcion: filters?.vetPrescription,
        pro_especie: filters?.species || [],
        pro_laboratorio: filters?.manufacturers || [],
        pro_macrofamilia: filters?.macroFamily || '',
        pro_familia: filters?.family || '',
        pro_subfamilia: filters?.subFamilies || [],
        destacado: filters?.featured || null,
        pro_precio_min: 0,
        pro_precio_max: 0,
        pro_caracteristica: []
      }
    }
  );
};

// TODO: do renaming at the backend
const tmpMapToCatalogItemPrice = (remoteData) =>
  mapRemoteData(
    remoteData,
    it => it?.map(({ producto_codigo, precio_old, pro_precio }) =>
      ({
        code: producto_codigo,
        basePrice: precio_old,
        sellingPrice: pro_precio
      })
    )
  );

/**
 *
 * @param codes
 * @returns {Promise<RemoteData<CatalogItemPriceWithCode>>}
 */
export const fetchCatalogItemPrices = (codes) => {
  return requestRemoteData(
    PRODUCTS_PRICES_ENDPOINT,
    {
      method: 'POST',
      body: codes.map(it => ({ pro_codigo: it }))
    }
  ).then(tmpMapToCatalogItemPrice);
};

/**
 * @deprecated use {@link fetchAll} as it handles remote data
 * @param {Filters} filters
 * @param {Config} config
 * @returns {Promise<ApiResponse<CatalogItem>>}
 */
export const getProducts = (filters, config) => {
  return ApiClient.performRequest(
    CATALOG_ENDPOINT,
    {
      method: 'POST',
      queryParams: {page: config.page, products_per_page: config.amount},
      body: {
        busqueda: filters?.search || '',
        prescripcion: filters?.vetPrescription,
        pro_especie: filters?.species || [],
        pro_laboratorio: filters?.manufacturers || [],
        pro_macrofamilia: filters?.macroFamily || '',
        pro_familia: filters?.family || '',
        pro_subfamilia: filters?.subFamilies || [],
        destacado: filters?.featured || null,
        pro_precio_min: 0,
        pro_precio_max: 0,
        pro_caracteristica: [],
      }
    }
  );
};
