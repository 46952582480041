import {I18n} from 'aws-amplify/utils';
import {translations} from '@aws-amplify/ui-react';

const VOCABULARY = {
  es: {
    ...translations['es'],
    'Enter your Username': 'Nombre de usuario',
    'Enter your username': 'Nombre de usuario',
    'Reset your password': 'Restablecer contraseña',
    'Reset Password': 'Restablecer contraseña',
    'Sign in': 'Acceso',
    'Password must have at least 8 characters': 'La contraseña debe tener al menos 8 caracteres'
  },
};


export const setupAwsAmplifyI18N = () => {
  I18n.putVocabulariesForLanguage('es', translations['es']);
  I18n.putVocabularies(VOCABULARY);
  I18n.setLanguage('es');
}
