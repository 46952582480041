import {Modal} from 'react-bootstrap';
import {BtnModificarDireccion, ContainerDireccion} from '../../ui';
import {Link} from 'react-router-dom';

// TODO: refactor
export const ConfirmAddressUI = ({ data, actions }) => {
  const { address, show } = data;
  const { onConfirm, onCancel } = actions;

  return (
    <Modal show={show} onHide={onCancel} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Confirma tu dirección de envío
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ContainerDireccion>
          <p>{address.mainInfo}</p>
          <p><b>Nombre: </b>{address.name}</p>
          <p><b>Código postal: </b>{address.zip || 'N/A'}</p>
          <p><b>Provincia ó región: </b>{address.country || 'N/A'}</p>

          <Link to={'/cuenta/direcciones'}>
            <BtnModificarDireccion>
              Modificar dirección de entrega
            </BtnModificarDireccion>
          </Link>
        </ContainerDireccion>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex gap-2">
          <button className="btn btn-secondary" type="butotn" onClick={() => onCancel()}>
            Cancelar
          </button>
          <button className="btn btn-primary" type="butotn" onClick={() => onConfirm()}>
            Confirmar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};