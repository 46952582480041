import styled from 'styled-components';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import {CartPreview} from './cart-preview/CartPreview';
import {useEffect, useMemo, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useCartContext} from '../context/CartContext';
import CloseIcon from '@mui/icons-material/Close';
import {useClickOutside} from '../../helpers/hooks/useClickOutside';

const ItemsCounter = styled.div`
  border-radius: .25rem;
  cursor: pointer;
  border: none;
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;

  :after {
    content: attr(data-count);
    min-width: 1.2rem;
    min-height: 1.2rem;
    margin-left: .25rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    background: red;
    color: white;
  }
`;


const Content = styled.div`
  position: fixed;
  background-color: var(--bs-white);
  min-width: 50%;
  max-width: 100%;
  max-height: calc(100vh - 100px);
  overflow: auto;
  right: 0;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  z-index: 2;
  padding: 1rem;
  border-radius: 10px;
`;

const CloseBtnContainer = styled.button`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  position: absolute;
  right: .5rem;
  top: 1rem;
  border: none;
  color: white;
  background-color: var(--bs-primary);
  opacity: 0.5;

  svg {
    width: 1rem;
  }
`;

/*
TODO
- create hook at the application layer for the cart (count, loading)
 */

export const CartPreviewButton = () => {
  const [modalCar, setModalCar] = useState(false);
  const location = useLocation();
  const { cart, loadCartBis } = useCartContext();

  const modalRef = useRef(null);
  useClickOutside(modalRef, () => setModalCar(false));

  const isCartRoute = useMemo(
    () => location.pathname === '/checkout',
    [location]
  );

  const itemCount = useMemo(() => {
    const productCount = cart?.products?.length ?? 0;
    const deprecatedProductCount = cart?.deprecatedProducts?.length ?? 0;
    return productCount + deprecatedProductCount;
  }, [cart]);

  useEffect(() => {
    if (!cart) {
      loadCartBis();
    }
  }, [cart, loadCartBis]);

  return (
    <div>
      {
        !isCartRoute && (
          <>
            <ItemsCounter
              data-count={itemCount}
              hideBadge={itemCount === 0}
              onClick={() => setModalCar(true)}>
              <ShoppingCartOutlinedIcon fontSize="large" className="text-primary" />
            </ItemsCounter>
            {
              modalCar && (
                <Content ref={modalRef}>
                  <CloseBtnContainer onClick={() => setModalCar(false)}>
                    <CloseIcon />
                  </CloseBtnContainer>
                  <CartPreview setModalCar={setModalCar} />
                </Content>
              )}
          </>
        )}
    </div>
  );
};