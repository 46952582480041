import React, {useEffect, useState} from 'react';
import {Galery__item, Galerycontainer, Gallery__img, Gallery_Overlay, Gallery_title} from './tarjetasInformativas.elements';
import 'swiper/css';
import 'swiper/css/pagination';
import './tarjetasInformativas.css';
import {OfferService} from '../../services/OfferService';

function TarjetasInformativas() {
  const [ofertDest, setOfertDest] = useState([]);

  const getOfferts = async () => {
    setOfertDest(await OfferService.getAll());
  };

  useEffect(() => {
    getOfferts()
    .catch(error => console.log(error));
  }, []);

  const len = ofertDest.length;

  return (
    <Galerycontainer>
      {
        ofertDest.map((ofertDest, index) => (
          <Galery__item className={'span-' + (ofertDest.ancho && Number.isInteger(ofertDest.ancho) ? ofertDest.ancho : 1)} key={index} Length={len}>
            <Gallery__img
              key={index}
              src={ofertDest.imagen}
              alt={ofertDest.nombre} />
            {ofertDest.nombre && ofertDest.nombre.trim() !== '' && <Gallery_Overlay />}
            <Gallery_title>
              {
                !!ofertDest.link ? (
                  <a href={ofertDest.link} target="_blank" rel="noopener noreferrer">{ofertDest.nombre}</a>
                ) : (
                  <span>{ofertDest.nombre}</span>
                )
              }
            </Gallery_title>
          </Galery__item>
        ))
      }
    </Galerycontainer>
  );
}

export {TarjetasInformativas};
