import styled from 'styled-components';

export const ButtonMenuUser = styled.button`
  text-decoration: none;
  background: none;
  color: var(--color-fuente);
  font-size: 16px;
  text-align: left;
  padding: 2% 0 2% 2%;
  margin: 1%;
  width: 100%;
  height: auto;
  border: 0;

  :hover {
    background: ${(props) => props.background};
    color: var(--white);
  }
`

export const ContenedorCuenta = styled.div`
  background: var(--white);
  width: 100%;
  margin: 1rem 0;
  padding: 1rem;

  box-shadow: 0 1px 5px rgba(140, 140, 140, 0.25);
  
  @media (max-width: 991px) {
    justify-content: center;
  }
  @media (max-width: 768px) {
    padding: 8px 0;
  }
`

export const TextAccount = styled.p`
  color: var(--bs-primary);
  font-size: 20px;
  font-weight: 500;
`

export const PCuenta = styled.p`
  width: 100%;
  font-size: 14px;
  margin-bottom: .3%;
`

export const InputAccount = styled.input`
  width: 100%;
  height: 40px;
  font-size: 12px;
  margin-bottom: 12px;
  padding-left: 1rem;
  background: none;
  border: 1px solid #f1f1f1;
  border-radius: 6px;
`
export const DivChangePass = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
  margin: 1.5rem 0 0 0;
`

export const Longcard = styled.div`
  background: var(--white);
  border-radius: 8px;
  margin: 1rem 0;
  padding: .5rem 1rem;
  box-shadow: 0 1px 5px rgba(140, 140, 140, 0.25);
`

export const ContainerLeft = styled.div`
  height: auto;
`

export const ContainerRight = styled.div`
  text-align: right;
  padding: 1rem 1rem 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`
export const ButtonOrder = styled.button`
  background: none;
  border: 1px solid var(--bs-primary);
  color: var(--bs-primary);
  font-weight: 500;
  font-size: 12px;
  border-radius: 8px;
  padding: 8px 10px 8px 10px;
  margin-right: 15px;

  :hover {
    background: var(--bs-primary);
    border-color: var(--bs-primary);
    color: var(--white);
  }
`

export const ButtonFactura = styled.button`
  background: ${(props) => props.background};
  border: 2px solid ${(props) => props.background};
  color: var(--white);
  font-size: 12px;
  border-radius: 8px;
  padding: 8px 10px 8px 10px;
  margin: 2rem 0;
`
export const EtiquetasPedido = styled.p`
  padding-top: 2%;
  font-size: 12px;
  margin: 0;
`

export const ContainerPrecios = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  >div:not(:first-child){
    border-left: 1px solid rgba(0,0,0,0.12);
  }
`

export const PreciosDiv = styled.div`
  padding: 0 .75rem;
  width: auto;
  @media (max-width: 991px) {
  }
`

export const PreciosPedidoEtiqueta = styled.p`
  font-weight: 500;
  font-size: 14px;
  margin: 0;
`

export const Precios = styled.p`
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  color: var(--bs-primary);
`

export const Carritos = styled.div`
  @media (max-width: 991px) {
    overflow: scroll;
  }

  .actions{
    text-align: end;

    ul{
      transform: translate(-1.2rem, 0) !important;
      li:not(:last-child){
        border-bottom: 1px solid rgba(0,0,0,0.12);
      }
    }
  }
`

export const TableCart = styled.table`
  width: 100%;

  thead tr th {
    color: #858585;
    font-weight: 400;
  }

  thead th:nth-last-child(-n+3) {
    text-align: right;
  }

  tbody td:nth-last-child(-n+3) {
    text-align: right;
    min-width: 7rem;
  }

  th, td {
    padding: 1rem;
  }

  tbody tr:nth-child(odd) {
    background-color: #FBF9F9;
  }
`

export const TableFactura = styled(TableCart)`
  thead th:nth-last-child(n+2) {
    text-align: left;
  }

  tbody td:nth-last-child(n+2) {
    text-align: left;
    min-width: 7rem;
  }
`

export const Notificaciones = styled.div`
  @media (max-width: 991px) {
    overflow: scroll;
  }
`
export const EtiquetaPrecio = styled.p`
  font-size: 20px;
  color: ${(props) => props.background};
  margin: 0;
`
export const OrdenPedido = styled.span`
  font-size: 12px;
  color: var(--color-fuente);
  margin: 0;
`

export const ContainerNuevo = styled.div`
  border-radius: 8px;
  padding: 1rem;
  span{
    color: #444444;
    font-weight: initial;
  }
  .imgAlert{
    width: clamp(17.5rem, 90%, 30rem);
    border-radius: .5rem;
  }

  @media (max-width: 992px) {
    padding: .5rem;
  }
  &.coloured {
    background: ${(props) => props.background};
    border: 1px solid #C0C0C0;
    border-radius: 0;
    box-shadow: none;
    padding: 32px;

    @media (max-width: 768px) {
      padding: 16px;
    }

    .submit-row {
      justify-content: end;
    }

    input[type="submit"] {
      width: fit-content;
      margin-top: 16px;
      @media (max-width: 992px) {
        width: calc(100% - 32px);
        margin-right: 16px;
      }
    }

    input[type="checkbox"] {
      margin-right: 8px;
    }

    select {
      padding: 6px;
      border: 1px solid rgb(176, 176, 176);
    }

    .explanation {
      color: #606060;
      font-size: 0.85em;
    }

    .errorMessage {
      background: #FFC0C0;
      color: #900000;
      padding: 8px;
      text-align: center;
      margin: 16px 0;
    }

    .successMessage {
      font-size: 1.2em;
      padding: 8px;
      margin: 16px 0;
    }
  }
`

export const InputOption = styled.input`
  width: 100%;
  height: 35px;
  font-size: 12px;
  padding: .5rem;
  border: 1px solid #B0B0B0;
`

export const InputSend = styled.input`
  background: var(--bs-primary);
  border-radius: 8px;
  font-size: 14px;
  padding: 10px 15px 10px 15px;
  color: var(--white);
  border: 1px solid var(--bs-secondary);

  :disabled {
    background: #B0B0B0;
    color: white;
    border: #B0B0B0;
  }
`

export const ContainerOptions = styled.div`
  margin-top: 3px;
  margin-bottom: 3px;
  padding-top: 3px;
  #entrega, #facturacion{
    margin-right: .5rem;
  }
  span{
    color: #787878;
    margin: 0;
    font-size: 16px;
  }
  button {
    margin-right: 8px;
    margin-bottom: 4px;
    margin-top: 4px;
  }
  button:last-child {
    margin-right: 0;
  }
`

export const LabelCart = styled.p`
  color: #303030;
  margin: 0;
  font-size: 16px;
  &.mandatory:after {
    content: "*";
    font-size: 0.9em;
    opacity: 0.6;
    margin-left: 2px;
    top: -2px;
    position: relative;
  }
`

export const InputError = styled.p`
  color: #F00000;
  margin: 0;
  font-size: 12px;
`

export const Explanation = styled.p`
  color: #787878;
  margin: 0;
  font-size: 12px;
  font-style: italic;
`

export const LabelName = styled.p`
  font-size: 20px;
  color: var('--lv-primary');
`

export const ContainerButtonRegister = styled.div`
  text-align: end;
  margin-top: 15px;
`

export const ContainerDetail = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  width: auto;
  text-align: right;
  @media (max-width: 991px) {
    width: 100%;
    text-align: left;
    margin: 0;
    row-gap: .5rem;
  }
`
export const ItemDiv = styled.div`
  background: white;
  border-radius: .5rem;
  margin: auto;
  padding: 1rem 1rem 0;
  width: 17.188rem;
  border: 1px solid #E7E7E7;
  @media (max-width: 991px) {
    width: 100%;
  }
`

export const TitleAccount = styled.h1`
  font-size: 20px;
  font-weight: 600;
  color: ${(props) => props.background};
  margin: 0;
`

export const PreciosTransacciones = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  margin: .5rem 0;
  color: ${(props) => props.background};
`

export const DescripcionTransacciones = styled.p`
  font-size: 12px;
  color: var(--color-fuente);
  margin-top: 5px;
`

export const ViewCart = styled.a`
  color: ${(props)=> props.color};
  text-decoration: underline;
  :hover{
    color: ${(props)=> props.color};
  }
`

export const TableHistoriar = styled.table`
  tbody tr td:nth-child(n+3){
    text-align: center;
  }
`
