import React, {useEffect} from 'react';
import 'swiper/css/bundle';
import '@aws-amplify/ui-react/styles.css';
import {AppRoutes} from './AppRoutes';
import {useLocation} from 'react-router-dom';

const App = () => {
  const pathname = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <AppRoutes />;
};

export default App;
