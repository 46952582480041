import {spanishDateFormatter} from '../../ui/components/SpanishDateFormatter';
import {DealType} from '../domain/DealSelection.model';

export const BulkDealsSelectorUI = ({
  bulkDeals,
  onDealSelection,
  selection
}) => {
  return (
    <div>
      {
        bulkDeals?.length > 0 && (
          <div>
            <strong className="m-0 text-primary">
              Promociones por lotes
            </strong>
            <div className="table-responsive">
              <table className="table table-borderless">
                <thead>
                <tr>
                  <th>{/*Checkbox*/}</th>
                  <th>Precio</th>
                  <th>Descuento</th>
                  <th>Precio Neto</th>
                  <th>Fecha de caducidad</th>
                </tr>
                </thead>
                <tbody className="fw-normal">
                {
                  bulkDeals.map((deal, index) => (
                    <tr key={index}>
                      <td>
                        <input type="radio" name="bulkDeal"
                               checked={selection?.value === deal}
                               onChange={() => onDealSelection({ type: DealType.LOT, value: deal})}
                        />
                      </td>
                      <td>{deal.mejorpreciotachado.toFixed(2)}€</td>
                      <td>{deal.THR_Discount__}%</td>
                      <td>{deal.mejorprecio.toFixed(2)}€</td>
                      <td>{spanishDateFormatter(deal.THR_Expiration_Date)}</td>
                    </tr>
                  ))
                }
                </tbody>
              </table>
            </div>
          </div>
        )
      }
    </div>
  );
};