import type {Cart} from '../models/Cart';
import {CartRepository} from '../repositories/CartRepository';
import ApiClient from '../../API/api';

const ADD_PRODUCT_ENDPOINT = 'cart/product';

export class CartService {
  static async changeEquivalenceCharges(product): Promise<void> {
    return CartRepository.setEquivalenceCharge(product.id, !product.equivalenceCharge);
  }

  static get(): Promise<Cart> {
    return CartRepository.get();
  }

  static removeProduct(product): Promise<void> {
    return CartRepository.removeProduct(product.id);
  }

  static validate(): Promise<boolean> {
    return CartRepository.validate();
  }

  static addProductBis = ({ productId, quantity, price, bcDiscounts, packParentId = null }) => {
    return ApiClient.performRequest(
      ADD_PRODUCT_ENDPOINT,
      {
        method: 'POST',
        body: {
          productId: productId,
          quantity: quantity,
          sellingPrice: price.sellingPrice,
          basePrice: price.basePrice,
          packParentId: packParentId,
          bcDiscounts: bcDiscounts
        }
      }
    );
  };
}