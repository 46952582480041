import {useAuthenticator} from '@aws-amplify/ui-react';
import {useEffect, useMemo, useState} from 'react';
import {resetPassword} from '../infrastructure/UserApi';
import {initial, isSuccess} from '../../common/domain/RemoteData.model';
import {remoteDataWithSetter} from '../../common/utils/remoteDataWithSetter';
import {isInvalid as _isInvalid} from '../domain/PasswordUpdate.model';

export const useChangePassword = () => {
  const { signOut } = useAuthenticator((context) => [context.user]);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const [resetPasswordRemoteData, setResetPasswordRemoteData] = useState(initial(null));

  const passwordUpdate = useMemo(() =>
      ({
        oldPassword,
        newPassword,
        repeatPassword
      }),
    [newPassword, oldPassword, repeatPassword]
  );

  const delayedSignOut = (delay = 5000) => {
    setTimeout(() => signOut(), delay);
  };

  const updatePassword = async () => {
    const dataSetterCallback = () => resetPassword(newPassword, oldPassword);
    await remoteDataWithSetter(setResetPasswordRemoteData, dataSetterCallback);
  };

  const isInvalid = _isInvalid;

  useEffect(() => {
    if (isSuccess(resetPasswordRemoteData)) {
      delayedSignOut();
    }
  }, [resetPasswordRemoteData]);

  return {
    passwordUpdate,
    setOldPassword,
    setNewPassword,
    setRepeatPassword,
    updatePassword,
    isInvalid,
    resetPasswordRemoteData
  };
};