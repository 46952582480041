import React, {useState} from 'react';
import {createPortal} from 'react-dom';
import {Indicador, ParrafoIndicador} from '../../ui';
import {ButtonOrder, ContenedorCuenta, DivChangePass, InputAccount, PCuenta, TextAccount} from './cuenta.elements';
import {ModalUI} from '../../ui/components/ModalUI';
import {ChangePass} from './ChangePass';
import {spanishDateFormatter} from '../../ui/components/SpanishDateFormatter';
import {useSelfDetails} from '../application/useSelfDetails';
import {RemoteDataUI} from '../../common/ui/RemoteDataUI';
import {Spinner} from 'react-bootstrap';


function UserDetails() {
  const [showModal, setShowModal] = useState(false);
  const { userDetailsRemoteData } = useSelfDetails();

  const handleOnPasswordChanged = () => {
    setTimeout(() => setShowModal(false), 5000);
  };

  return (
    <div>
      <Indicador indicador={'Mis datos de usuario'} />
      <ParrafoIndicador parrafo={'Mantén actualizados los datos de tu cuenta'} />

      <RemoteDataUI remoteData={userDetailsRemoteData}>
        <RemoteDataUI.Pending>
          <div className="d-flex justify-content-center">
            <Spinner />
          </div>
        </RemoteDataUI.Pending>

        <RemoteDataUI.Success>
          {
            userdata => (
              <div>
                <ContenedorCuenta className="row">
                  <div className="col-xl-12">
                    <TextAccount>Información de usuario</TextAccount>
                  </div>
                  <form className="row">
                    <div className="col-sm-6">
                      <div className="col-sm-12">
                        <PCuenta>Razón social</PCuenta>
                        <InputAccount
                          type="text"
                          name="first_name"
                          id="first_name" disabled
                          defaultValue={userdata.first_name} />
                      </div>
                      <div className="col-sm-12">
                        <PCuenta>Identificación</PCuenta>
                        <InputAccount
                          type="text"
                          name="cif_niff"
                          id="cif_niff"
                          disabled
                          value={userdata.cif_nuff} />
                      </div>
                      <div className="col-sm-12">
                        <PCuenta>Teléfono</PCuenta>
                        <InputAccount
                          type="text"
                          name="telefono_cliente"
                          id="telefono_cliente" disabled
                          defaultValue={userdata.telefono_cliente}
                        />
                      </div>

                    </div>
                    <div className="col-sm-6">
                      <div className="col-sm-12">
                        <PCuenta>Código cliente</PCuenta>
                        <InputAccount
                          type="text"
                          name="codigo_cliente"
                          id="codigo_cliente" disabled
                          defaultValue={userdata.userbc}
                        />
                      </div>
                      <div className="col-sm-12">
                        <PCuenta>Correo electrónico</PCuenta>
                        <InputAccount
                          type="text"
                          name="email"
                          id="email"
                          disabled value={userdata.email} />
                      </div>
                      <div className="col-sm-12">
                        <PCuenta>Fecha de registro</PCuenta>
                        <InputAccount type="text" name="fecharegistro" disabled
                                      value={spanishDateFormatter(userdata.fecha_registro)} />
                      </div>
                      <div className="col-sm-12">
                        <PCuenta>Móvil</PCuenta>
                        <InputAccount
                          type="text"
                          name="movil_cliente"
                          id="movil_cliente"
                          disabled
                          defaultValue={userdata.movil_cliente}
                        />
                      </div>

                    </div>
                  </form>
                  <DivChangePass>
                    <ButtonOrder onClick={() => setShowModal(true)}>
                      cambiar contraseña
                    </ButtonOrder>
                    {showModal && createPortal(
                      <ModalUI onClose={() => setShowModal(false)}>
                        <ChangePass onPasswordChanged={handleOnPasswordChanged} />
                      </ModalUI>,
                      document.body
                    )}
                  </DivChangePass>
                </ContenedorCuenta>
              </div>
            )
          }
        </RemoteDataUI.Success>
      </RemoteDataUI>

    </div>
  );
}

export {UserDetails};