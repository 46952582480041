import {EquivalenceCharges} from '../../../user/domain/User.model';
import {Spinner} from 'react-bootstrap';
import {ProductListUI} from '../ProductListUI';

export const CartViewUI = ({
  equivalenceChargedProducts,
  equivalenceChargeFreeProducts,
  deprecatedProducts,
  equivalenceCharges,
  loading
}) => {
  if (loading === true) {
    return <Spinner className="text-center" />;
  }

  return (
    <>
      {
        deprecatedProducts?.length > 0 && (
          <div className="mb-3">
            <div className="mb-2">
              <h4>Productos desactualizados</h4>
              <small className="text-danger">Borra o edita estos productos para tramitar el pedido</small>
            </div>
            <ProductListUI products={deprecatedProducts} areDeprecated />
          </div>
        )
      }
      <div>
        <h4>Productos en tu carrito</h4>
        {
          equivalenceCharges === EquivalenceCharges.YES && <ProductListUI products={equivalenceChargedProducts} />
        }
        {
          equivalenceCharges === EquivalenceCharges.NO && <ProductListUI products={equivalenceChargeFreeProducts} />
        }
        {
          equivalenceCharges === EquivalenceCharges.BY_CHOICE && (
            <>
              <p>
                Tu carrito contiene productos <a href="#equivalence-charge-free-products">sin recargo de equivalencia</a> y <a href="#equivalence-charged-products">con recargo de
                equivalencia</a>
              </p>
              <div className="d-flex flex-column gap-5">
                <div id="equivalence-charge-free-products">
                  <p className="fw-bold">
                    Productos <b>SIN</b> recargo de equivalencia ({equivalenceChargeFreeProducts?.length})
                    <br />
                    <a href="#equivalence-charged-products" className="text-muted">
                      Ir a pedidos <b>CON</b> recargo de equivalencia
                    </a>
                  </p>
                  <ProductListUI products={equivalenceChargeFreeProducts} />
                </div>
                <div id="equivalence-charged-products">
                  <p className="fw-bold">
                    Productos <b>CON</b> recargo de equivalencia ({equivalenceChargedProducts?.length})
                    <br />
                    <a href="#equivalence-charge-free-products" className="text-muted">
                      Ir a pedidos <b>SIN</b> recargo de equivalencia
                    </a>
                  </p>
                  <ProductListUI products={equivalenceChargedProducts} />
                </div>
              </div>
            </>
          )
        }
      </div>
    </>
  );
};