import React, {useEffect, useState} from 'react';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import {useNavigate} from 'react-router-dom';
import {useNotificationContext} from '../../contexts/NotificationContext';
import {Offcanvas} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {NotificationDetailsModalUI} from '../NotificationDetailModalUI';
import {PendingNotificationsUI} from '../PendingNotificationsUI';

export const PendingNotificationsSidebar = ({ open, close }) => {
  const { pendingNotifications, loading, loadNotifications, changeReadStatus } = useNotificationContext();
  const [showDetails, setShowDetails] = useState(false);
  const [notification, setNotification] = useState();
  const navigate = useNavigate();

  const markAsRead = async (notification) => {
    try {
      await changeReadStatus(notification.id, true);
    } catch (error) {
      toast.error('Error al marcar como leída la notificación');
    }
  };

  const showDetailsPopup = (notification) => {
    setNotification(notification);
    setShowDetails(true);
  };

  useEffect(() => {
    if (!pendingNotifications) {
      loadNotifications();
    }
  }, [loadNotifications, pendingNotifications]);

  return (
    <>
      <NotificationDetailsModalUI
        data={{
          notification: notification,
          show: showDetails
        }}
        actions={{
          handleClose: () => setShowDetails(false)
        }} />

      <Offcanvas show={open} onHide={close} placement="end">
        <Offcanvas.Header closeButton>
          <div className="d-flex flex-row align-items-center justify-content-start text-primary">
            <NotificationsActiveOutlinedIcon />
            <span>Notificaciones</span>
          </div>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <PendingNotificationsUI
            data={{
              pendingNotifications: pendingNotifications,
              loading: loading
            }}
            actions={{
              handleMarkAsRead: markAsRead,
              handleShow: showDetailsPopup,
              handleSeeAll: () => {
                close();
                setShowDetails(false);
                setNotification(null);
                navigate('/cuenta/Alertas');
              }
            }}
          />
        </Offcanvas.Body>
      </Offcanvas>

    </>
  );
};