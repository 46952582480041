import {MutableRefObject} from 'react';
import {jsPDF} from 'jspdf';

export const downloadPdf = (blob: Blob, desiredName: string) => {
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = URL.createObjectURL(blob);
  link.download = `${desiredName}.pdf`;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  URL.revokeObjectURL(link.href);
};

export const downloadCmpAsPdf = async (reference: MutableRefObject, name: string): Promise<void> => {
  const pdf =  await new jsPDF({ format: 'a2', unit: 'px' });
  const htmlWorker = pdf.html(reference.current);
  return await htmlWorker.save(name + '.pdf');
};
