import React, {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import {Navigation} from 'swiper';
import {ContenedorCarruselesLabs} from '../../styles/carruselesLabs.elements';
import './carruselesLabs.css';
import {Link} from 'react-router-dom';
import {useTenantContext} from '../../../tenant/TenantContext';
import {ManufacturerService} from '../../../manufacturer/services/ManufacturerService';

function CarouselLabs() {
  const { secondaryColor } = useTenantContext();

  const [labs, setLabs] = useState([]);

  useEffect(() => {
    const getLabs = async () => {
      try {
        setLabs(await ManufacturerService.getAllWithLogo());
      } catch (error) {
        console.error('Error al obtener la lista de laboratorios:', error.message);
      }
    };
    getLabs();
  }, []);

  return (
    <div className="row">
      <ContenedorCarruselesLabs background={secondaryColor}>
        <Swiper
          slidesPerView={6}
          navigation={true}
          loop={false}
          loopFillGroupWithBlank={false}
          modules={[Navigation]}
          className="mySwiperClientes"
          background={secondaryColor}
          breakpoints={{
            0: {
              slidesPerView: 3
            },
            780: {
              slidesPerView: 4
            },
            1032: {
              slidesPerView: 4
            },
            1300: {
              slidesPerView: 5
            }
          }}>
          {labs.map((lab, index) => (
            <SwiperSlide key={index}>
              <Link to={`/laboratorios/${lab.id}`}>
                <img src={lab.imageLogo} style={{ maxWidth: '170px', maxHeight: '140px' }} alt="logo" />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </ContenedorCarruselesLabs>
    </div>
  );
}

export {CarouselLabs};