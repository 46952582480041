import {Availability} from '../domain/Availability.model';
import React from 'react';

interface Props {
  data: {
    availability: Availability;
  };
}

export const AvailabilityUI = ({ data }: Props) => {
  const { availability } = data;
  return (
    <>
      {
        !!availability && (
          <small className={`${availability.isAvailable ? 'text-primary' : 'text-warning'}`}>
            {availability.msg}
          </small>
        )
      }
    </>
  );
};