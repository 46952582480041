import {createContext, useContext, useEffect, useState} from 'react';
import AddressService from '../services/AddressService';

const DeliveryAddressContext = createContext();

export const useDeliveryAddressContext = () => useContext(DeliveryAddressContext);

export const DeliveryAddressContextProvider = ({ children }) => {
  const [deliveryAddress, setDeliveryAddress] = useState({});
  const [loading, setLoading] = useState(false);
    //TODO we need to control if the customer has no address, showing a message to them.
  useEffect(() => {
    AddressService.getDeliveryAddresses()
                  .then(addresses => {
                    setDeliveryAddress(addresses[0]);
                    setLoading(false);
                  });
  }, [setDeliveryAddress, setLoading]);

  return (
    <DeliveryAddressContext.Provider value={{deliveryAddress, loading}}>
      {children}
    </DeliveryAddressContext.Provider>
  );
};
