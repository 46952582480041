import {createContext, useContext} from 'react';

import {retrieveHeader} from '../helpers/HttpHeaders';

const TENANT_ID_HEADER = 'X-Tenant-Id';
const DEFAULT_TENANT_ID = process.env.REACT_APP_TENANT_ID;

const tenantId = retrieveHeader(TENANT_ID_HEADER) ?? DEFAULT_TENANT_ID;
const tenant = require(`./configs/${tenantId}/config.json`);

const favicon = `/tenants/${tenantId}/imgs/favicon.png`;
const logo = `/tenants/${tenantId}/imgs/logo.png`;
const logoRecuperacion = `/tenants/${tenantId}/imgs/Logo_COLOR.jpg`;
const logoFundacion = `/tenants/${tenantId}/imgs/EN V Funded by the EU_PANTONE.jpg`;

import(`./styles/${tenantId}/style.scss`);

const tenantConfig = {
  tenantId,
  favicon,
  logo,
  logoRecuperacion,
  logoFundacion,
  ...tenant
}

const TenantContext = createContext();

export const useTenantContext = () => {
  return useContext(TenantContext);
};

export const TenantContextProvider = ({ children }) => {
  return (
    <TenantContext.Provider value={tenantConfig}>
      {children}
    </TenantContext.Provider>
  )
};
