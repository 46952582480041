import {BtnNotificationActions, ContainerBtnActions, DivNotification, InfoNotification, TextoNotification, TituloNotification} from './pending-notifications/notification.elements';
import {Spinner} from 'react-bootstrap';
import React from 'react';

interface Props {
  data: {
    pendingNotifications: any[], //TODO: add model
    loading: boolean;
  };
  actions: {
    handleMarkAsRead: (notification: any) => void,
    handleShow: (notification: any) => void,
    handleSeeAll: () => void
  };
}

export const PendingNotificationsUI = ({ data, actions }: Props) => {
  const { pendingNotifications, loading } = data;
  const { handleShow, handleMarkAsRead, handleSeeAll } = actions;

  return (
    <>
      {
        loading === true ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : (
          <div className="d-flex flex-align-start flex-column h-100">
            <div className="h-100 mb-auto overflow-auto p-3">
              {
                pendingNotifications.length === 0 && (
                  <div className="alert alert-light">No hay notificaciones pendientes.</div>
                )
              }
              {
                pendingNotifications.map((notification, index) => (
                  <div key={index}>
                    {
                      <DivNotification className="bs">
                        <div>
                          <InfoNotification>
                            {/* Notification's imgs need to be a dynamic link or null */}
                            <div>
                              <TituloNotification>
                                {notification.datos.titulonotificacion}
                              </TituloNotification>
                              <TextoNotification>
                                {notification.datos.asunto}
                              </TextoNotification>
                            </div>
                          </InfoNotification>
                          <ContainerBtnActions>
                            <BtnNotificationActions
                              onClick={() => handleShow(notification)}>
                              Ver
                            </BtnNotificationActions>
                            <BtnNotificationActions
                              onClick={() => handleMarkAsRead(notification)}>
                              Marcar como leida
                            </BtnNotificationActions>
                          </ContainerBtnActions>
                        </div>
                      </DivNotification>
                    }
                  </div>
                ))
              }
            </div>

            <div className="d-flex justify-content-start">
              <button type="button" className="mt-5 btn btn-primary"
                      onClick={handleSeeAll}>
                Ver Todas
              </button>
            </div>
          </div>
        )
      }
    </>
  );
};