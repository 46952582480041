import {ContainerSearhformations, OrderSelect} from '../../../components/Theme/theme.elements';
import {ContainerBusqueda, IconBusqueda, InputSearch, Search} from '../../../ui';
import SearchIcon from '@mui/icons-material/Search';
import {useTenantContext} from '../../../tenant/TenantContext';

function CourseSearcher(props) {
    const { primaryColor } = useTenantContext();
    return (
        <>
            <ContainerSearhformations>
                <ContainerBusqueda>
                    <Search>
                        <InputSearch>
                            <input type="text" value={props.search} placeholder="Buscar" onChange={props.handleSearch}/>
                            <IconBusqueda role="button"
                                          background={primaryColor}>
                                <SearchIcon className="searchIcon"/>
                            </IconBusqueda>
                        </InputSearch>
                    </Search>
                    <div>
                        <label htmlFor="Ordenar">Ordenar por</label> <br/>
                        <OrderSelect value={props.orderBy} onChange={props.handleOrderBy}>
                            <option value='nuevo'>Nuevo</option>
                            <option value='A-Z'>Nombre, A a la Z</option>
                            <option value='Z-A'>Nombre, Z a la A</option>
                            <option value='antiguo'>Más antiguo</option>
                        </OrderSelect>
                    </div>
                </ContainerBusqueda>
            </ContainerSearhformations>
        </>
    );
}

export {CourseSearcher};