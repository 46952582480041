import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {BreadCrumbFormaciones} from '../../../components/Theme/breadcrumb';
import {SubTitleFormaciones} from './subtitleFormaciones';
import HTMLReactParser from 'html-react-parser';
//Styles
import {Containermenu, ContainerTitle, ContenedorGlobal} from '../../../components/Theme/theme.elements';
import {
    ARegistro,
    BtnRegistroDos,
    ButtonRegisterLearning,
    ContainerBtns,
    ContainerFormacionesDescripcion,
    ContainerInfo,
    ImgFormacion,
    InfoFormador,
    LiInfoFormador,
    LinkCompartir,
    Modalidad,
    TextoFormacion
} from '../../../ui';
import {BtnFormacion, FechaFormacion} from '../course-card/CardFormacion.elements';
// Iconos
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import {useDocumentTitle} from '../../../components/Theme/useDocumentTitle';
import {useTenantContext} from '../../../tenant/TenantContext';

const apiUrl = process.env.REACT_APP_API_URL;

const CourseDetails = () => {

    const {id} = useParams()
    const navigate = useNavigate();
    const {primaryColor, secondaryColor} = useTenantContext();
    const [formacion, setFormacion] = useState(undefined);
    const [formations, setFormations] = useState(null);
    const [formacionIndex, setFormacionIndex] = useState(undefined)
    useDocumentTitle("Formaciones")

    const GetFormation = async (id) => {
        const requestOptions = {
            method: "GET", headers: {"accept": "application/json"},
        };
        const response = await fetch(`${apiUrl}/learning/${id}`, requestOptions);
        const data = await response.json();
        if (!response.ok) {
        } else {
            setFormacion(data)
        }
    };
    const GetFormations = async () => {
        const requestOptions = {
            method: "GET", headers: {"accept": "application/json"},
        };
        const response = await fetch(`${apiUrl}/learning`, requestOptions);
        const data = await response.json();
        if (!response.ok) {
        } else {
            const orderedFormations = data.sort((a,b) => (a.fechaformacion > b.fechaformacion ? 1 : -1));
            setFormations(orderedFormations)
            console.log("--------")
            console.log(orderedFormations)
            console.log(formacion)
            if(formacion) {
                const index = orderedFormations.findIndex(obj => obj.identificador === formacion.identificador);
                setFormacionIndex(index);

                console.log(index)
            }
        }
    };
    useEffect(() => {
        if(!formacion || formacion.identificador != id) {
            GetFormation(id)
                .catch(error => console.log(error));
        }
        GetFormations()
            .catch(error => console.log(error));
    }, [id, formacion])

    const formatDate = (date) => {
        let day = date.getUTCDate();
        let month = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"][date.getUTCMonth()];
        let year = date.getUTCFullYear();
        return day + " " + month + " " + year;
    }
    const shareUrl = "Compartiendo informacion";
    const handleShareFace = () => {
        window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`,
            "facebook-share-dialog",
            "width=626,height=436"
        );
    };
    const handleClickLinkedin = () => {
        window.open(
            `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}`,
            "linkedin-share-dialog",
            "width=626,height=436"
        );
    };
    const handleClickTwitter = () => {
        window.open(
            `https://twitter.com/share?url=${encodeURIComponent(shareUrl)}`,
            "twitter-share-dialog",
            "width=626,height=436"
        );
    };
    return (
        <>
            {formacion &&
                <ContenedorGlobal>
                    <div className="row">
                        <BreadCrumbFormaciones ubicacion={"Formación"} id={formacion.titulo_formacion}/>
                        <div className="col-lg-2">
                            <ContainerInfo>
                                <ContainerBtns>
                                    {(formations && Array.isArray(formations) && (formacionIndex > 0)) &&
                                        <BtnFormacion
                                            color={primaryColor}
                                            onClick={() => navigate(`/formacion/${formations[formacionIndex - 1].identificador}`)}
                                        >Ver anterior</BtnFormacion>
                                    }
                                    {(formations && Array.isArray(formations) &&  (formacionIndex < formations.length - 1)) &&
                                        <BtnFormacion
                                            color={primaryColor}
                                            onClick={() => navigate(`/formacion/${formations[formacionIndex + 1].identificador}`)}
                                        >Ver siguiente</BtnFormacion>
                                    }
                                </ContainerBtns>
                                <Containermenu>
                                    <ContainerTitle color={secondaryColor}>
                                        Detalles del evento
                                    </ContainerTitle>
                                    <InfoFormador>
                                        <ul>
                                            <LiInfoFormador><b>Fecha del evento</b>
                                                <br/> {formatDate(new Date(formacion.fechaformacion))}
                                            </LiInfoFormador>
                                            <LiInfoFormador><b>Hora del evento</b> <br/> {formacion.horaformacion}
                                            </LiInfoFormador>
                                            <LiInfoFormador><b>Organizador</b> <br/> {formacion.organizador}</LiInfoFormador>
                                            <LiInfoFormador><b>Tipo de evento</b> <br/> {formacion.formacion}
                                            </LiInfoFormador>
                                        </ul>
                                    </InfoFormador>
                                </Containermenu>
                                {formacion && Array.isArray(formacion.formadores) && formacion.formadores.length > 0 && (
                                    <Containermenu>
                                        <ContainerTitle color={secondaryColor}>
                                            Detalles del formador
                                        </ContainerTitle>
                                        <InfoFormador>
                                            {
                                                formacion.formadores.map((formador, subIndex) => (
                                                    <div className="my-4" key={subIndex}>
                                                        <div>
                                                            <img src={formador.foto_formador} alt="Foto Formador"/>
                                                        </div>
                                                        <ul>
                                                            <LiInfoFormador>
                                                                <b>Nombre:</b><br/>
                                                                {formador.nombre_formador}
                                                            </LiInfoFormador>
                                                            <LiInfoFormador>
                                                                <b>Profesión:</b><br/>
                                                                {formador.profesion_formador}
                                                            </LiInfoFormador>
                                                            {/* <LiInfoFormador>{formador.caracteristicas_formador}</LiInfoFormador>*/}
                                                        </ul>
                                                    </div>
                                                ))
                                            }
                                        </InfoFormador>
                                    </Containermenu>
                                )}
                                {formacion && Array.isArray(formacion.url_inscripcion) && formacion.url_inscripcion.length >= 1 ?
                                    <ARegistro href={formacion.url_inscripcion} target="_blank">
                                        <BtnRegistroDos color={primaryColor} background={secondaryColor}
                                                        url={formacion.url_inscripcion} target="_blank">
                                            Registrarme ahora
                                        </BtnRegistroDos>
                                    </ARegistro>
                                    :
                                ''
                                }
                            </ContainerInfo>
                        </div>
                        <div className="col-lg-10">
                            <ContainerFormacionesDescripcion>
                                { formacion.imagen_banner &&
                                <div className="formaciones__banner">
                                    <img src={formacion.imagen_banner} alt="Imagen formaciones descripcion"/>
                                    <Modalidad background={secondaryColor}>
                                        <p>{formacion.formacion}</p>
                                    </Modalidad>
                                </div>
                                }
                                <div className="container__title">
                                    <div className="subtitle">
                                        <SubTitleFormaciones
                                            title={formacion.titulo_formacion}
                                            organizador={formacion.organizador}/>

                                    </div>
                                    { (! formacion.imagen_banner) &&
                                        <div>
                                            <Modalidad background={secondaryColor} inline>
                                                <p>{formacion.formacion}</p>
                                            </Modalidad>
                                        </div>
                                    }
                                    <div className="container__button">
                                        {formacion && formacion.url_inscripcion ?
                                            <a href={formacion.url_inscripcion} target="_blank">
                                                <ButtonRegisterLearning color={primaryColor}
                                                                        background={secondaryColor}
                                                                        url={formacion.url_inscripcion} target="_blank">
                                                    Registrarme ahora
                                                </ButtonRegisterLearning>
                                            </a>
                                            :
                                            ''
                                        }

                                    </div>
                                </div>
                                <div className="container__descripcion">
                                    <div className="bandera">
                                        <FechaFormacion background={secondaryColor}>
                                            <span
                                                className="day">{formatDate(new Date(formacion.fechaformacion))}</span><br/>
                                        </FechaFormacion>
                                        <p>Compartir esta información</p>
                                        <LinkCompartir onClick={handleShareFace} color={"#0084FE"}>
                                            <FacebookRoundedIcon/>
                                        </LinkCompartir>
                                        <LinkCompartir onClick={handleClickTwitter} color={"#00EFFE"}>
                                            <TwitterIcon/>
                                        </LinkCompartir>
                                    </div>
                                    <ImgFormacion src={formacion.imagen_formacion} alt="ImagenFormación"/>
                                    <TextoFormacion>
                                        {formacion.descripcion_formacion ? HTMLReactParser(formacion.descripcion_formacion) : ''}
                                    </TextoFormacion>
                                </div>
                            </ContainerFormacionesDescripcion>
                        </div>
                    </div>
                </ContenedorGlobal>
            }
        </>
    );
}


export {CourseDetails};