import {pending} from '../domain/RemoteData.model';

/**
 *
 * @param {function(any): any} reactSetter
 * @param {function(): Promise<RemoteData>} dataFetchingCallback
 * @returns {Promise<RemoteData>}
 */
export const remoteDataWithSetter = async (reactSetter, dataFetchingCallback) => {
  reactSetter(pending());
  reactSetter(await dataFetchingCallback());
}