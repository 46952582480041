/**
 * @typedef {Object} ProductDetails
 * @property {number} id
 * @property {string} pro_codigo
 * @property {string} pro_nombre
 * @property {string} pro_laboratorio
 * @property {ProductImages} pro_imagen
 * @property {boolean} pro_nuevo
 * @property {boolean} pro_destacado
 * @property {boolean} pro_mas_vendido
 * @property {number} pro_unidades_disponibles
 * @property {string} ficha_cimavet
 * @property {boolean} mostrar_mensaje_ultimas_unidades
 * @property {string?} texto_mensaje_ultimas_unidades
 * @property {string?} stockText
 * @property {string} pro_descripcion
 * @property {boolean} showStock
 * @property {PriceDetailedInformation?} priceDetailedInformation
 */

/**
 * @typedef {Object} ProductImages
 * @property {string} imagen_principal
 * @property {string[]} subimage
 */

/**
 * @typedef {Object} PriceDetailedInformation
 * @property {Price} price
 * @property {Offer[]} offers
 * @property {Promo[]} promos
 * @property {Lot[]} lots
 */

/**
 * @typedef {Object} Price
 * @property {number} originalPrice
 * @property {number} bestPrice
 * @property {number} descBc1
 * @property {number} descBc2
 * @property {number} descBc3
 * @property {number} descBc4
 * @property {number} descBc5
 * @property {number} descBc6
 * @property {number} descBc7
 * @property {number} descBc8
 * @property {string} discountLine
 */

/**
 * @typedef {Object} Offer
 * @property {number} cantidad
 * @property {number} descBc5
 * @property {number} descBc6
 * @property {number} descBc7
 * @property {number} descBc8
 * @property {string} descuento
 * @property {string} endingDate
 * @property {number} price
 * @property {string} startingDate
 */

/**
 * @typedef {Object} Lot
 * @property {string} Description
 * @property {string} Descuentos1
 * @property {number} Expired_Inventory
 * @property {string} Lot_No_
 * @property {number} THR_Discount__
 * @property {string} THR_Expiration_Date
 * @property {number} THR_Sales_Price
 * @property {number} descBc1
 * @property {number} descBc2
 * @property {number} descBc3
 * @property {number} descBc4
 * @property {number} descBc9
 * @property {number} mejorprecio
 * @property {number} mejorpreciotachado
 * @property {string} porcentajeAhorro
 */

/**
 * @typedef {Object} Promo
 * @property {string} Descuentos1
 * @property {number} Discount__
 * @property {number} RLD_Cant__Min__Promocion
 * @property {number} RLD_Precio_Producto
 * @property {GiftProduct[]} THR_Charge_Item_No_
 * @property {string} THR_Code
 * @property {number} THR_Quantity
 * @property {number} THR_Sales_Price
 * @property {number} descBc1
 * @property {number} descBc2
 * @property {number} descBc3
 * @property {number} descBc4
 * @property {number} descBc10
 * @property {string} description
 * @property {number} mejorprecio
 * @property {number} mejorpreciotachado
 * @property {number} precio_neto
 * @property {number} unitPrice
 * @property {number} packTotalPrice
 * @property {string} descuento
 */

/**
 * @typedef {Object} GiftProduct
 * @property {string} codigo_articulo
 * @property {number} id
 * @property {string} imagen_principal
 * @property {string} nombre_articulo
 */

/**
 * @param {ProductDetails} productDetails
 * @return {Offer | undefined}
 */
export const extractSingleQuantityOffer = (productDetails) => {
  return productDetails.priceDetailedInformation?.offers?.find(offer => offer.cantidad === 1);
};

/**
 * if there is an offer for a single unit of the product and none other offers
 * @param {ProductDetails} productDetails
 * @returns {boolean}
 */
export const hasOffers = (productDetails) => {
  const singleQuantityOffer = extractSingleQuantityOffer(productDetails);
  return (singleQuantityOffer && productDetails.priceDetailedInformation?.offers.length > 1)
    || (!singleQuantityOffer && productDetails.priceDetailedInformation?.offers.length > 0);
};

/**
 * If the user can choose any of the product details deals
 * @param {ProductDetails} productDetails
 * @returns {boolean}
 */
export const hasDealsToChoose = (productDetails) => {
  return !productDetails.priceDetailedInformation
    || productDetails.priceDetailedInformation?.lots?.length > 0
    || hasOffers(productDetails)
    || productDetails.priceDetailedInformation?.promos?.length > 0;
};

/**
 * If a product detail has any deal
 * @param {ProductDetails} productDetails
 * @returns {boolean}
 */
export const hasDeals = (productDetails) => {
  return !!productDetails.priceDetailedInformation
    && (productDetails.priceDetailedInformation?.lots?.length > 0
    || productDetails.priceDetailedInformation?.offers?.length > 0
    || productDetails.priceDetailedInformation?.promos?.length > 0);
};
