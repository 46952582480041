import React from 'react';
import {ContainerNuevo, ContainerOptions, Explanation, InputError, InputOption, InputSend, LabelCart, LabelName} from './cuenta.elements';
import {ContenedorGlobal} from '../../components/Theme/theme.elements';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {Link} from 'react-router-dom';
import {useTenantContext} from '../../tenant/TenantContext';
import {RemoteDataUI} from '../../common/ui/RemoteDataUI';
import {useOnboard} from '../application/useOnboard';
import {Spinner} from 'react-bootstrap';

function Onboard() {
  const { tenantId, privacyPolicy } = useTenantContext();
  const { onboardRemoteData, onboard } = useOnboard();

  const validationSchema = Yup.object({
    nombre: Yup.string()
               .required('Este campo es obligatorio')
               .min(2, 'El valor del campo es demasiado corto'),
    numCliente: Yup.string(),
    empresa: Yup.string()
                .required('Este campo es obligatorio')
                .min(2, 'El valor del campo es demasiado corto'),
    email: Yup.string()
              .email('El email no tiene un formato correcto')
              .required('Este campo es obligatorio'),
    telefono: Yup.string()
                 .required('Este campo es obligatorio')
                 .min(2, 'El valor del campo es demasiado corto')
                 .matches(/^(\+\d{1,3})?\s?-?\d{3}\s?-?\d{3}\s?-?\d{3}$/, 'Introduzca un teléfono válido (ej: 123 456 789 o +34 123 456 789)'),
    poblacion: Yup.string()
                  .required('Este campo es obligatorio')
                  .min(2, 'El valor del campo es demasiado corto'),
    aceptaTerminos: Yup.boolean()
                       .oneOf([true], 'Debe aceptar los términos y condiciones para continuar')
  });

  return (
    <div className="pt-4">
      <ContenedorGlobal className="narrow">
        <ContainerNuevo className="col-md-12 bs coloured" background={'#F0F0F0'}>
          <div className="col-md-12">
            <LabelName>
              Registro para clientes de LeonVet
            </LabelName>
          </div>
          <RemoteDataUI remoteData={onboardRemoteData}>
            <RemoteDataUI.Initial>
              <Formik
                initialValues={{
                  nombre: '',
                  numCliente: '',
                  empresa: '',
                  email: '',
                  telefono: '',
                  poblacion: '',
                  aceptaTerminos: false
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => onboard(values, tenantId)}>
                {({ handleChange, handleBlur, values, errors, touched }) => (
                  <Form>
                    <div className="row">
                      <ContainerOptions className="col-md-9">
                        <LabelCart className="mandatory">Nombre y apellidos</LabelCart>
                        <InputOption type="text" id="nombre" value={values.nombre} onChange={handleChange} onBlur={handleBlur} />
                        {errors.nombre && touched.nombre && (<InputError>{errors.nombre}</InputError>)}
                      </ContainerOptions>

                      <ContainerOptions className="col-md-3">
                        <LabelCart>Código de cliente</LabelCart>
                        <InputOption type="text" id="numCliente" value={values.numCliente} onChange={handleChange} onBlur={handleBlur} />
                        <Explanation>Opcional</Explanation>
                        {errors.numCliente && touched.numCliente && (<InputError>{errors.numCliente}</InputError>)}
                      </ContainerOptions>

                      <ContainerOptions className="col-md-7">
                        <LabelCart className="mandatory">Correo electrónico</LabelCart>
                        <InputOption type="text" id="email" value={values.email} onChange={handleChange} onBlur={handleBlur} />
                        {errors.email && touched.email && (<InputError>{errors.email}</InputError>)}
                      </ContainerOptions>

                      <ContainerOptions className="col-md-5">
                        <LabelCart className="mandatory">Telefono</LabelCart>
                        <InputOption type="text" id="telefono" value={values.telefono} onChange={handleChange} onBlur={handleBlur} />
                        {errors.telefono && touched.telefono && (<InputError>{errors.telefono}</InputError>)}
                      </ContainerOptions>

                      <ContainerOptions className="col-md-6">
                        <LabelCart className="mandatory">Centro veterinario</LabelCart>
                        <InputOption type="text" id="empresa" value={values.empresa} onChange={handleChange} onBlur={handleBlur} />
                        {errors.empresa && touched.empresa && (<InputError>{errors.empresa}</InputError>)}
                      </ContainerOptions>

                      <ContainerOptions className="col-md-6">
                        <LabelCart className="mandatory">Provincia o población</LabelCart>
                        <InputOption type="text" id="poblacion" value={values.poblacion} onChange={handleChange} onBlur={handleBlur} />
                        {errors.poblacion && touched.poblacion && (<InputError>{errors.poblacion}</InputError>)}
                      </ContainerOptions>

                      <ContainerOptions className="col-md-12 mt-4">
                        <input type="checkbox" id="aceptaTerminos" value={values.aceptaTerminos} onChange={handleChange} onBlur={handleBlur} />
                        <label htmlFor="aceptaTerminos">
                          Acepto los <a href={privacyPolicy} target="_blank">términos y condiciones de LEONVET SL</a>
                        </label>
                        {errors.aceptaTerminos && touched.aceptaTerminos && (<InputError>{errors.aceptaTerminos}</InputError>)}
                      </ContainerOptions>

                    </div>
                    <div className="mt-4 explanation"><p>Ten en cuenta que esto no es un proceso automatizado, nuestro personal revisará tu solicitud lo antes posible, podemos
                      tardar hasta 48 horas. Gracias por confiar en Leonvet.</p></div>

                    <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-md-end gap-2 submit-row">
                      <InputSend type="submit" value="Continuar registro" />
                      <div className="order-md-first text-center">
                        <Link to="/iniciar-sesion">
                          Volver a inicio de sesión
                        </Link>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </RemoteDataUI.Initial>

            <RemoteDataUI.Pending>
              <div className="d-flex justify-content-center">
                <Spinner />
              </div>
            </RemoteDataUI.Pending>

            <RemoteDataUI.Success>
              {
                () => (
                  <div className="successMessage text-success">
                    <p>Hemos recibido correctamente su solicitud, y contactaremos muy pronto para confirmar el registro.</p>
                    <p>Gracias por su interés en LeonVet</p>
                  </div>
                )
              }
            </RemoteDataUI.Success>

            <RemoteDataUI.Failure>
              <div className="errorMessage">
                <p>Ha ocurrido un error. Por favor, reintente más tarde o contacte con nosotros</p>
                <p>{onboardRemoteData.error}</p>
              </div>
            </RemoteDataUI.Failure>
          </RemoteDataUI>
        </ContainerNuevo>
      </ContenedorGlobal>
    </div>
  );
}

export {Onboard};