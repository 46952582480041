import React, {useEffect} from 'react';
import {CarouselLabs} from '../../ui';
import {useDocumentTitle} from '../../components/Theme/useDocumentTitle';
import {toast} from 'react-toastify';
import {FeaturedProducts} from '../../catalog/ui/FeaturedProducts';
import {TarjetasInformativas} from './tarjetasInformativas/tarjetasInformativas';
import {useFilterContext} from '../../catalog/infrastructure/FilterContext';


function Home() {
  useDocumentTitle('Inicio');
  const { clearFilters } = useFilterContext();
  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    const message = localStorage.getItem('message');
    if (isLoggedIn) {
      toast.success(message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2500
      });
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('message');
    }
    clearFilters();
  }, [clearFilters]);
  return (
    <>
      <section className="container justify-content-center">
        <div style={{ marginTop: '20px' }}>
          <TarjetasInformativas />
        </div>
        <div style={{ marginTop: '100px' }}>
          <FeaturedProducts />
        </div>
        <div style={{ marginTop: '100px' }}>
          <CarouselLabs />
        </div>
      </section>
    </>
  );
}

export {
  Home
};