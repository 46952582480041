import React from 'react';
import {Breadcrumbone, TitleSeccion} from './theme.elements';
import {Link} from 'react-router-dom';
import {useTenantContext} from '../../tenant/TenantContext';

// TODO: replace this with route breadcrumb.
function Breadcrumb(props) {
    const { primaryColor } = useTenantContext();
    return (
        <>
            <div className="row my-3">
            <TitleSeccion color={primaryColor}>
                {props.ubicacion}
            </TitleSeccion>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Breadcrumbone as={Link} to={"/"} background={primaryColor}>Inicio</Breadcrumbone>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {props.id}
                        </li>
                    </ol>
                </nav>
            </div>
        </>
    );
}

export {Breadcrumb};

// TODO: replace this with route breadcrumb.
export const BreadCrumbFormaciones = (props) =>{
    const { primaryColor } = useTenantContext();
    return(
        <>
            <div className="row my-4">
                <TitleSeccion color={primaryColor}>
                    {props.ubicacion}
                </TitleSeccion>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Breadcrumbone as={Link} to={"/"} background={primaryColor}>Inicio</Breadcrumbone>
                        </li>
                        <li className="breadcrumb-item">
                            <Breadcrumbone as={Link} to={"/formacion"} background={primaryColor}>Formaciones</Breadcrumbone>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {props.id}
                        </li>
                    </ol>
                </nav>
            </div>
        </>
    )
}
