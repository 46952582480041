import {Route, Routes} from 'react-router-dom';
import {ManufacturersOverviewPage} from './components/overview-page/ManufacturersOverviewPage';
import {ManufacturerPage} from './components/page/ManufacturerPage';

export const ManufacturerRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<ManufacturersOverviewPage />} />
      <Route exact path="/:id" element={<ManufacturerPage />} />
    </Routes>
  );
};
