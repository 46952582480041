import React, {useEffect, useState} from 'react';
import {ContainerImg, ContainerMultiImg, ImgArt, ImgProductDetail} from '../styles/Articulos.elements';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


export const ImgSlider = ({
  mainImgUrl,
  subImageUrls = [],
}) => {
  const [principal, setPrincipal] = useState('');
  useEffect(() => {
    setPrincipal(mainImgUrl);
  }, [mainImgUrl]);
  return (
    <>
      <ContainerImg>
        <ImgProductDetail id={'ImgPrincipal'} src={principal} alt="product image" />
      </ContainerImg>
      <ContainerMultiImg>
        {
          subImageUrls.map((url, index) =>
            <div onClick={() => setPrincipal(url)}>
              <ImgArt key={index} src={url} alt="subimagen" />
            </div>
          )
        }
        {
          mainImgUrl !== principal && (
            <div onClick={() => setPrincipal(mainImgUrl)}>
              <ImgArt src={mainImgUrl} alt="subimagen" />
            </div>
          )
        }
      </ContainerMultiImg>
    </>
  );
};
