import styled from "styled-components";

export const CardFormacion = styled.div`
  height: 280px;
  background-color: white;
  display: flex;

  .Containerinfo {
    width: 58%;
    padding: 1.5rem 4rem 1.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    h4{
      font-size: 20px;
      margin-bottom: 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    
    .info {          
      margin-bottom: 1rem;
      .infoResumen *{
        font-size: 0.875rem;
        font-weight: 400;
        margin: 0;
      }
      .infoResumen {
        line-height: 18px;
        color: #444444;
        margin: .5rem 0;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }

      p {
        margin: 0;
      }
      
      p > span{
        font-weight: 600;
      }

      span {
        font-size: 0.75rem;
      }
    }
    @media (max-width: 575px) {
      padding: 1rem 2rem 1rem 1rem;
    }
  }

  .imgFormacion {
    width: 42%;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`

export const BtnFormacion = styled.button`
  font-size: 12px;
  color: ${(props) => props.color};
  background: none;
  border: 1px solid ${(props) => props.color};
  border-radius: 5px;
  width: 114px;
  height: 38px;
  margin: 2px;
  padding: 0 1rem;
/*  position: relative;
  bottom: -110px;*/
  :hover {
    background: ${(props) => props.color};
    color: white;
  }
`

export const Modalidad = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: absolute;
  top: 35%;
  left: -15%;
  padding-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props)=> props.background};
  p{
    color: white;
    font-size: 12px;
  }
`

export const FechaFormacion = styled.div`
  position: absolute;
  text-align: center;
  top: 0;
  right: 0.5rem;
  width: 55px;
  height: 72px;
  padding: .5rem;
  font-size: 12px;
  line-height: 1.2;
  span:first-child{
    font-size: 16px;
    font-weight: 700;
    white-space: break-spaces;
  }
  border-radius: 0 0 5px 5px;
  color: white;
  background-color: ${(props)=> props.background};
`