import React from 'react';
import styled from 'styled-components';

export const ContainerTitle = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  text-transform: uppercase;
  @media (max-width: 768px) {
    width: 100%;
  };
`

export const TitleProduct = styled.h2`
  font-size: 22px;
  margin-bottom: .25rem;
  span{
    padding-left: 4px;
  }
`

export const SubTitleProduct = styled.p`
  color: #444444;
  font-size: 16px;
  margin-bottom: .25rem;
  span{
    padding-left: 4px;
  }
`

function NameProduct(props) {
    return (
        <React.Fragment>
            <ContainerTitle>
                <div>
                    <TitleProduct>{props.title}</TitleProduct>
                    <SubTitleProduct>
                        {props.lab}. Ref: {props.referencia}
                    </SubTitleProduct>
                </div>
            </ContainerTitle>
        </React.Fragment>
    );
}

export {NameProduct};