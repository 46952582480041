import React from "react";
import styled from 'styled-components';

const PIndicador = styled.p`
  font-size: 14px;
  margin: 1rem 0;
  color: var(--color-fuente);
`

export function ParrafoIndicador(props) {
    return(
        <React.Fragment>
            <PIndicador>{props.parrafo}</PIndicador>
        </React.Fragment>
    );
}
