import styled from 'styled-components';

export const SpanFilter = styled.span`
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${(props) => props.color};
  padding-left: 3%;
`

export const ContainerBtn = styled.div`
  --color: ${(props) => props.background};
  .activeTab{
    background: var(--color);
    color: white;
  }
`

export const AccessStyle = styled.button`
  width: 3rem;
  height: 3rem;
  padding: 2px;
  background: none;
  color: ${(props) => props.background};
  border: none;
`
// Inicio de diseño de tarjetas de contenido
export const Cardsection = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));

  padding-left: 1rem;
`

export const ContainerCards = styled.div`
  align-items: center;
  margin: 0 0 1rem;
  .cardList{
    padding-left: 1rem;
  }
`

export const ModalContend = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  @media (max-width: 768px) {
    width: clamp(350px, 95%, 600px);
    margin: 3rem auto;
  }
`
export const ModalDetalleArticulo = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: absolute;
  z-index: 999;
`

export const NameProduct = styled.a`
  font-size: .8rem;
  font-weight: 500;
  color: #444444;
  margin: 0;
  text-decoration: none;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  :hover {
    color: ${(props) => props.background};
  }
`


export const ImgProductDetail = styled.img`
  height: 400px;
  width: 100%;
  object-fit: contain;
  @media (max-width: 768px) {
    height: 100%;
  }
`

export const Tr = styled.tr`
  font-size: 14px;
  padding: 0;
  margin: 0;
  @media screen and (max-width: 1500px) {
    margin-bottom: .625em;
  }
`

export const Innumber = styled.input`
  width: clamp(50px, 90px, 120px);
`

export const ImgArt = styled.img`
  width: 80px;
  height: 80px;
`

export const DivPagination = styled.div`
  padding: 3rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
`


export const H6TitlePrices = styled.h6`
  font-size: 14px;
  color: #B6B6B6;
  margin: 0;
  padding-bottom: 5px;
`

export const ContentTitle = styled.div`
  display: flex;
  align-items: center;
`

export const ContainerImg = styled.div`
  --btnFav: ${(props) => props.fav};
  position: relative;
  width: 100%;

  .addedFav {
    color: var(--btnFav);
  }

  :hover .btnfav {
    display: block;
  }
`

export const ContainerMultiImg = styled.div`
  width: 100%;
  display: flex;
  gap: .5rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 1rem 0;
`

export const FootNote = styled.div`
  font-size: 8pt;
  border: none;
  margin: 4px 0 0 0;
`
