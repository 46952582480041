import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {Button, View} from '@aws-amplify/ui-react';

const Separator = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-color: var(--bs-light); /* Bootstrap bg-light equivalent */

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: white;
    z-index: 0;
  }

  strong {
    z-index: 1;
    font-size: 1.25rem; /* Bootstrap fs-5 equivalent */
  }

  hr {
    flex-grow: 1;
    margin: 0 0.5rem;
    border: none;
    border-top: 1px solid var(--bs-dark); /* Slightly visible border */
  }
`;

export const LoginFooterUI = ({ onForgotPassword }) => {
  const navigate = useNavigate();

  return (
    <View textAlign="center">
      <div className="d-flex flex-column">
        <Button
          className="mb-2"
          fontWeight="normal"
          onClick={onForgotPassword}
          size="small"
          variation="link"
        >
          ¿Olvidó su contraseña?
        </Button>

        <Separator>
          <hr />
          <strong>O regístrate</strong>
          <hr />
        </Separator>

        <div className="d-flex flex-row justify-content-center gap-3 py-4 bg-light">
          <Button
            className="bg-white"
            onClick={() => navigate('/registroclientes')}
          >
            Solicitar acceso web
          </Button>
          <Button className="bg-white" onClick={() => navigate('/registro')}>
            No soy cliente
          </Button>
        </div>
      </div>
    </View>
  );
};
