import {useCallback, useEffect, useMemo, useState} from 'react';
import {fetchAll} from '../infrastructure/CatalogApi';
import {useFilterContext} from '../infrastructure/FilterContext';
import {remoteDataWithSetter} from '../../common/utils/remoteDataWithSetter';
import {initial, isSuccess, mapRemoteData} from '../../common/domain/RemoteData.model';

/**
 * @param {RemoteData<ApiResponse<CatalogItem>>} apiResponseRemoteData
 * @returns {{pageNumber: number, totalPages: number}} // TODO: create type
 */
const extractPagination = (apiResponseRemoteData) => {
  let result = { totalPages: 0, pageNumber: 0 };
  if (isSuccess(apiResponseRemoteData)) {
    result = {
      totalPages: apiResponseRemoteData.value.totalPages,
      pageNumber: apiResponseRemoteData.value.pageable.pageNumber
    };
  }
  return result;
};

export const useCatalogItemsRemoteData = () => {
  const [apiResponseRemoteData, setApiResponseRemoteData] = useState(initial(null));
  const { filters } = useFilterContext();

  const pagination = useMemo(
    () => extractPagination(apiResponseRemoteData),
    [apiResponseRemoteData]
  );

  const catalogItemsRemoteData = useMemo(
    () => mapRemoteData(apiResponseRemoteData, (it) => !!it ? it.content : []),
    [apiResponseRemoteData]
  );

  useEffect(() => {
    const fetchProducts = () => fetchAll(filters, { page: 0, amount: 20 });
    remoteDataWithSetter(setApiResponseRemoteData, fetchProducts).then();
  }, [filters]);

  const loadCatalogItems = useCallback(page => {
    const fetchProducts = () => fetchAll(filters, { page: page, amount: 20 });
    remoteDataWithSetter(setApiResponseRemoteData, fetchProducts).then();
  }, [filters]);

  return { catalogItemsRemoteData, pagination, loadCatalogItems };
};

