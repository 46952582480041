import {useTenantContext} from '../../tenant/TenantContext';
import useIsMobile from '../../helpers/isMobile';
import {Breadcrumb} from 'react-bootstrap';
import TuneIcon from '@mui/icons-material/Tune';
import {AccessStyle, ContainerBtn, SpanFilter} from '../../ui';
import {useState} from 'react';
import {ProductFilters} from './ProductFilters';
import {useCatalogActiveTab} from '../application/useCatalogActiveTab';
import {CatalogContainer} from './CatalogContainer';
import {GridViewSharp, ListSharp} from '@mui/icons-material';

export const CatalogPage = () => {
  const { primaryColor, secondaryColor } = useTenantContext();
  const { activeTab, changeActiveTab } = useCatalogActiveTab();
  const isTabled = useIsMobile().isTablet;
  const [hide, setHide] = useState(isTabled);

  return (
    <div className="container">
      <Breadcrumb id={'Productos'} />

      <div className="row">
        <div className="col-12 col-lg-3 mb-4">
          <div className="mb-4 d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <TuneIcon style={{ color: secondaryColor }} />
              <SpanFilter color={secondaryColor}>Filtros</SpanFilter>
              {
                isTabled && (
                  <div className="form-check form-switch ms-2">
                    <input className="form-check-input" type="checkbox" role="switch" id="filterSwitch" checked={!hide} onChange={() => setHide(!hide)} />
                  </div>
                )
              }
            </div>

            <ContainerBtn background={primaryColor}>
              <AccessStyle
                title="grid"
                id="grid"
                background={primaryColor}
                onClick={() => changeActiveTab('grid')}
                className={`tab-button ${activeTab === 'grid' ? 'activeTab' : ''}`}>
                <GridViewSharp style={{ fontSize: 35 }} />
              </AccessStyle>
              <AccessStyle
                title="list"
                id="list"
                background={primaryColor}
                onClick={() => changeActiveTab('list')}
                className={`tab-button ${activeTab === 'list' ? 'activeTab' : ''}`}>
                <ListSharp style={{ fontSize: 35 }} />
              </AccessStyle>
            </ContainerBtn>
          </div>

          {
            hide || <ProductFilters />
          }
        </div>

        <div className="col-12 col-lg-9 p-0">
          <CatalogContainer data={{ activeTab: activeTab }} />
        </div>
      </div>
    </div>
  );
};