import {isInfoTag} from '../domain/Tag.model.ts';

export const TagListUI = ({ data }) => {
  const { tags } = data;
  return (
    <div className="d-flex flex-row align-items-center gap-1 flex-wrap">
      {
        tags.map((tag, index) => (
            <span key={index}
                  className={`badge border ${isInfoTag(tag) ? 'border-primary text-primary' : 'border-warning text-warning'}`}>
              {tag.value}
            </span>
          )
        )
      }
    </div>
  );
};
