import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';

export const ContainerModal = styled.div`
  display: grid;
  place-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0, .7);
  z-index: 999;
`

export const DivModal = styled.div`
  display: grid;
  width: clamp(16rem, 90%, 30rem);
  max-height: 90vh;
  overflow-y: auto;
  position: fixed;
  box-shadow: rgba(100, 100, 111, 0.3) 0 7px 29px 0;
  background-color: white;
  border: 2px solid rgb(240, 240, 240);
  border-radius: .5rem;
  padding: 1rem;
`


export const ModalUI = ({ onClose, title = '', children }) => {
  const handleModalDialogClick = (e) => {
    e.stopPropagation();
  };

  return (
    <ContainerModal>
      <DivModal onClick={handleModalDialogClick}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>{title}</h3>
          <button type="button" className="btn btn-danger p-1"
                  onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        {children}
      </DivModal>
    </ContainerModal>
  );
};
