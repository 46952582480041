import ApiClient from '../../API/api';
import type {Address} from '../models/Address';

const GET_DELIVERY_ADDRESSES_ENDPOINT = 'account/address/delivery';

const getDeliveryAddresses = (): Promise<Address> => {
  return ApiClient.performRequest(GET_DELIVERY_ADDRESSES_ENDPOINT);
};

const AddressService = {
  getDeliveryAddresses,
};

export default AddressService ;
