import {ContentTitle, FootNote, H6TitlePrices} from '../../../ui';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import './tableCart.css';
import {Spinner} from 'react-bootstrap';
import {CartContenido, ContainerProductsCar, ImgPro, TableProducts, TextCartInfoDrop} from '../../../ui/components/navbar/NavbarStyles.elements';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import {InfoOutlined} from '@mui/icons-material';
import TruncateTooltipUI from '../../../common/ui/TruncateTooltipUI';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

export const CartPreviewUI = ({
  cart,
  loading,
  onDeleteProduct,
  onEditProduct,
  onPlaceOrder,
  onCatalogClick
}) => {
  return (
    <CartContenido>
      <ContentTitle>
        <div className="d-flex flex-row align-items-center gap-2 mb-2">
          <ShoppingCartIcon />
          <span className="fs-4 fw-bold">Tu carrito</span>
        </div>
      </ContentTitle>
      {
        loading === true ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : (
          <>
            {
              (!cart || cart?.products.length === 0) &&
              <div className="my-3 d-flex flex-column justify-content-center gap-3 align-items-center">
                <div className="d-flex flex-row gap-2">
                  <TextCartInfoDrop>
                    <span>Actualmente no hay productos en tu carrito</span>
                    <ShoppingCartOutlinedIcon />
                  </TextCartInfoDrop>
                </div>
                <button type="button" className="btn btn-info" onClick={() => onCatalogClick()}>
                  Ir al catalogo
                </button>
              </div>
            }

            {
              cart?.products.length >= 1 &&
              <ContainerProductsCar>
                <TableProducts>
                  <table className="table">
                    <thead>
                    <tr>
                      <th scope="col">Producto</th>
                      <th scope="col">Precio unitario</th>
                      <th scope="col">Precio total (sin iva)</th>
                      <th scope="col">Iva</th>
                      <th scope="col">Unidades</th>
                      <th scope="col">Acciones</th>
                    </tr>
                    </thead>
                    {cart?.products.map((item, index) => (
                      <tbody key={index}>
                      <tr className="vertAlign">
                        <td key={item.code}>
                          <div className="row">
                            <div className="col-2 text-center">
                              {
                                !!item.packParentId ? (
                                  <CardGiftcardIcon className="text-primary" fontSize="large" />
                                ) : (
                                  <ImgPro src={item.image} alt="ImgProduct" />
                                )
                              }
                            </div>
                            <div className="col-auto ms-2 d-flex flex-column justify-content-start">
                              <small className="fw-bold">
                                {
                                  !!item.name && <TruncateTooltipUI text={item.name} maxLength={50} />
                                }
                              </small>
                              <small className="text-muted">Ref {item.code}</small>
                            </div>
                          </div>
                        </td>
                        <td>{item.sellingPrice.toFixed(2)}€</td>
                        <td>{(item.sellingPrice * item.quantity).toFixed(2)}€</td>
                        <td>{item.tax}%</td>
                        <td className="unidades">
                          {item.quantity} Uds
                        </td>
                        <td>
                          {
                            item.packParentId === null && (
                              <div className="d-flex flex-row align-items-center gap-1">
                                <button type="button"
                                        className="btn btn-primary rounded-circle p-1"
                                        onClick={() => onEditProduct(item)}>
                                  <EditIcon />
                                </button>
                                <button type="button"
                                        className="btn btn-primary rounded-circle p-1"
                                        onClick={() => onDeleteProduct(item)}>
                                  <DeleteIcon />
                                </button>
                              </div>
                            )
                          }
                        </td>
                      </tr>
                      </tbody>
                    ))}
                  </table>
                </TableProducts>
                <div className="d-flex flex-row align-items-center justify-content-end gap-3">
                  <div className="d-flex flex-row align-items-start gap-2">
                    <div>
                      <H6TitlePrices>Descuentos:</H6TitlePrices>
                      <span className="text-primary fw-bolder fs-3">
                  <b>{cart?.discount.toFixed(2)} €</b>
                </span>
                    </div>
                    <div>
                      <H6TitlePrices>Total:</H6TitlePrices>
                      <span className="text-secondary fw-bolder fs-3">
              <b>{cart?.subtotal.toFixed(2)}€</b>
              </span>
                      <FootNote>IVA no incluido</FootNote>
                    </div>
                  </div>
                  <div>
                    <button className="btn btn-primary" onClick={() => onPlaceOrder()}>
                      <LibraryAddCheckIcon />
                      <span>Tramitar pedido</span>
                    </button>
                  </div>
                </div>
              </ContainerProductsCar>
            }
            {
              cart?.deprecatedProducts?.length > 0 && (
                <div className="d-flex align-items-end-center gap-1">
                  <InfoOutlined className="text-danger" style={{ width: '2rem' }} />
                  <div>
                    Tienes <strong className="text-danger">{cart?.deprecatedProducts?.length}</strong> productos desactualizados. Borra o edita estos productos para tramitar el
                    pedido <a className="text-decoration-underline text-info" onClick={() => onPlaceOrder()}>aquí</a>
                  </div>
                </div>
              )
            }
          </>
        )}
    </CartContenido>
  );
};