import {Authenticator, Button, Heading, Image, useAuthenticator, useTheme, View} from '@aws-amplify/ui-react';
import type {ResourcesConfig} from 'aws-amplify';
import {Amplify} from 'aws-amplify';
import {useTenantContext} from '../../tenant/TenantContext';
import {useNavigate} from 'react-router-dom';
import {confirmResetPassword, fetchAuthSession, resetPassword, signIn, signOut} from '@aws-amplify/auth';
import {useEffect} from 'react';
import {setupAwsAmplifyI18N} from '../../helpers/setupAwsAmplifyI18N';
import {LoginFooterUI} from '../../common/ui/LogInFooterUI';

const authConfig: ResourcesConfig['Auth'] = {
    Cognito: {
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        loginWith: {
            username: true,
            email: false,
            phone: false
        }
    }
};

Amplify.configure({
    Auth: authConfig
});

// TODO: needed because amplify form buttons' text cannot be changed
setupAwsAmplifyI18N();

const buildUsername = (userCode, tenantId) => `${userCode}-${tenantId}`;

export const AwsCustomAuthenticator = ({children}) => {
    const {user} = useAuthenticator(context => [context.user]);
    const navigate = useNavigate();
    const {tenantId} = useTenantContext();

    useEffect(() => {
        if (user) {
            navigate('/');
        }
    }, [navigate, user]);

    return (
        <Authenticator hideSignUp={true}
                       components={{
                           Header() {
                               const {tokens} = useTheme();
                               const {businessName, logo} = useTenantContext();

                               return (
                                   <View textAlign="center" padding={tokens.space.large}>
                                       <Image alt={businessName} src={logo}/>
                                   </View>
                               );
                           },
                           ConfirmResetPassword: {
                               Footer() {
                                   const {toSignIn} = useAuthenticator();
                                   return (
                                       <Button size="small" variation="link" onClick={toSignIn}>
                                           Volver a inicio de sesión
                                       </Button>
                                   );
                               }
                           },
                           SignIn: {
                               Header() {
                                   const {tokens} = useTheme();

                                   return (
                                       <Heading
                                           textAlign="center"
                                           padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                                           level={3}
                                       >
                                           Pedidos Online
                                       </Heading>
                                   );
                               },
                               Footer() {
                                   const {toForgotPassword} = useAuthenticator();
                                   return <LoginFooterUI onForgotPassword={toForgotPassword} /> ;
                               }
                           }
                       }}
                       formFields={{
                           signIn: {
                               username: {
                                   label: null,
                                   placeholder: 'Código de cliente',
                                   isRequired: true
                               },
                               password: {
                                   label: null,
                                   placeholder: 'Contraseña',
                                   isRequired: true
                               }
                           },
                           forceNewPassword: {
                               password: {
                                   placeholder: 'Nueva contraseña',
                                   label: null
                               },
                               confirm_password: {
                                   placeholder: 'Por favor, confirme su nueva contraseña',
                                   label: null,
                                   isRequired: true,
                               }
                           },
                           forgotPassword: {
                               username: {
                                   label: null,
                                   placeholder: 'Código de cliente'
                               }
                           },
                           confirmResetPassword: {
                               confirmation_code: {
                                   placeholder: 'Código enviado a tu email',
                                   label: null
                               },
                               password: {
                                   placeholder: 'Nueva contraseña',
                                   label: null
                               },
                               confirm_password: {
                                   placeholder: 'Confirmar contraseña',
                                   label: null
                               }
                           }
                       }}
                       services={{
                           async handleSignIn(input) {
                               try {
                                   const username = buildUsername(input.username, tenantId);
                                   const signInOutput = await signIn({
                                       ...input,
                                       username
                                   });
                                   const session = await fetchAuthSession();
                                   if (session.tokens) {
                                       const userGroups = session.tokens.accessToken.payload['cognito:groups'] || [];
                                       if (userGroups.includes('admin')) {
                                           await signOut();
                                           return Promise.reject(new Error('Los usuarios administradores no tienen permitido iniciar sesión.'));
                                       }
                                       window.location.href = '/';
                                   }
                                   return signInOutput;
                               } catch (error) {
                                   console.error(error)
                                   await signOut();
                                   if (error?.name === 'NotAuthorizedException') {
                                       return Promise.reject(new Error('Nombre de usuario or contraseña incorrectos.'));
                                   }
                                   return Promise.reject(new Error('Ha ocurrido un error inesperado, por favor contacte con nosotros.'));
                               }
                           },
                           async handleForgotPassword(input) {
                               const username = buildUsername(input.username, tenantId);
                               try {
                                   return await resetPassword({
                                       ...input,
                                       username
                                   });
                               } catch (error) {
                                   if (error?.name === 'LimitExceededException') {
                                       return Promise.reject(new Error('Se ha excedido el límite de intentos, inténtelo después de un tiempo.'));
                                   }
                               }
                           },
                           async handleForgotPasswordSubmit(input) {
                               const username = buildUsername(input.username, tenantId);
                               try {
                                   return await confirmResetPassword({
                                       ...input,
                                       username
                                   });
                               } catch (error) {

                               }
                           }
                       }}
                       className="position-absolute top-50 start-50 translate-middle">
            {children}
        </Authenticator>
    );
};