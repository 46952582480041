import React, {useEffect, useState} from 'react';
import {Notificaciones} from '../../../user/ui/cuenta.elements';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {AlertTable, Marcar} from '../alertascuenta.elements';
import {useTenantContext} from '../../../tenant/TenantContext';
import {Indicador} from '../../../ui';
import {useNotificationContext} from '../../contexts/NotificationContext';
import {Spinner} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {NotificationDetailsModalUI} from '../NotificationDetailModalUI';

export function NotificationView() {
  const { primaryColor } = useTenantContext();

  const {
    allNotifications,
    pendingNotifications,
    readNotifications,
    loading,
    loadNotifications,
    changeReadStatus
  } = useNotificationContext();

  const [vernotificacion, setVernotificacion] = useState(false);
  const [notificacionunitaria, setNotificacionunitaria] = useState([]);

  const changeReadState = async (id, readState) => {
    try {
      await changeReadStatus(id, readState);
    } catch (error) {
      toast.error('Error al marcar como leída la notificación');
    }
  };

  const showDetailsPopup = (estado, id) => {
    setVernotificacion(estado);
    allNotifications.forEach(elemento => {
      if (elemento.id === id) {
        setNotificacionunitaria(elemento);
      }
    });
  };
  const formatDate = (date) => {
    let day = date.getUTCDate();
    let month = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'][date.getMonth() + 1];
    let year = date.getFullYear();
    return day + ' ' + month + ' ' + year;
  };

  useEffect(() => {
    loadNotifications();
  }, [loadNotifications]);
  return (
    <React.Fragment>
      <NotificationDetailsModalUI
        data={{
          notification: notificacionunitaria,
          show: vernotificacion
        }}
        actions={{
          handleClose: () => setVernotificacion(false),
        }} />
      <div>
        <Indicador indicador={'Historial de notificaciones'} />
        {
          loading === true ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            <>
              {
                <div>
                  <div className="row">
                    <div className="col">Notificaciones sin leer</div>
                  </div>
                  {
                    pendingNotifications.length === 0 ? (
                      <div className="alert alert-light">No hay notificaciones sin leer.</div>
                    ) : (
                      <Notificaciones className="row mb-4">
                        <div className="col-xl-12">
                          <AlertTable>
                            <thead>
                            <tr>
                              <th>Tipo</th>
                              <th>Mensaje</th>
                              <th>Fecha</th>
                              <th>Acciones</th>
                              <th>Ver</th>
                            </tr>
                            </thead>
                            <tbody>
                            {pendingNotifications.map((noti, index) => (
                              <React.Fragment key={index}>
                                <tr key={index}>
                                  <td><b>{noti.datos.tipo}</b></td>
                                  <td>{noti.datos.asunto}</td>
                                  <td>{formatDate(new Date(noti.fechanotificacion))}</td>
                                  <td><Marcar color={primaryColor} onClick={() => changeReadState(noti.id, !noti['notificacionleida'])}>Marcar como leído</Marcar></td>
                                  <td>
                                    <a onClick={() => showDetailsPopup(!vernotificacion, noti.id)}><VisibilityIcon /></a>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                            </tbody>
                          </AlertTable>
                        </div>
                      </Notificaciones>
                    )
                  }

                  <div className="row">
                    <div className="col">Notificaciones leídas</div>
                  </div>
                  {
                    readNotifications.length === 0 ? (
                      <div className="alert alert-light">No hay notificaciones leidas.</div>
                    ) : (
                      <Notificaciones className="row">
                        <div className="col-xl-12">
                          <AlertTable>
                            <thead>
                            <tr>
                              <th>Tipo</th>
                              <th>Mensaje</th>
                              <th>Fecha</th>
                              <th>Acciones</th>
                              <th>ver</th>
                            </tr>
                            </thead>
                            <tbody>
                            {readNotifications.map((noti, index) => (
                              <React.Fragment key={index}>
                                <tr key={index}>
                                  <td><b>{noti.datos.tipo}</b></td>
                                  <td>{noti.datos.asunto}</td>
                                  <td>{formatDate(new Date(noti.fechanotificacion))}</td>
                                  <td><Marcar color={primaryColor} onClick={() => changeReadState(noti.id, !noti['notificacionleida'])}>Marcar como no leída</Marcar></td>
                                  <td>
                                    <a onClick={() => showDetailsPopup(!vernotificacion, noti.id)}><VisibilityIcon /></a>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                            </tbody>
                          </AlertTable>
                        </div>
                      </Notificaciones>
                    )
                  }
                </div>
              }
            </>
          )
        }
      </div>
    </React.Fragment>
  );
}
