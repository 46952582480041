/**
 * @typedef {Object} PasswordUpdate
 * @property {string} oldPassword - The user's current password.
 * @property {string} newPassword - The user's new password.
 * @property {string} repeatPassword - Confirmation of the new password.
 */

export const isInvalid = (passwordUpdate) => {
  const { oldPassword, newPassword, repeatPassword } = passwordUpdate;
  return !oldPassword || !newPassword || newPassword !== repeatPassword;
};
