import styled from "styled-components";

export const AlertTable = styled.table`
  width: 100%;

  thead tr th {
    color: #858585;
    font-weight: 400;
  }

  thead th:nth-last-child(-n+3) {
    text-align: right;
  }

  tbody td:nth-last-child(-n+3) {
    text-align: right;
    max-width: 6rem;
  }

  tbody tr {

  }

  th, td {
    padding: 1rem;
  }

  tbody tr:nth-child(odd) {
    background-color: #FBF9F9;
  }
`

export const Marcar = styled.a`
  text-decoration: underline;
  color: ${(props)=> props.color};
`