import React from 'react';

interface Props {
  showLastUnitsMsg?: boolean;
  showStock?: boolean;
  lastUnitsMsg?: string;
  availableUnits: number;
  requiredQuantity: number;
  insufficientUnitsMsg: number;
}

export const ProductAvailabilityUI = (props: Props) => {
  const {
    showLastUnitsMsg,
    showStock,
    lastUnitsMsg,
    availableUnits,
    requiredQuantity,
    insufficientUnitsMsg
  } = props;

  return (
    <>
      {
        (() => {
          let result;
          if (showLastUnitsMsg === true) {
            result = (
              <small className="text-info">{!!lastUnitsMsg ? lastUnitsMsg : 'Últimas unidades disponibles'}</small>
            );
          } else if (availableUnits <= 0) {
            if (!!insufficientUnitsMsg) {
              result = (<small className="text-warning">{insufficientUnitsMsg}</small>);
            } else {
              result = (<small className="text-warning">Disponible próximamente</small>);
            }
          } else if (showStock === false && (!requiredQuantity || (!!requiredQuantity && requiredQuantity <= availableUnits))){
            result = (<small className="text-primary">Producto disponible</small>);
          } else if (showStock === false || (!!requiredQuantity && requiredQuantity > availableUnits)) {
            if (!!insufficientUnitsMsg) {
              result = (<small className="text-warning">{insufficientUnitsMsg}</small>);
            } else {
              result = (<small className="text-warning">Stock insuficiente.</small>);
            }
          } else {
            result = (<small className="text-primary">{availableUnits} unidades disponibles</small>);
          }
          return result;
        })()
      }
    </>
  );
};
