import {isFailure, isInitial, isPending, isSuccess} from '../domain/RemoteData.model';
import React from 'react';

const renderChild = (condition, child) => condition ? child : <></>;

/**
 * @param {RemoteData} remoteData
 * @param children
 * @returns {Element}
 * @constructor
 */
export const RemoteDataUI = ({ remoteData, children }) => {
  const allChildren = React.Children.toArray(children);

  const initialChild = allChildren.find(({ type }) => type === RemoteDataUI.Initial);
  const pendingChild = allChildren.find(({ type }) => type === RemoteDataUI.Pending);
  const successChild = allChildren.find(({ type }) => type === RemoteDataUI.Success);
  const failureChild = allChildren.find(({ type }) => type === RemoteDataUI.Failure);

  return (
    <>
      {renderChild(isInitial(remoteData), initialChild)}
      {renderChild(isPending(remoteData), pendingChild)}
      {renderChild(
        isSuccess(remoteData),
        React.cloneElement(successChild ?? <></>, { value: remoteData.value })
      )}
      {renderChild(isFailure(remoteData), failureChild)}
    </>
  );
};

// Functional components for different states
RemoteDataUI.Initial = ({ children }) => <>{children}</>;
RemoteDataUI.Pending = ({ children }) => <>{children}</>;
RemoteDataUI.Success = ({ value, children }) => <>{children(value)}</>;
RemoteDataUI.Failure = ({ children }) => <>{children}</>;
