import {AddressOverview} from './AddressOverview';
import {useAddress} from '../application/useAddress';
import {toast} from 'react-toastify';

export const AddressOverviewContainer = () => {
  const { billingAddress, deliveryAddresses, loading, markAsDefault } = useAddress();

  const handleMarkAsDefault = async (address) => {
    try {
      await markAsDefault(address);
    } catch (error) {
      toast.error('Imposible actualizar dirección de envio predeterminada. Contacte con nostoros');
    } finally {
      toast.success('Dirección de envio predeterminada actualizada.');
    }
  };

  return (
    <AddressOverview
      data={{ billingAddress, deliveryAddresses, loading }}
      actions={{ markAsDefault: handleMarkAsDefault }}
    />
  );
};