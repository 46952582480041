import {Innumber} from '../styles/Articulos.elements';

interface Props {
  data: {
    value: number,
  };
  actions: {
    setValue: (value: number) => void,
  };
}

export const NumberInputUI = ({ data, actions }: Props) => {
  const { value } = data;
  const { setValue } = actions;

  const subtractOne = (value) => {
    const num = Number(value) || 0;
    setValue(num <= 0 ? 0 : num - 1);
  }

  const addOne = (value) => {
    setValue((Number(value) || 0) + 1);
  }

  const onWriteValue = (value) => {
    setValue(Number(value) || '')
  }

  return (
    <div className="d-flex flex-row">
      <button className="btn btn-primary rounded-end-0"
              onClick={(e) => {
                e.stopPropagation();
                subtractOne(value);
              }}>
        -
      </button>
      <Innumber value={value || 0}
                className="ps-2 border-start-0 border-end-0 border-1 border-primary"
                style={{ width: '65px' }}
                onChange={({ target }) => onWriteValue(target.value)}
      />
      <button className="btn btn-primary rounded-start-0"
              onClick={(e) => {
                e.stopPropagation();
                addOne(value);
              }}>
        +
      </button>
    </div>
  );
};