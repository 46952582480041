import React from 'react';

// TODO: once it is possible, rename the price object with the CatalogItem priceInformation names so we use the same naming convention
export const ProductPriceUI = ({ price }) => {
  return (
    <>
      {
        price?.sellingPrice ? (
          <div>
            <strong className="fs-5">{price?.sellingPrice.toFixed(2)}€</strong>
            &nbsp;
            {
              price?.sellingPrice < price?.basePrice && (
                <small className="text-danger text-decoration-line-through">
                  {price?.basePrice.toFixed(2)}€
                </small>
              )
            }
          </div>
        ) : (
          <span className="text-warning">Precio online no disponible</span>
        )
      }
    </>
  );
};