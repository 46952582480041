import type {Cart} from '../models/Cart';
import ApiClient from '../../API/api';

const GET_CART_ENDPOINT = 'cart';
const VALIDATE_CART_ENDPOINT = 'cart/validate';
const PUT_PRODUCT_EQUIVALENCE_CHARGE_ENDPOINT = 'cart/product/:productId/recargo';
const DELETE_PRODUCT_ENDPOINT = 'cart/product/:productId';

export class CartRepository {
  static get(): Promise<Cart> {
    return ApiClient.performRequest(GET_CART_ENDPOINT);
  };

  static validate(): Promise<boolean> {
    return ApiClient.performRequest(VALIDATE_CART_ENDPOINT, {
      headers: { 'Content-type': 'text/plain' },
    });
  };

  static setEquivalenceCharge(productId, equivalenceCharge): Promise<void> {
    return ApiClient.performRequest(PUT_PRODUCT_EQUIVALENCE_CHARGE_ENDPOINT,
      {
        method: 'PUT',
        pathParams: { productId: productId },
        queryParams: { recargo: equivalenceCharge }
      }
    );
  };

  static async removeProduct(productId) {
    return ApiClient.performRequest(
      DELETE_PRODUCT_ENDPOINT,
      { method: 'DELETE', pathParams: { productId: productId } }
    );
  };
}

