import {useMemo} from 'react';
import {findItemAvailability, generateItemTags} from '../domain/CatalogService';

/**
 * @param {CatalogItem} item
 * @param {number?} requiredQuantity
 * @returns {CatalogItem & {availability: Availability, tags: Tag}}
 */
export const useEnhancedItem = (
  item,
  requiredQuantity?
) => {
  return useMemo(() =>
      ({
        ...item,
        tags: generateItemTags(item),
        availability: findItemAvailability(item, requiredQuantity)
      }),
    [
      item,
      requiredQuantity
    ]);
};
