import {ExclusiveDealsSelectorUI} from './ExclusiveDealsSelectorUI';
import {BulkDealsSelectorUI} from './BulkDealsSelectorUI';
import {GiftDealsSelectorUI} from './GiftDealsSelectorUI';
import {extractSingleQuantityOffer} from '../domain/ProductDetails.model';

export const DealSelector = ({ productDetails, selection, onSelectionChange }) => {

  return (
    <div className="fw-lighter">
      <ExclusiveDealsSelectorUI selection={selection} exclusiveDeals={productDetails.priceDetailedInformation.offers} onDealSelection={onSelectionChange} />
      <GiftDealsSelectorUI selection={selection} giftDeals={productDetails.priceDetailedInformation.promos} onDealSelection={onSelectionChange} />
      <BulkDealsSelectorUI selection={selection} bulkDeals={productDetails.priceDetailedInformation.lots} onDealSelection={onSelectionChange} />
      {
        !extractSingleQuantityOffer(productDetails) && (
          <div className="px-2 d-flex flex-row gap-3 justify-content-start align-items-center">
            <input type="radio"
                   name="noDeal"
                   checked={!selection}
                   onChange={(event) => {
                     event.target.checked && onSelectionChange(null);
                   }} />
            <strong>No quiero ninguna promoción</strong>
          </div>
        )
      }
    </div>
  );
};