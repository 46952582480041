import {Navigation} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Titles} from '../../components/Theme/titles';
import {CatalogItemCard} from './CatalogItemCard';
import {useRelatedCatalogItems} from '../application/useRelatedCatalogItems';
import {RemoteDataUI} from '../../common/ui/RemoteDataUI';
import {Spinner} from 'react-bootstrap';

/**
 * @param {number} data.productCode Catalog Item code
 * @returns {JSX.Element}
 * @constructor
 */
export const RelatedCatalogItemsSwiper = ({ data }) => {
  const { productCode } = data;
  const { relatedCatalogItemsRemoteData } = useRelatedCatalogItems(productCode);

  return (
    <div className="row">
      <div className="col-12 align-self-start">
        <Titles textuno="Productos" textdos="Relacionados" />
      </div>
      <div className="col-11 offset-1 bg-body-secondary p-3 rounded-2" style={{ minHeight: '300px' }}>
        <RemoteDataUI remoteData={relatedCatalogItemsRemoteData}>
          <RemoteDataUI.Pending>
            <div className="d-flex justify-content-center align-items-center h-100">
              <Spinner />
            </div>
          </RemoteDataUI.Pending>

          <RemoteDataUI.Success>
              {(value) => (
              <Swiper
                slidesPerView={5}
                spaceBetween={10}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  0: {
                    slidesPerView: 1
                  }, 767: {
                    slidesPerView: 2
                  }, 991: {
                    slidesPerView: 3
                  }, 1400: {
                    slidesPerView: 4
                  }, 1680: {
                    slidesPerView: 5
                  }
                }}
                className="mySwiper"
              >
                {value.map((catalogItem, index) => (
                  <SwiperSlide key={index}>
                    <CatalogItemCard
                      className="swiper-no-swiping"
                      key={index}
                      data={{ catalogItem }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </RemoteDataUI.Success>
        </RemoteDataUI>
      </div>
    </div>
  );
};
