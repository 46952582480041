import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {TenantContextProvider} from './tenant/TenantContext';
import {AuthenticatorProvider} from '@aws-amplify/ui-react-core';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <AuthenticatorProvider>
      <TenantContextProvider>
        <App />
      </TenantContextProvider>
    </AuthenticatorProvider>
  </BrowserRouter>
);
