import {useEffect, useState} from 'react';
import {initial, isSuccess} from '../../common/domain/RemoteData.model';
import {signup as _signup} from '../infrastructure/UserApi';
import {remoteDataWithSetter} from '../../common/utils/remoteDataWithSetter';
import {useNavigate} from 'react-router-dom';

export const useSignup = () => {
  const [signupRemoteData, setSignupRemoteData] = useState(initial(null));
  const navigate = useNavigate();

  const signup = async (userInfo, tenantId) => {
    const dataSetterCallback = () => _signup(userInfo, tenantId);
    await remoteDataWithSetter(setSignupRemoteData, dataSetterCallback);
  }

  useEffect(() => {
    if (isSuccess(signupRemoteData)) {
      setTimeout(() => { navigate('/iniciar-sesion'); }, 3000);
    }
  }, [signupRemoteData]);

  return { signupRemoteData, signup };
}