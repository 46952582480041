import HttpRequestError from './models/HttpRequestError';
import {fetchAuthSession} from '@aws-amplify/auth';

const BASE_URL = process.env.REACT_APP_API_URL;

const performRequest = async (path, {
  method = 'GET',
  headers = {},
  body,
  pathParams = {},
  queryParams = {},
  useJwt = true
} = {}) => {

  if (!['GET', 'POST', 'PUT', 'DELETE'].includes(method)) {
    throw Error(`Method ${method} does not exist`);
  }

  if (useJwt) {
    const session = await fetchAuthSession();
    const jwt = session?.tokens?.idToken?.toString();
    if (!jwt) {
      throw Error(`Missing token: ${jwt}`);
    }
    headers = { ...headers, 'Authorization': `Bearer ${jwt}` };
  }

  const config = {
    method, headers: {
      'accept': 'application/json',
      'Content-Type': 'application/json',
      ...headers
    }
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  return fetch(constructUrl(BASE_URL, path, pathParams, queryParams), config)
  .then(it => parseResponse(it, config.headers['Content-Type']))
  .catch(err => {
    throw new HttpRequestError(err.message || 'Unknown error', err.response);
  });
};

const constructUrl = (base_url, path, pathParams, queryParams) => {
  const url = replacePathParams(`${base_url}/${path}`, pathParams);
  const queryString = buildQueryParamString(queryParams);
  if (queryString) {
    return `${url}?${queryString}`;
  }
  return url;
};

const replacePathParams = (rawUrl, pathParams) => {
  const replaceKeyFn = (str, key) => str.replace(`:${key}`, encodeURIComponent(pathParams[key]));
  return Object.keys(pathParams).reduce(replaceKeyFn, rawUrl);
}

const buildQueryParamString = (queryParams) =>
  Object.entries(queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

const parseResponse = async (response, contentType) => {
  let result;
  switch (contentType) {
    case 'application/json':
      result = await response.json().catch(() => null);
      break;
    case 'application/pdf':
      result = await response.blob();
      break;
    default:
      result = await response.text();
  }
  if (Number(result?.code)) {
    throw new HttpRequestError(result.message, result.code);
  }
  return result;
};

const ApiClient = {
  performRequest
};

export default ApiClient;

/**
 * Adapts the performRequest function to return a Promise<RemoteData>
 * @param {string} path - The API endpoint path.
 * @param {Object} options - The request options, including method, headers, etc.
 * @returns {Promise<RemoteData>}
 */
export const requestRemoteData = async (path, options = {}) => {
  try {
    const result = await performRequest(path, options);
    return { state: 'success', value: result };
  } catch (error) {
    return { state: 'failure', value: null, error: error.message || 'Unknown error' };
  }
};
