import React, {useEffect, useState} from 'react';
import {Breadcrumb} from '../../../components/Theme/breadcrumb';
import {Containermenu, ContainerTitle, ContenedorFormaciones, ContenedorGlobal} from '../../../components/Theme/theme.elements';
import {CourseCard} from '../course-card/CourseCard';
import ReactPaginate from 'react-paginate';
import {ButtonFilter, ContainerElements, DivPagination} from '../../../ui';
import {useDocumentTitle} from '../../../components/Theme/useDocumentTitle';
import {CourseSearcher} from '../course-searcher/CourseSearcher';
import {useTenantContext} from '../../../tenant/TenantContext';

const apiUrl = process.env.REACT_APP_API_URL;


function Courses() {
    useDocumentTitle("Formaciones")
    const { primaryColor, secondaryColor } = useTenantContext();
    const [formaciones, setFormaciones] = useState([]);
    const [orderBy, setOrderBy] = useState("");
    const [search, setSearch] = useState('');
    const [mes, setMes] = useState('');
    const [year, setYear] = useState('');
    const [tipo, setTipo] = useState('');
    const [sector, setSector] = useState('');
    const [datesFilterValues, setDatesFilterValues] = useState([]);
    const [organizador, setOrganizador] = useState('');
    const [itemOffset, setItemOffset] = useState(0);
    /*------------------ Consumo api ------------------*/
    const getDataLearning = async () => {
        const requestOption = {
            method: "GET",
            headers: {"accept": "application/json"}
        };
        const URL = `${apiUrl}/learning`
        const response = await fetch(URL, requestOption)
        if(response.ok) {
            const data = await response.json()
            setFormaciones(data.sort((a,b) => (a.fechaformacion > b.fechaformacion ? 1 : -1)))
        }
    }

    // Funcion de activacion del API
    useEffect(() => {
        window.scrollTo(0, 0);
        if(!formaciones || formaciones.length === 0) {
            getDataLearning()
                .catch(error => console.log(error));
        }
        if(formaciones && Array.isArray(formaciones)) {

            var formacionesDates = formaciones.map (formacion => {
                const [year, month] = formacion.fechaformacion.split('-');
                return month + " / " + year;
            })
            formacionesDates.sort((a, b) => {
                const [monthA, yearA] = a.split(' / ').map(Number);
                const [monthB, yearB] = b.split(' / ').map(Number);
                if (yearA !== yearB) { return yearA - yearB; }
                return monthA - monthB;
              });
            setDatesFilterValues([...new Set(formacionesDates)]);
        }
    }, [formaciones])
    /*------------------ Fin Consumo api ------------------*/

    /*------------------ Busqueda y ordenamiento ------------------*/
    const handleSearch = e => {
        setSearch(e.target.value);
    }
    const handleOrderBy = event => {
        setOrderBy(event.target.value);
    };
    /*------------------ Fin Busqueda y ordenamiento ------------------*/

    const setMesAndYear = (date) => {
        if(date === '') {
            setYear('')
            setMes('')
        } else {
            const [month, year] = date.split(' / ').map(Number);
            setYear(year)
            setMes(month)
        }
    };

    const getUniqueValues = (data, prop) => {
        if (!data) {
            return []
        }
        const values = data.map(item => item[prop]);
        return [...new Set(values)];
    };
    const getMonths = getUniqueValues(formaciones, 'mes')
    // Ordenamiento de meses
    const compareMonths = (a, b) => {
        const mesesOrdenados = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        return mesesOrdenados.indexOf(a) - mesesOrdenados.indexOf(b);
    };
    const meses = getMonths.sort(compareMonths);

    const getYears = getUniqueValues(formaciones, 'year').sort((a, b) => b - a)
    const getTypes = getUniqueValues(formaciones, 'formacion');

    const getSector = getUniqueValues(formaciones, 'sector_veterinario');
    const getOrganizer = getUniqueValues(formaciones, 'organizador');
    /*------------------ Filtros ------------------*/
    // Captura de datos de formulario de formacion
    const handleFormationFilters = () => {
        if (!formaciones) {
            return
        }
        let filteredFormations = formaciones

        /*Order*/
        if (orderBy === 'nuevo') {
            filteredFormations = filteredFormations.sort((a, b) => (new Date(b.fechaformacion) - new Date(a.fechaformacion)));
        }
        if (orderBy === 'A-Z') {
            filteredFormations = filteredFormations.sort((a, b) => (a.ttitulo_formacion > b.ttitulo_formacion ? 1 : -1));
        }
        if (orderBy === 'Z-A') {
            filteredFormations = filteredFormations.sort((a, b) => (a.ttitulo_formacion < b.ttitulo_formacion ? 1 : -1));
        }
        if (orderBy === 'antiguo') {
            filteredFormations = filteredFormations.sort((a, b) => (new Date(a.fechaformacion) - new Date(b.fechaformacion)))
        }

        filteredFormations = filteredFormations.filter(item => {
            return item.titulo_formacion.toLowerCase().includes(search.toLowerCase())
        });

        if (mes !== '' && year !== '' && tipo !== '' && sector !== '' && organizador !== '') {
            filteredFormations = filteredFormations.filter(
                item => item.mes === mes
                    && item.year === year
                    && item.formacion === tipo
                    && item.sector_veterinario === sector
                    && item.organizador === organizador)
        } else if (mes !== '' && year !== '') {
            filteredFormations = filteredFormations.filter(item => item.mes === mes && item.year === year)
        } else if (mes !== '') {
            filteredFormations = filteredFormations.filter(item => item.mes === mes)
        } else if (year !== '') {
            filteredFormations = filteredFormations.filter(item => item.year === year)
        }
        if (tipo !== '') {
            filteredFormations = filteredFormations.filter(item => item.formacion === tipo)
        }
        if (sector !== '') {
            filteredFormations = filteredFormations.filter(item => item.sector_veterinario === sector)
        }
        if (organizador !== '') {
            filteredFormations = filteredFormations.filter(item => item.organizador === organizador)
        }

        return filteredFormations;
    }
    const filteredDataFormatios = handleFormationFilters();

    const reStartFilters = e => {
        e.preventDefault();
        setSearch('');
        setMes('');
        setYear('');
        setTipo('');
        setSector('');
        setOrganizador('');
    }

    /*------------------ Fin Filtros ------------------*/

    /*------------------ Paginación ------------------*/
    const itemsPerPage = 6;
    const endOffset = itemOffset + itemsPerPage;
    // currentItems son los datos que se estan renderizando
    const currentItems = filteredDataFormatios.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(filteredDataFormatios.length / itemsPerPage);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % filteredDataFormatios.length;
        setItemOffset(newOffset);
    };
    /*------------------ Fin Paginación ------------------*/

    return (
        <React.Fragment>
            <ContenedorGlobal>
                <Breadcrumb ubicacion={"Formaciones"} id={"Formaciones"}/>
                <div className="row">
                    <div className="col-lg-3">
                        <Containermenu>
                            <ContainerTitle color={secondaryColor}>
                                Filtrar por
                            </ContainerTitle>
                            <ContainerElements>
                                <form onSubmit={handleFormationFilters}>
                                <div className="row">
                                        <div className="col">
                                            <label htmlFor="years">Fecha de la formación</label><br/>
                                            <select onChange={(e) => setMesAndYear(e.target.value)} name="year" id="Years">
                                                <option value="">Todas</option>
                                                {datesFilterValues.map((date, i) => (
                                                    <option key={i} value={date}>{date}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="row">
                                        <div className="col">
                                            <label htmlFor="Tipo">Tipo</label><br/>
                                            <select onChange={(e) => setTipo(e.target.value)} name="tipo" id="Tipo">
                                                <option value="">Todos los tipos</option>
                                                {getTypes.map((type, i) => (
                                                    <option key={i} value={type}>{type}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="row">
                                        <div className="col">
                                            <label htmlFor="Sector">Sector Veterinario</label><br/>
                                            <select onChange={(e) => setSector(e.target.value)} name="sector"
                                                    id="Sector">
                                                <option value="">Todos los sectores</option>
                                                {getSector.map((sector, i) => (
                                                    <option key={i} value={sector}>{sector}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="row">
                                        <div className="col">
                                            <label htmlFor="Organizador">Organizador</label>
                                            <select onChange={(e) => setOrganizador(e.target.value)} name="organizador"
                                                    id="Organizador">
                                                <option value="">Todos los organizadores</option>
                                                {getOrganizer.map((organizador, i) => (
                                                    <option key={i} value={organizador}>{organizador}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <br/>
                                    <div>
                                        <ButtonFilter
                                            type="submit"
                                            background={primaryColor}
                                            hover={primaryColor}
                                            onClick={() => reStartFilters}>
                                            Reiniciar Filtros
                                        </ButtonFilter>
                                    </div>
                                </form>
                            </ContainerElements>
                        </Containermenu>
                    </div>
                    <div className="col-lg-9">
                        <CourseSearcher
                            handleSearch={handleSearch}
                            search={search}
                            orderBy={orderBy}
                            handleOrderBy={handleOrderBy}/>
                        <ContenedorFormaciones>
                            <CourseCard data={currentItems}/>
                        </ContenedorFormaciones>
                        <DivPagination>
                            <ReactPaginate
                                previousLabel="<"
                                nextLabel=">"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName="pagination"
                                activeClassName="active"
                            />
                        </DivPagination>
                    </div>
                </div>
            </ContenedorGlobal>
        </React.Fragment>
    );
}

export {Courses};