import React, {useEffect} from 'react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import {useChangePassword} from '../application/useChangePassword';
import {isFailure, isSuccess} from '../../common/domain/RemoteData.model';

export const ContainerForm = styled.div`
  padding: 1rem;

  .container-input {
    width: auto;
  }

  h3 {
    text-align: center;
    font-weight: 400;
    font-size: 24px;
  }
`;

export const ContainerInput = styled.div`
  /*position: relative;*/
  margin-top: .5rem;
  color: #696969;

  span {
    /*position: absolute;*/
    /*top: -24px;*/
    left: 4px;
  }

  select, input, textarea {
    border: 1px solid #E7E7E7;
    border-radius: 10px;
    width: 100%;
    height: 40px;
    padding: 1rem;
    outline: none;
  }

  select {
    color: #696969;
    padding: 0 1rem;
  }
`;

export const DivBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

export const ChangePass = ({ onPasswordChanged }) => {
  const {
    passwordUpdate,
    resetPasswordRemoteData,
    setOldPassword,
    setNewPassword,
    setRepeatPassword,
    updatePassword,
    isInvalid
  } = useChangePassword();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updatePassword();
  };

  useEffect(() => {
    if (isSuccess(resetPasswordRemoteData)) {
      toast.success('Contraseña actualizada!');
      onPasswordChanged();
    } else if (isFailure(resetPasswordRemoteData)) {
      toast.error(resetPasswordRemoteData.error);
    }
  }, [resetPasswordRemoteData]);

  return (
    <>
      <ContainerForm>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <ContainerInput>
            <span>Contraseña anterior</span>
            <input autoComplete="off"
                   type="password"
                   placeholder="Contraseña anterior"
                   required
                   name="password"
                   value={passwordUpdate.oldPassword}
                   onChange={(e) => setOldPassword(e.target.value)} />
          </ContainerInput>
          <ContainerInput>
            <span>Nueva Contraseña</span>
            <input autoComplete="off"
                   type="password"
                   placeholder="Nueva contraseña"
                   required
                   name="password"
                   value={passwordUpdate.newPassword}
                   onChange={(e) => setNewPassword(e.target.value)} />
          </ContainerInput>
          <ContainerInput>
            <span>Vuelva a escribir la contraseña</span>
            <input autoComplete="off"
                   type="password"
                   placeholder="Contraseña"
                   required
                   name="password"
                   value={passwordUpdate.repeatPassword}
                   onChange={(e) => setRepeatPassword(e.target.value)} />
          </ContainerInput>
          <DivBtn>
            <button type="submit" className="btn btn-primary" disabled={isInvalid(passwordUpdate)}>
              Actualizar contraseña
            </button>
          </DivBtn>
        </form>
      </ContainerForm>
    </>
  );
};