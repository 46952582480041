import styled from 'styled-components';

export const ContenedorGlobal = styled.section`
  width: 82%;
  margin: auto auto 16px auto;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 0 16px;
  }

  @media (max-width: 768px) {
    padding: 0 8px;
  }

  &.narrow {
    max-width: 800px;
  }

  &.embedded {
    width: 100%;
    margin: auto;
  }
`


export const Containermenu = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border: 1.1px solid #D0D0D0;

  &.taxonomy {
    overflow-wrap: anywhere;
    border: none;
    padding: 0;
  }
`;


export const ContainerTitle = styled.h1`
  position: absolute;
  top: -13px;
  left: 1rem;
  font-weight: 700;
  font-size: 18px;
  color: var(--bs-primary);
  background: white;

  &.categoria {
    top: 0;
    left: 0;
    position: relative;
  }
`

export const ContainerSearhformations = styled.div`
  width: 100%;
  height: 80px;
  padding: .5rem 1.5rem;
  display: flex;
  align-items: end;
  margin-bottom: 20px;
  background-color: #eeeeee;
  border-radius: .5rem;
  @media (max-width: 768px) {
    height: auto;
    padding: 2rem 0 1rem;
  }
`

export const ContenedorFormaciones = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(min(49%,555px),1fr));
  gap: 1rem;
`
export const TitleContainer = styled.div`
  display: grid;
  padding: 1rem 2.5rem;
  width: 100%;
  text-transform: uppercase;
  margin: auto;
  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem 1.3rem;
  };
`

export const HorizontalLine = styled.div`
  width: 350px;
  padding-left: 48px;
  margin-left: -39px;
  border-bottom: .5px solid #E5E5E5;
  position: relative;
  @media (max-width: 598px) {
    width: auto;
    margin-left: -20px;
  };
  @media (max-width: 768px) {
    width: auto;
    margin-left: -20px;
  };
`

export const VerticalLine = styled.div`
  height: 120px;
  padding-bottom: 48px;
  border-left: .5px solid #E5E5E5;
  position: absolute;
  @media (max-width: 598px) {
    height: 75px;
  };
  @media (max-width: 768px) {
    height: 75px;
  };
`

export const TitleH1 = styled.h1`
  color: var(--bs-primary);
  font-size: 24px;
  @media (max-width: 768px) {
    font-size: 16px;
  };
  @media (max-width: 598px) {
    font-size: 16px;
  };
  @media (max-width: 398px) {
    font-size: 16px;
  };
`

export const TitleSpam = styled.span`
  color: ${(props) => props.color};
  font-size: 30px;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 20px;
  };
  @media (max-width: 598px) {
    font-size: 20px;
  };
  @media (max-width: 398px) {
    font-size: 20px;
  };
`

export const Breadcrumbone = styled.a`
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  color: var(--bs-primary);
  :hover{
    color: var(--bs-primary);
  }
`

export const TitleSeccion = styled.h1`
  color: var(--bs-secondary);
  font-size: 35px;

  @media (max-width: 1024px) {
    font-size: 20px;
  }
`

export const OrderSelect = styled.select`
  background: none;
  padding: .5rem;
  color: #606060;
  border: 1px solid #606060;
  border-radius: .25rem;
`