import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useCartContext} from '../../context/CartContext';
import {CartPreviewUI} from './CartPreviewUI';


export function CartPreview({ setModalCar }) {
  const { cart, loading, loadCart, removeProduct } = useCartContext();
  const navigate = useNavigate();

  useEffect(() => {
    loadCart();
  }, [loadCart]);

  const navigateTo = (path) => {
    navigate(path);
    setModalCar(null);
  }

  return (
    <CartPreviewUI cart={cart}
                   loading={loading}
                   onCatalogClick={() => navigateTo('/articulos')}
                   onEditProduct={(product) => navigateTo(`/articulos/${encodeURIComponent(product.code)}`)}
                   onDeleteProduct={(product) => removeProduct(product)}
                   onPlaceOrder={() => navigateTo('/checkout')}
    />
 );
}