import {useFilterContext} from '../infrastructure/FilterContext';
import React from 'react';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {FloatingDropdown} from '../../ui/components/FloatingDropdown';
import {useFilterOptionsRemoteData} from '../application/useFilterOptionsRemoteData';
import {RemoteDataUI} from '../../common/ui/RemoteDataUI';
import {Spinner} from 'react-bootstrap';

export const Title = styled.span`
  font-size: 0.8em;
  margin-left: 15px;
  font-weight: 400;
  margin-top: 5px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 1280px) {
    font-size: 0.7em;
  };
`;


export const SearchLinksFilter = () => {
  const { updateFilters } = useFilterContext();
  const { filterOptionsRemoteData } = useFilterOptionsRemoteData();
  const navigate = useNavigate();

  /**
   * @param {Filters} filters
   */
  const onClick = (filters) => {
    updateFilters(filters);
    navigate('/articulos');
  };

  return (
    <RemoteDataUI remoteData={filterOptionsRemoteData}>
      <RemoteDataUI.Pending>
        <div className="d-flex justify-content-center align-items-center">
          <Spinner size="sm" />
        </div>
      </RemoteDataUI.Pending>

      <RemoteDataUI.Success>
        {
          filterOptions => (
            <div className="row justify-content-around align-items-center">
              {
                filterOptions.macrofamilias.map(({ macrofamilia, familias }, i) => (
                  <div className="col-12 col-sm-6 col-md-auto" key={i}>
                    <FloatingDropdown>
                      <FloatingDropdown.Title>
                        <Title className="fw-bold fs-6" onClick={() => onClick({ macroFamily: macrofamilia })}>
                          {macrofamilia}
                        </Title>
                      </FloatingDropdown.Title>
                      <FloatingDropdown.Items>
                        {
                          familias.map(({ familia, subfamilias }, j) => (
                            <FloatingDropdown key={j} placement="after">
                              <FloatingDropdown.Title>
                                <Title onClick={() => onClick({ macroFamily: macrofamilia, family: familia })}>
                                  {familia}
                                </Title>
                              </FloatingDropdown.Title>
                              <FloatingDropdown.Items>
                                {subfamilias.map(({ subfamilia }, k) => (
                                  <Title
                                    key={k}
                                    onClick={() =>
                                      onClick({
                                        macroFamily: macrofamilia,
                                        family: familia,
                                        subFamilies: [subfamilia]
                                      })
                                    }
                                  >
                                    {subfamilia}
                                  </Title>
                                ))}
                              </FloatingDropdown.Items>
                            </FloatingDropdown>
                          ))
                        }
                      </FloatingDropdown.Items>
                    </FloatingDropdown>
                  </div>
                ))}
            </div>
          )
        }
      </RemoteDataUI.Success>
    </RemoteDataUI>
  );

};