import {useEnhancedItem} from '../../catalog/application/useEnhancedItem';
import {AvailabilityUI} from '../../common/ui/AvailabilityUI';
import {ProductPriceUI} from './ProductPriceUI';
import {DealSelector} from './DealSelector';
import {PriceBreakdownUI} from './PriceBreakdownUI';
import {NumberInputUI} from '../../ui/components/NumberInputUI';
import React, {useMemo, useState} from 'react';
import {useProductSelection} from '../application/useProductSelection';
import {hasDeals} from '../domain/ProductDetails.model';
import {useCartContext} from '../../cart';
import {toast} from 'react-toastify';
import {AddUpdateButton} from '../../common/ui/AddUpdateButton';

export const PurchasePanel = ({ productDetails, defaultQuantity, afterAddToCart = () => null }) => {
  const { productSelection, dealSelection, setDealSelection, setQuantity } = useProductSelection(productDetails, defaultQuantity);
  const { isOnCart, addProduct } = useCartContext();
  const [fetching, setFetching] = useState(false);

  const disabled = useMemo(() =>
      productSelection.price?.sellingPrice <= 0 || productSelection.quantity <= 0 || fetching,
    [fetching, productSelection]
  );
  const enhancedItem = useEnhancedItem(productDetails, productSelection.quantity);

  const addToCart = async () => {
    try {
      setFetching(true);
      await addProduct(productSelection);
      toast.success(`'${productDetails.pro_nombre || productDetails.pro_codigo}' agregado al carrito exitosamente.`);
    } catch (error) {
      toast.error(`'${productDetails.pro_nombre || productDetails.pro_codigo}' no pudo agregarse al carro. Por favor, reintente más tarde o contacte con nosotros.`);
    } finally {
      afterAddToCart();
      setFetching(false);
    }
  };

  return (
    <div className="shadow-sm p-2">
      <div className="mb-2 d-flex flex-row align-items-bottom gap-2">
        <AvailabilityUI data={{ availability: enhancedItem.availability }} />
      </div>
      <div className="d-flex flex-row justify-content-start align-items-end gap-2">
        <div className="d-flex align-items-center gap-2">
          {
            !!productSelection.price && <span>Precio Unidad: </span>
          }
          <ProductPriceUI price={productSelection.price} />
        </div>
      </div>
      {
        !!productSelection.discount?.text && (
          <p>Porcentaje de ahorro: <strong className="text-primary">{productSelection.discount?.text}</strong></p>
        )
      }
      {
        hasDeals(productDetails) && (
          <>
            <hr />
            <DealSelector productDetails={productDetails}
                          selection={dealSelection}
                          onSelectionChange={setDealSelection}
            />
          </>
        )
      }
      {
        productSelection.price && (
          <>
            <hr />
            <div className="row justify-content-end align-items-center gap-2 gap-sm-0">
              <div className="col-auto">
                <PriceBreakdownUI
                  price={{
                    sellingPrice: productSelection.price.sellingPrice * productSelection.quantity,
                    basePrice: productSelection.price.basePrice * productSelection.quantity
                  }}
                  discount={productSelection.discount}
                />
              </div>
              <div className="col-auto">
                <NumberInputUI data={{ value: productSelection.quantity }} actions={{ setValue: setQuantity }} />
              </div>
              <div className="col-auto">
                <AddUpdateButton
                  shouldAdd={isOnCart(productDetails.id)}
                  disabled={disabled}
                  loading={fetching}
                  onClick={() => addToCart()}
                />
              </div>
            </div>
          </>
        )
      }
    </div>
  );
};
