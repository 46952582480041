import ApiClient from '../../API/api';
import type {Manufacturer} from '../models/Manufacturer';

const GET_NOTIFICATIONS_ENDPOINT = 'manufacturer';

export class ManufacturerService {
  static getAll(): Promise<Manufacturer[]> {
    return ApiClient.performRequest(GET_NOTIFICATIONS_ENDPOINT);
  }

  static getAllWithLogo(): Promise<Manufacturer[]> {
    return this.getAll().then(manufacturers =>
      manufacturers.filter(({imageLogo}) => !!imageLogo)
    );
  }

  static get(id): Promise<Manufacturer> {
    return this.getAll().then(manufacturers =>
      manufacturers.find(it => it.id === id)
    );
  }
}