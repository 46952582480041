import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import React from 'react';

export const ManufacturersFilterUI = ({ data, actions }) => {
  const { manufacturers, filters, disabled } = data;
  const { changeSelection } = actions;

  return (
    <>
      {
        manufacturers?.length > 0 && (
          <Accordion className="accordion taxonomy-dropdowns">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content">
              <span>Fabricantes</span>
            </AccordionSummary>
            <AccordionDetails>
              {
                manufacturers.map((manufacturer, index) => (
                    <div key={index} className="ms-3 form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        disabled={disabled}
                        value={manufacturer.numproveedor}
                        checked={filters?.manufacturers?.includes(manufacturer.numproveedor) || false}
                        onChange={event => {
                          const { value, checked } = event.target;
                          const currentSelection = filters?.manufacturers || [];

                          const newSelection = checked
                                               ? [...currentSelection, value]
                                               : currentSelection.filter(it => it !== value);

                          changeSelection({ ...filters, manufacturers: newSelection });
                        }}
                      />
                      <label className="form-check-label">
                        <small>{manufacturer.nombrerazonsocial}</small>
                      </label>
                    </div>
                  )
                )
              }
            </AccordionDetails>
          </Accordion>
        )
      }
    </>
  );
};