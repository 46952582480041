import {Modal} from 'react-bootstrap';
import {useCallback, useState} from 'react';
import {ProductDescriptionUI} from './ProductDescriptionUI';

export const ProductDescription = ({ description }) => {
  const [show, setShow] = useState(false);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);


  const handleShowMore = useCallback(() => {
    setShow(true);
  }, []);

  return (
    <>
      <ProductDescriptionUI
        data={{
          description: description,
          maxLength: 300,
          suffix: '...'
        }}
        actions={{
          handleShowMore: handleShowMore
        }}
      />
      <Modal show={show} onHide={handleClose} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            Descripción del producto
          </Modal.Title>
        </Modal.Header>
          <Modal.Body>
              <div dangerouslySetInnerHTML={{__html: description}}/>
          </Modal.Body>
      </Modal>
    </>
  );
};