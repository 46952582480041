import {RemoteDataUI} from '../../common/ui/RemoteDataUI';
import {CatalogUI} from './CatalogUI';
import {useCatalogItemsRemoteData} from '../application/useCatalogItemsRemoteData';
import ReactPaginate from 'react-paginate';
import {ContainerCards, DivPagination} from '../../ui';
import React from 'react';
import {Spinner} from 'react-bootstrap';

export const CatalogContainer = ({ data }) => {
  const { activeTab } = data;
  const { catalogItemsRemoteData, pagination, loadCatalogItems } = useCatalogItemsRemoteData();

  return (
    <RemoteDataUI remoteData={catalogItemsRemoteData}>
      <RemoteDataUI.Pending>
        <div className="text-center"><Spinner /></div>
      </RemoteDataUI.Pending>

      <RemoteDataUI.Failure>
        <div className="alert alert-danger">
          Imposible cargar productor. Intentelo más tarde.
        </div>
      </RemoteDataUI.Failure>;

      <RemoteDataUI.Success>
        {(catalogItems) => (
          <>
            <ContainerCards>
              <CatalogUI data={{ catalogItems: catalogItems, activeTab: activeTab }} />
            </ContainerCards>

            <DivPagination>
              <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={pagination?.totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(selectedItem) => loadCatalogItems(selectedItem.selected)}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={pagination?.pageNumber}
              />
            </DivPagination>
          </>
        )}
      </RemoteDataUI.Success>
    </RemoteDataUI>
  )
    ;
};