import {useEffect, useMemo} from 'react';
import {useErrorLoadingState} from '../../../helpers/hooks/useErrorLoadingState';
import parse from 'html-react-parser';
import {Spinner} from 'react-bootstrap';
import {useManufacturerContext} from '../../context/ManufacturerContext';

const useManufacturerDetailsState = ({ id }) => {
  const { details, loadDetails } = useManufacturerContext();
  const { error, loading, run } = useErrorLoadingState();

  useEffect(() => {
    run(loadDetails, [id]);
  }, [id]);

  return useMemo(() => ({
    details,
    error,
    loading
  }), [
    details,
    error,
    loading
  ]);
};

export const ManufacturerDetails = ({ id }) => {
  const { details, error, loading } = useManufacturerDetailsState({ id });

  if (loading === true) {
    return (
      <div className="text-center m-4">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger">
        Imposible cargar detalles del fabricante. Intentelo más tarde.
      </div>
    );
  }

  return (
    <>
      {
        !!details && (
          <div className="row align-content-center">
            <div className="col-12 col-md-7 order-md-last">
              <h3>{details.nombre}</h3>
              {
                !!(details.descripcion) && (
                  <div>
                    {parse(details.descripcion)}
                  </div>
                )
              }
              {
                !!(details.url) && (
                  <button type="button" className="btn btn-info">
                    <a href={details.url} target="_blank">Conoce más</a>
                  </button>
                )
              }
            </div>
            {
              !!details.imageLogo && (
                <div className="col-12 col-md-5 d-flex justify-content-center">
                  <img className="img-fluid" src={details.imageLogo} alt="imagen laboratorio" />
                </div>
              )
            }
          </div>
        )
      }
    </>
  );
};