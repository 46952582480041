import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import React from 'react';
import useIsMobile from '../../helpers/isMobile';
import {useUserContext} from '../../user/infrastructure/UserContext';
import {useNavigate} from 'react-router-dom';

// TODO: use application layer instead of insfrastructure for getting the user
export const AccountButton = () => {
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();
  const user = useUserContext();

  return (
      <button type="button" className="btn p-0 d-flex align-items-center justify-content-center"
              onClick={() => navigate('/cuenta')}>
        {
          !isMobile && (
            <div className="d-flex flex-column align-items-end" style={{ color: '#787878' }}>
              <small>Cod. cliente</small>
              <small>{user?.code}</small>
            </div>
          )
        }
        <Person2OutlinedIcon fontSize="large" className="text-primary" />
      </button>
  );
};