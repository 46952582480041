/**
 * @enum {string}
 * Enum for Equivalence Charges.
 */
export const EquivalenceCharges = {
  YES: 'YES', // type 1
  NO: 'NO', // type 2
  BY_CHOICE: 'BY_CHOICE', // type 3
};

/**
 * @typedef {Object} User
 * @property {number} id - The unique ID of the user.
 * @property {EquivalenceCharges} equivalenceCharges - The equivalence charges for the user.
 */
