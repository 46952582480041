import {useEffect, useState} from 'react';
import {initial, pending} from '../domain/RemoteData.model';

/**
 *
 * @param initialData
 * @param {function(): Promise<RemoteData>} dataFetchingCallback
 * @returns {RemoteData}
 */
export const useRemoteData = ({ initialData, dataFetchingCallback }) => {
  const [remoteData, setRemoteData] = useState(initial(initialData));

  useEffect(() => {
    const loadRemoteData = async () => {
      setRemoteData(pending());
      setRemoteData(await dataFetchingCallback());
    }
    loadRemoteData().then();
  }, [dataFetchingCallback]);

  return remoteData;
}