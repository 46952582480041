import {Spinner} from 'react-bootstrap';
import React, {useState} from 'react';
import {ProductTagsUI} from './ProductTagsUI';
import {ImgSlider} from '../../ui/components/ImgSlider';
import {AddToFavoritesButton} from './AddToFavoritesButton';
import {ProductDescription} from './ProductDescription';
import {NameProduct} from './nameProduct';

export const ProductDetailsUI = ({ data }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { details, loading, error } = data;

  if (loading === true) {
    return (
      <div className="text-center m-4">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger">
        Imposible cargar producto. Intentelo más tarde
      </div>
    );
  }

  if (!!details) {
    return (
      <div className="row gap-4 gap-lg-0"
           onMouseEnter={() => setIsHovered(true)}
           onMouseLeave={() => setIsHovered(false)}
      >
        <div className="col-12 order-lg-first row align-items-start gap-2">
          <div className="col-12">
            <div className="d-flex justify-content-center align-items-center">
              <div className="position-absolute right-50 translate-middle z-1">
                <AddToFavoritesButton data={{
                  productCode: details.pro_codigo,
                  show: isHovered || true,
                  isFavorite: details.pro_favorito
                }} />
              </div>
              <div>
                <ImgSlider
                  mainImgUrl={details.pro_imagen.imagen_principal}
                  subImageUrls={details.pro_imagen.subimage}
                />
              </div>
            </div>
          </div>
          <div className="col-12 order-md-first">
            <div className="d-flex flex-column flex-xl-row gap-2 align-items-start mb-3">
              <div>
                <NameProduct title={details.pro_nombre}
                             lab={details.pro_laboratorio}
                             referencia={details.pro_codigo}
                />
                {
                  !!details?.ficha_cimavet && (
                    <a target="_blank" rel="noreferrer" href={details.ficha_cimavet}>Ficha técnica</a>
                  )
                }
              </div>
              <ProductTagsUI productDetails={details} />
            </div>
          </div>
          <div className="col-12 d-flex flex-column gap-2">
            <ProductDescription description={details.pro_descripcion} />
          </div>
        </div>
      </div>
    );
  }
};