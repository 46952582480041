import styled from "styled-components";


export const FooterInfo = styled.footer`
  padding-top: 60px;
  width: 100%;
  text-align: center;
  background: var(--white);
  color: #B5B5B5;
`

export const LogoPrincipal = styled.img`
  height: 2.5rem;
  object-fit: contain;
`

export const SubTitlesFooter = styled.p`
  font-size: 14px;
  text-align: left;
  font-weight: 600;
  color: ${(props) => props.background};
`
export const TextTelefono = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.background};
`
export const UlFooter = styled.ul`
  text-align: left;
  margin-top: .5em;
  padding: 0;
`
export const ImageFooterContainer=styled.div`
  text-align: justify;
  justify-content: left;
  font-size: 14px;

`
export const ImageFooter = styled.img`
  width: 7vw;
  @media screen and (max-width: 1023px){
    width: 8rem;
  }
`

export const ImageFooter2 = styled.img`
  width: 6vw;
  @media screen and (max-width: 1023px){
    width: 8rem;
  }
`

export const UlFooterInfo = styled.ul`
  margin-top: .5em;
  text-align: left;
`

export const UlFooterInfoMobile = styled.ul`
  margin-top: .5em;
  text-align: center;
  @media (max-width: 991px) {
    padding: 0;
  }
`

export const RedesSociales = styled.div`
  color: ${(props) => props.color};
  text-align: left;
  font-weight: 700;
  margin-left: 48px;
  a{

  }

  .red {
    width: 3rem;
    height: 3rem;
  }

  .facebook {
    color: #0084FE;
  }

  .twitter {
    color: #00EFFE;
  }

  .instagram {
    color: #FF006B;
  }

  .linkedin {
    color: #0a66c2;
  }
`

export const LiFooter = styled.li`
  list-style: none;
  a {
    text-decoration: none;
    color: #B5B5B5;
    :hover{
      color: #606060;
    }
  }
  margin: 10px 0;
  position: relative;
  padding-left: 20px;
  svg {
    float: left;
    left: -18px;
    position: absolute;
  }
`

export const Registro = styled.p`
  text-align: center;
  font-size: 13px;
`
export const TextoRegistro = styled.p`

`

export const FooterContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 32px;
  margin: 0 auto 32px 0;
  & > div {
    margin: 0 72px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 1280px){
    & > div {
      margin: 0 16px;
    }
  }
  @media screen and (max-width: 1024px){
    & > div {
      margin: 0 8px;
    }
  }
  @media screen and (max-width: 768px){
    padding: 0 8px;
    flex-direction: column;
    & > div {
      margin: 0px 8px;
    }
  }
`
export const FooterLegalText = styled.div `
  text-align: left;
  font-size: 0.7em;
  max-width: 480px;
  div {
    text-align: center;
    margin: 15px 0 0 0;
    width: 100%;
  }
  img {
    height: 80px;
    width: auto;
  }
  @media screen and (max-width: 1420px){
    max-width: 360px;
  }
  @media screen and (max-width: 1024px){
    max-width: initial;
    padding-top: 48px;
    padding-bottom: 0px;
  }

`