import {Titles} from '../../components/Theme/titles';
import {Spinner} from 'react-bootstrap';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper';
import React from 'react';
import {CatalogItemCard} from './CatalogItemCard';
import {ProductPriceUI} from '../../product/ui/ProductPriceUI';


export const ProductSwiperUI = ({ data }) => {
  const { title, subTitle, products } = data;
  return (
    <div className="row">
      <div className="col-12 align-self-start">
        <Titles textuno={title} textdos={subTitle} />
      </div>
      <div className="col-11 offset-1 bg-body-secondary p-3 rounded-2" style={{ minHeight: '300px' }}>
        {
          (() => {
            if (products.loading === true) {
              return <div className="text-center m-4"><Spinner /></div>;
            }

            if (products.error) {
              return <div className="alert alert-danger">Imposible cargar productos.</div>;
            }

            return (
              <>
                {
                  products.value.length === 0 ? (
                    <div className="alert alert-light">
                      No hay productos destacados
                    </div>
                  ) : (
                    <Swiper
                      slidesPerView={5}
                      spaceBetween={10}
                      navigation={true}
                      modules={[Navigation]}
                      breakpoints={{
                        0: {
                          slidesPerView: 1
                        }, 767: {
                          slidesPerView: 2
                        }, 991: {
                          slidesPerView: 3
                        }, 1400: {
                          slidesPerView: 4
                        }, 1680: {
                          slidesPerView: 5
                        }
                      }}
                      className="mySwiper">
                      {
                        products.value.map((product, index) => (
                          <SwiperSlide key={index}>
                            <CatalogItemCard data={{ catalogItem: product }}>
                              <CatalogItemCard.Price>
                                <ProductPriceUI
                                  price={{
                                    sellingPrice: product.priceInformation?.bestPrice,
                                    basePrice: product.priceInformation?.originalPrice,
                                  }} />
                              </CatalogItemCard.Price>
                            </CatalogItemCard>
                          </SwiperSlide>
                        ))
                      }
                    </Swiper>
                  )
                }
              </>
            );
          })()
        }
      </div>
    </div>
  );
};