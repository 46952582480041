const RemoteDataState = {
  success: 'success',
  failure: 'failure',
  pending: 'pending',
  initial: 'initial'
};

/**
 * @typedef {Object} RemoteData
 * @template T
 * @property {('success'|'failure'|'pending'|'initial')} state - The state of the remote data.
 * @property {T} value - The value of the remote data.
 * @property {string} error - The error message, if any.
 */

/**
 * @param {RemoteData} remoteData
 * @returns {boolean}
 */
export const isSuccess = (remoteData) =>
  remoteData.state === RemoteDataState.success;

/**
 * @param {RemoteData} remoteData
 * @returns {boolean}
 */
export const isPending = (remoteData) =>
  remoteData.state === RemoteDataState.pending;

/**
 * @param {RemoteData} remoteData
 * @returns {boolean}
 */
export const isFailure = (remoteData) =>
  remoteData.state === RemoteDataState.failure;

/**
 * @param {RemoteData} remoteData
 * @returns {boolean}
 */
export const isInitial = (remoteData) =>
  remoteData.state === RemoteDataState.initial;

/**
 * @param data
 * @returns {RemoteData}
 */
export const initial = (data) =>
  ({ state: RemoteDataState.initial, value: data, error: null });

/**
 * @returns {RemoteData}
 */
export const pending = () =>
  ({ state: RemoteDataState.pending, value: null, error: null });

/**
 * @param {any} value
 * @returns {RemoteData}
 */
export const success = (value) =>
  ({ state: RemoteDataState.success, value: value });

/**
 * @param {string} msg
 * @returns {RemoteData}
 */
export const failure = (msg) =>
  ({ state: RemoteDataState.failure, error: msg });

/**
 *
 * @param {RemoteData} remoteData
 * @param {function(any): any} mapperFn
 * @returns {RemoteData}
 */
export const mapRemoteData = (remoteData, mapperFn) =>
  ({
    state: remoteData.state,
    value: mapperFn(remoteData.value),
    error: remoteData?.error
  });

/**
 * @param {RemoteData} remoteData
 * @param value - The default if the remoteData is different than success
 * @returns {any}
 */
export const getOrElse = (remoteData, value) =>
  isSuccess(remoteData) ? remoteData.value : value;