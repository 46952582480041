import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import React from 'react';

export const SpeciesFilterUI = ({ data, actions }) => {
  const { species, filters, disabled } = data;
  const { changeSelection } = actions;

  return (
    <>
      {
        species?.length > 0 && (
          <Accordion className="accordion taxonomy-dropdowns">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content">
              <span>Especies</span>
            </AccordionSummary>
            <AccordionDetails>
              {
                species.map((specie, index) => (
                    <div key={index} className="ms-3 form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        disabled={disabled}
                        value={specie}
                        checked={filters?.species?.includes(specie) || false}
                        onChange={event => {
                          const { value, checked } = event.target;
                          const currentSelection = filters?.species || [];

                          const newSelection = checked
                                               ? [...currentSelection, value]
                                               : currentSelection.filter(it => it !== value);

                          changeSelection({ ...filters, species: newSelection });
                        }}
                      />
                      <label className="form-check-label">
                        <small>{specie}</small>
                      </label>
                    </div>
                  )
                )
              }
            </AccordionDetails>
          </Accordion>
        )
      }
    </>
  );
};