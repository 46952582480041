import {CartViewUI} from './CartViewUI';
import {useUserContext} from '../../../user/infrastructure/UserContext';
import {useCartContext} from '../../context/CartContext';
import {useEffect} from 'react';

export const CartView = () => {
  const { cart, loadCart, equivalenceChargedProducts, equivalenceChargeFreeProducts, loading } = useCartContext();
  const { equivalenceCharges } = useUserContext();

  useEffect(() => {
    loadCart();
  }, [loadCart]);

  return (
    <CartViewUI equivalenceChargedProducts={equivalenceChargedProducts}
                equivalenceChargeFreeProducts={equivalenceChargeFreeProducts}
                deprecatedProducts={cart?.deprecatedProducts}
                equivalenceCharges={equivalenceCharges}
                loading={loading}
    />
  );
};