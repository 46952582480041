import React, {useState} from 'react';
import {Containermenu, ContainerTitle} from '../../components/Theme/theme.elements';
import {ContainerElements} from '../../ui';
import {Link} from 'react-router-dom';
import {useDocumentTitle} from '../../components/Theme/useDocumentTitle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import GridViewIcon from '@mui/icons-material/GridView';
import {useAuthenticator} from '@aws-amplify/ui-react';
import {ButtonMenuUser} from './cuenta.elements';
import {useTenantContext} from '../../tenant/TenantContext';
import useIsMobile from '../../helpers/isMobile';

export const AccountMenu = () => {
  useDocumentTitle('Mi cuenta');
  const isMobile = useIsMobile().isMobile;
  const [showMenu, setShowMenu] = useState(!isMobile);
  const { primaryColor } = useTenantContext();

  const { signOut } = useAuthenticator((context) => [context.user]);

  const handleLogout = () => {
    signOut();
  };

  return (
    <div className="d-flex flex-column gap-4">
      {
        isMobile && (
          <div className="d-flex justify-content-between border p-2">
            <div className="d-flex gap-2 align-items-center">
              <GridViewIcon fontSize="large" className="text-primary" />
              <span>Menú de usuario</span>
            </div>
            <div role="button" onClick={() => setShowMenu(!showMenu)}>
              <KeyboardArrowDownIcon fontSize="large" className="text-primary" />
            </div>
          </div>
        )
      }

      {
        showMenu && (
          <Containermenu className="col-md-12">
            <ContainerTitle color={primaryColor}>
              Menú de usuario
            </ContainerTitle>
            <ContainerElements>
              <Link to="usuario">
                <ButtonMenuUser background={primaryColor}>
                  Datos de usuario
                </ButtonMenuUser>
              </Link>
              <Link to="direcciones">
                <ButtonMenuUser background={primaryColor}>
                  Direcciones
                </ButtonMenuUser>
              </Link>
              <Link to="Pedidos">
                <ButtonMenuUser background={primaryColor}>
                  Pedidos
                </ButtonMenuUser>
              </Link>
              <Link to="Facturas">
                <ButtonMenuUser background={primaryColor}>
                  Facturas
                </ButtonMenuUser>
              </Link>
              <Link to="Favoritos">
                <ButtonMenuUser background={primaryColor}>
                  Favoritos
                </ButtonMenuUser>
              </Link>
              <Link to={'Alertas'}>
                <ButtonMenuUser background={primaryColor}>
                  Alertas y notificaciones
                </ButtonMenuUser>
              </Link>
              <ButtonMenuUser className="text-danger" onClick={handleLogout}>
                Cerrar sesión
              </ButtonMenuUser>
            </ContainerElements>
          </Containermenu>
        )
      }
    </div>
  );
}