import {addToFavorites, removeFromFavorites} from '../infrastructure/productApi';
import {mapRemoteData} from '../../common/domain/RemoteData.model';

/**
 * @param {string} productCode
 * @param {boolean} isFavorite
 * @returns {Promise<RemoteData<boolean>>}
 */
export const changeProductFavorite = async (productCode, isFavorite) => {
  let result;
  if (isFavorite) {
    result = await removeFromFavorites(productCode);
  } else {
    result = await addToFavorites(productCode);
  }
  return mapRemoteData(result, () => !isFavorite);
}
