
const isDDMMYYYYDate = (strDate): boolean =>
  !!strDate.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);

const parseToSpanishDate = (strDate: string): string | null => {
  const date = new Date(strDate);
  return isNaN(date.getTime()) ? null : date.toLocaleDateString('es-ES');
};

export const spanishDateFormatter = (strDate: string, fallback: string = ''): string => {
  let formatedStrDate = fallback;
  if (isDDMMYYYYDate(strDate)) {
    formatedStrDate = strDate;
  } else {
    formatedStrDate = parseToSpanishDate(strDate) ?? fallback;
  }
  return formatedStrDate;
};
