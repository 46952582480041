export const ProductFamilyFilterUI = ({ data, actions }) => {
    const {
      selection,
      disabled,
      macroFamilies,
      families,
      subFamilies
    } = data;
    const { changeSubFamilies, changeMacroFamily, changeFamily } = actions;

    return (
      <div className="categories-tree">
        <select
          className="form-select taxonomy-categories"
          disabled={disabled}
          value={selection?.macroFamily || ''}
          onChange={event => changeMacroFamily(event.target.value || null)}
        >
          <option value="">Todas las categorías</option>
          {
            macroFamilies.map(name => (
              <option key={name} value={name} defaultValue={selection?.macroFamily}>
                {name}
              </option>
            ))
          }
        </select>

        {
          selection?.macroFamily && (
            <div className="mt-2 my-1">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  disabled={disabled}
                  checked={!selection?.family}
                  onChange={() => changeFamily(null)}
                />
                <label className="form-check-label">
                  <small>Todos</small>
                </label>
              </div>
              {
                families.map(family => (
                    <div key={family}>
                      <div key={family} className="d-flex flex-row align-items-center gap-1">
                        <input
                          className="form-check-input"
                          type="radio"
                          value={family}
                          disabled={disabled}
                          checked={selection?.family === family}
                          onChange={event => changeFamily(event.target.value)}
                        />

                        <label className="form-check-label">
                          <small>{family}</small>
                        </label>
                      </div>

                      {
                        selection?.family === family && subFamilies.map(subfamily => (
                            <div key={subfamily} className="ms-3 form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                value={subfamily}
                                disabled={disabled}
                                checked={selection?.subFamilies?.includes(subfamily) || false}
                                onChange={event => {
                                  const { value, checked } = event.target;
                                  const currentSelection = selection?.subFamilies || [];

                                  const newSelection = checked
                                                       ? [...currentSelection, value]
                                                       : currentSelection.filter(it => it !== value);

                                  changeSubFamilies(newSelection);
                                }}
                              />
                              <label className="form-check-label">
                                <small>{subfamily}</small>
                              </label>
                            </div>
                          )
                        )
                      }
                    </div>
                  )
                )
              }
            </div>
          )
        }


      </div>
    )
      ;
  }
;