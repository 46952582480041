import React from 'react';
import {Footer} from '../ui';
import {Outlet} from 'react-router-dom';

function PublicPage() {
  return (
    <>
      <main className="container">
        <Outlet />
      </main>
      <Footer publicFooter={true} />
    </>
  );
}

export {PublicPage};