import {OverlayTrigger, Popover} from 'react-bootstrap';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import React from 'react';
import {EquivalenceCharges} from '../../../user/domain/User.model';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import {ProductAvailabilityUI} from '../../../product/ui/ProductAvailabilityUI';
import type {Product} from '../../models/Product';

interface Props {
  data: {
    product: Product,
    productDiscount: number,
    userEquivalenceCharges: EquivalenceCharges;
    isDeprecated?: boolean,
  };
  actions: {
    changeProductEquivalenceCharges: () => void,
    edit: () => void,
    remove: () => void
  };
}

export const CartProductUI = ({ data, actions }: Props) => {
  const { product, productDiscount, userEquivalenceCharges, isDeprecated = false } = data;
  const { edit, remove, changeProductEquivalenceCharges } = actions;

  const priceDetailsPopOver = (
    <Popover>
      <Popover.Header>Detalles del precio</Popover.Header>
      <Popover.Body>
        <div className="d-flex flex-column align-products-start">
          <span>Precio base: <strong>{product.basePrice.toFixed(2)}€</strong></span>
          <span>Descuentos: <strong>{productDiscount?.toFixed(2)}%</strong></span>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="card">
      <div className="d-flex flex-lg-row flex-column">
        <div className="d-flex justify-content-center align-items-center"
             style={{ width: '150px' }}>
          {
            product.packParentId === null ? (
              <img src={product.image} className="img-fluid rounded-start p-2" alt="imagen producto" />
            ) : (
              <CardGiftcardIcon className="text-primary" fontSize="large" />
            )
          }
        </div>
        <div className="card-body">
          <div className="row gap-4 gap-lg-0">
            <div className="col-12 col-lg-4 col-xxl-5">
              <div className="d-grid gap-1">
                <span>{product.name}</span>
                <small className="text-muted">REF: {product.code}</small>
                <ProductAvailabilityUI availableUnits={product.stock}
                                       insufficientUnitsMsg={product.outOfStockMessage}
                                       requiredQuantity={product.quantity}
                                       showStock={product.showStock}
                />
              </div>
            </div>
            <div className="col-12 col-lg-8 col-xxl-7">
              <div className="row text-center gap-2 gap-sm-0 justify-content-center justify-content-xl-end">
                <div className="col-5 col-md-auto">
                  <div className="d-flex flex-column">
                    <span>Precio unitario</span>
                    <div className="d-flex flex-row gap-2 justify-content-center">
                      <span>{product.sellingPrice.toFixed(2)}€</span>
                      <OverlayTrigger placement="bottom"
                                      trigger={['hover', 'focus']}
                                      overlay={priceDetailsPopOver}>
                        <InfoTwoToneIcon style={{ cursor: 'pointer', width: '2rem' }} />
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
                <div className="col-5 col-md-auto">
                  <div className={`d-grid ${product.quantity > product.stock ? 'text-danger' : ''}`}>
                    <span >Unidades</span>
                    <span>{product.quantity}</span>
                  </div>
                </div>
                <div className="col-5 col-md-auto">
                  <div className="d-grid">
                    <span>Sub total (Sin IVA)</span>
                    <span>{(product.sellingPrice * product.quantity).toFixed(2)}€</span>
                  </div>
                </div>
                <div className="col-5 col-md-auto">
                  <div className="d-grid">
                    <span>IVA</span>
                    <span>{product.tax.toFixed(2)}%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="mt-2 d-flex flex-row justify-content-end align-items-center">
                {
                  userEquivalenceCharges === EquivalenceCharges.BY_CHOICE && !product.packParentId && (
                    <div className="flex-fill">
                      <button className="btn btn-info" onClick={changeProductEquivalenceCharges}>
                        {
                          product.equivalenceCharge ? (
                            <span>Quitar recargo de equivalencia</span>
                          ) : (
                            <span>Añadir recargo de equivalencia</span>
                          )
                        }
                      </button>
                    </div>
                  )
                }
                {
                  product.packParentId === null && (
                    <div className="d-flex flex-row justify-content-end gap-2">
                      <button type="button"
                              className={`btn ${isDeprecated ? 'btn-warning' : 'btn-primary'}`}
                              onClick={() => edit()}>
                        Editar
                      </button>
                      <button type="button"
                              className="btn btn-danger"
                              onClick={() => remove()}>
                        Borrar
                      </button>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};