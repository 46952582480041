import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import useIsMobile from '../../helpers/isMobile';

const FilterContext = createContext();

export const useFilterContext = () => useContext(FilterContext);

// TODO: refactor. This should moved to the infrastructure folder/layer
export const FilterContextProvider = ({ children }) => {
  const [filters, setFilters] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [hide, setHide] = useState(false);
  const pathname = useLocation();
  const isMobile = useIsMobile().isMobile;

  useEffect(() => {
    setDisabled(false);
  }, [pathname]);

  useEffect(() => {
    setHide(isMobile);
  }, [isMobile]);

  const updateFilters = useCallback((filters) => {
    setFilters({ ...filters });
  }, []);

  const clearFilters = useCallback(() => {
    setFilters({});
  }, []);

  const context = useMemo(() => ({
    filters: filters,
    updateFilters: updateFilters,
    clearFilters: clearFilters,
    disabled: disabled,
    setDisabled: setDisabled,
    hide: hide,
    setHide: setHide
  }), [disabled, filters, hide]);

  return (
    <FilterContext.Provider value={context}>
      {children}
    </FilterContext.Provider>
  );
};