import {useCallback, useEffect, useState} from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import {toast} from 'react-toastify';
import {changeFavorite} from '../infrastructure/productApi';

interface Props {
  actions?: {
    afterChangeFavorite?: () => void,
  };
  data: {
    productCode: string;
    show?: boolean;
    isFavorite: boolean;
  }
}

export const AddToFavoritesButton = ({ data, actions = {} }: Props) => {
  const { productCode, show, isFavorite: _isFavorite }  = data;
  const [isFavorite, setIsFavorite] = useState(false);
  const { afterChangeFavorite } = actions;

  const handleChangeFavorite = useCallback(async () => {
    try {
      await changeFavorite(productCode, isFavorite);
      setIsFavorite(!isFavorite);
      !!afterChangeFavorite && afterChangeFavorite();
    } catch (error) {
      toast.error(`Error al ${isFavorite ? 'eliminar' : 'añadir'} a favoritos ${productCode}.`);
    }
  }, [isFavorite]);

  useEffect(() => {
    if (_isFavorite !== null) {
      setIsFavorite(_isFavorite);
    }
  }, [_isFavorite]);

  return (
    show && (
      <button type="button"
              className={'btn text-primary shadow-sm rounded-circle p-1 bg-white'}
              onClick={(e) => {
                e.stopPropagation();
                handleChangeFavorite().then();
              }}>
        {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
      </button>
    )
  );
};