import styled from 'styled-components';


export const Galerycontainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin: auto;

  .span-1 {
    grid-column: span 1;
  }
  .span-2 {
      grid-column: span 2;
  }
  .span-3 {
      grid-column: span 3;
  }
  .span-4 {
      grid-column: span 4;
  }
  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    .span-4 {
      grid-column: span 3;
    }
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    .span-4 {
      grid-column: span 2;
    }
    .span-3 {
      grid-column: span 2;
    }
  }
  @media (max-width: 468px) {
    grid-template-columns: repeat(1, 1fr);
    .span-4 {
      grid-column: span 1;
    }
    .span-3 {
      grid-column: span 1;
    }
    .span-2 {
      grid-column: span 1;
    }
  }
`

export const Galery__item = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 368px;
`

export const Gallery__img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.8);
`

export const Gallery_title = styled.h2`
  margin: 0;
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;

  & > * {
    font-size: 20px;
    font-weight: bold;
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px;
    text-decoration: none;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-start;
  }
  
  a {
    cursor: pointer;
  }
`

export const Gallery_Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 25%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
`
