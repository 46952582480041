import {Link} from 'react-router-dom';

const ORDERS_PAGE_PATH = '/cuenta/Pedidos'

export const OrderConfirmationUI = (order) => (
  <div className="d-flex flex-column">
    <span>Pedido realizado con código {order.code}</span>
    <Link to={ORDERS_PAGE_PATH}>
      <small className="link-info">Visita tus pedidos</small>
    </Link>
  </div>
);