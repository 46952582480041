import {useCallback, useState} from 'react';

interface Props {
  data: {
    max?: number,
    value?: string,
    placeholder: string,
  };
  actions: {
    handleChange: (text: string) => void,
  };
}

export const TextAreaUI = ({ data, actions }: Props) => {
  const { placeholder, value, max = 200 } = data;
  const { handleChange } = actions;

  const [text, setText] = useState(() => {
    if (!value) {
      return '';
    }
    return value.length > max ? value.slice(0, max) : value;
  });

  const [textSize, setTextSize] = useState(() => {
    if (!value) {
      return 0;
    }
    return value.length > max ? max : value.length;
  });


  const handleOnChange = useCallback(event => {
    const inputValue = event.target.value;
    setText(inputValue);
    setTextSize(inputValue.length);
    handleChange(inputValue);
  }, [handleChange]);

  return (
    <div className="form">
      <textarea
        className={`form-control ${textSize === max ? 'is-invalid' : ''}`}
        value={text}
        onChange={handleOnChange}
        maxLength={max}
        rows="5"
        placeholder={placeholder}
        aria-describedby="charCount"
        aria-label={placeholder}
      ></textarea>

      <div id="charCount" className="me-2 mt-1 d-flex justify-content-end">
        <span className={`text-${textSize === max ? 'danger' : 'muted'}`}>
          {textSize}/{max}
        </span>
      </div>
    </div>
  );
};