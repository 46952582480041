import styled from "styled-components";

export const ContainerBtnRecargos = styled.div`
  display: flex;
  width: fit-content;
  border: solid 1px ${(props) => props.color};
  border-radius: .6rem;
  --background-color: ${(props) => props.bgColor};
  .activeTab {
    background-color: ${(props) => props.bgColor};
    color: white;
  }
`

export const BtnRecargo = styled.button`
  background-color: white;
  height: auto;
  border: none;
  border-radius: .5rem;
  color: ${(props) => props.TextColor};
  padding: .5rem 1vw;
`

export const ContainerResumenCompra = styled.div`
  margin-top: 2rem;
  padding-top: 2.5rem;
  border-top: var(--border);
  .tab-page {
    display: none;
  }
  .active {
    display: block;
  }
`
export const TitleResumenCompra = styled.h4`
  font-size: 20px;
  margin-bottom: 1rem;
  color: var(--bs-primary);
  span{
    font-weight: 600;
  }
  @media (max-width: 992px) {
    text-align: center;
  }
`

export const ContenedorArticulos = styled.div`
  //min-height: 9rem;
  //display: flex;
  //align-items: center;
  //justify-content: space-between;
  //padding: 1rem;
  //margin-bottom: 1rem;
  //@media (max-width: 992px) {
  //  flex-wrap: wrap;
  //}
`
export const CardResumenCompra = styled.div`
  min-height: 9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  //margin-bottom: 1rem;
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
`
export const InformacionOferta = styled.button`
  width: max-content;
  background: ${(props) => props.background};
  padding: .5rem 1rem;
  border-radius: 8px;
  border: none;
  color: var(--white);
  font-size: 12px;
  margin-right: 1rem;
  a{
    text-decoration: none;
    color: white;
  }
`
export const InfoProduct = styled.div`
  display: flex;
  column-gap: 1rem;
  max-width: 50%;
  img{
    width: 6rem;
  }
  span{
    font-weight: 400;
    font-size: 12px;
  }
  @media (max-width: 1200px) {
    max-width: 100%;
    img{
      display: none;
    }
  }
`

export const ValoresProduct = styled.div`
  display: flex;
  align-items: flex-start;
  text-align: right;
  justify-content: end;
  flex-wrap: wrap;
  row-gap: 1rem;
  
  >div{
    min-width: 6rem;
    position: relative;
    padding: 0 1rem;
    display: flex;
    justify-content: flex-start;
    row-gap: .5rem;
    flex-direction: column;
  }
  .unidades, .iva{
    text-align: center;
  }
  div span{
    font-size: 12px;
  }
  @media (max-width: 992px) {
    width: 100%;
    margin-top: 1.5rem;
    text-align: left;
    >div{
      width: 50%;
    }
    .unidades, .iva{
      text-align: center;
    }
  }
`

export const ValoresProductOrden = styled(ValoresProduct)`
  flex-wrap: nowrap;

  div span {
    font-size: 11px;
  }

  > div {
    min-width: 4rem;
  }
`

export const ContainerMenu = styled.div`
  position: relative;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border: 1px solid #dee2e6;
`
export const ContainerDireccion = styled.div`
  h5{
    margin: 0;
    font-size: 14px;
    color: #787878;
  }
  @media (min-width: 992px) and (max-width: 1270px) {
    margin-top: 1.5rem;
  }
`

export const BtnModificarDireccion = styled.button`
  background: none;
  font-size: 14px;
  font-weight: 500;
  padding: .5rem 1rem;
  color: var(--bs-primary);
  border: solid 1px var(--bs-primary);
  border-radius: .5rem;
  margin-top: .5rem;
`