import styled from "styled-components";

export const ContainerNotifications = styled.div`
  padding: .5rem;
  border-top: var(--border);
  text-align: left;
  position: relative;
  overflow-y: auto;
  min-height: 93vh;
`

export const DivNotification = styled.div`
  margin-bottom: 2rem;
  border-bottom: var(--border);
`

export const InfoNotification = styled.div`
  display: flex;
  align-items: start;
  column-gap: .5rem;
  padding: .5rem;
`
export const ImgNotification = styled.img`
  width: 4rem;
  border-radius: .5rem;
`
export const TituloNotification = styled.a`
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  color: #606060;
`

export const TextoNotification = styled.p`
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0;
`

export const ContainerBtnActions = styled.div`
  text-align: right;
  padding: .75rem 0;
  background-color: #eee;
`
export const BtnNotificationActions = styled.a`
  margin-right: 1rem;
  text-decoration: none;
  font-size: 14px;
`

export const BtnNoticias = styled.a`
  text-align: center;
  text-decoration: none;
  position: absolute;
  padding: .5rem 1rem;
  bottom: 2rem;
  border: none;
  color: white;
  background-color: ${(props)=> props.background};
  :hover{
    color: white;
  }
`