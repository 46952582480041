import {useRemoteData} from '../../common/utils/useRemoteData';
import {fetchProductDetails} from '../infrastructure/productApi';
import {useMemo} from 'react';

export const useProductDetailsRemoteData = (productCode) => {
  const fetchProductFn = useMemo(
    () => (() => fetchProductDetails(productCode)),
    [productCode]
  );

  const productDetailsRemoteData = useRemoteData(
    {
      initial: null,
      dataFetchingCallback: fetchProductFn
    });

  return { productDetailsRemoteData };
};