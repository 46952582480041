import React, {useEffect, useMemo, useState} from 'react';
import {useErrorLoadingState} from '../../helpers/hooks/useErrorLoadingState';
import {useNavigate} from 'react-router-dom';
import {ProductSwiperUI} from './ProductSwiperUI';
import {getProducts} from '../infrastructure/CatalogApi';

// TODO: this component is similar to the FeaturedProducts. Find a way to remove this duplication
const useProductSwiperState = ({ filters, config }) => {
  const [products, setProducts] = useState([]);
  const productsErrorLoading = useErrorLoadingState();

  useEffect(() => {
    const loadProducts = async (filters, config) => {
      const response = await getProducts(filters, config);
      setProducts(response.content);
    };

    if (filters) {
      productsErrorLoading.run(loadProducts, [filters, config]);
    }
  }, [filters, config]);

  return useMemo(() => ({
    products,
    productsLoading: productsErrorLoading.loading,
    productsError: productsErrorLoading.error
  }), [
    products,
    productsErrorLoading
  ]);
};

export const ProductSwiper = ({
  filters,
  config,
  title,
  subtitle
}) => {
  const {
    products,
    productsLoading,
    productsError
  }
    = useProductSwiperState({ filters, config });
  const navigate = useNavigate();

  return (
    <ProductSwiperUI
      data={{
        title: title,
        subTitle: subtitle,
        products: { value: products, loading: productsLoading, error: productsError }
      }}
      actions={{
        onClick: (details) => navigate(`/articulos/${encodeURIComponent(details.pro_codigo)}`)
      }}
    />
  );
};