import styled from "styled-components";

export const ContainerArticulos = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill,minmax(270px,1fr));
  justify-items: center;
  margin-bottom: 3rem;
  .favorite{
    margin-bottom: 2.5rem;
  }
`
export const CardArticle = styled.div`
  position: relative;
  background-color: white;
  width: 260px;
  height: 408px;
  padding: 4rem 1rem 1rem;
  box-shadow: 0 1px 5px rgba(140, 140, 140, 0.25);
  margin: .5rem auto;
`

export const CardArticuloBody = styled.div`
  --btnFav: ${(props) => props.fav};
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .imgLink {
    padding: 0 1rem;
  }

  .imgLink img {
    width: 100%;
    object-fit: cover;
    max-height: 12rem;
  }

  :hover .btnfav {
    display: block;
  }

  .addedFav {
    color: var(--btnFav);
  }
`
export const BodyText = styled.div`
  color: #444444;
  text-align: center;
  max-height: 170px;
  height: 8.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .price{
    font-size: 18px;
    font-weight: 500;
    margin-top: .5rem;
  }
`

export const ProdutcName = styled.a`
  color: #444444;
  font-size: .8rem;
  margin: 0;
  text-decoration: none;
  line-height: 1;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const DivNotFound = styled.div`
  display: grid;
  place-items: center;
  text-align: center;
`

export const TextNotFount = styled.h4`
  font-size: 20px;
  span {
    font-size: 14px;
  }
`
