import {createContext, useCallback, useContext, useState} from 'react';
import {ManufacturerService} from '../services/ManufacturerService';

const ManufacturerContext = createContext();

export const useManufacturerContext = () => useContext(ManufacturerContext);

export const ManufacturerContextProvider = ({ children }) => {
  const [details, setDetails] = useState();

  const loadDetails = useCallback(async (id) => {
    setDetails(await ManufacturerService.get(id));
  }, []);

  return (
    <ManufacturerContext.Provider value={{
      details,
      loadDetails
    }}>
      {children}
    </ManufacturerContext.Provider>
  );
};