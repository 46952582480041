import React from 'react';

export const DownloadableDeliveryAddressUI = ({ address }) => {
  return (
    <div>
      <div>{address.name}</div>
      <div>{address.mainInfo}</div>
      <div>{address.zip} {address.location}</div>
      <div>{address.country}</div>
    </div>
  );
};

