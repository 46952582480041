import {requestRemoteData} from '../../API/api';
import {EquivalenceCharges} from '../domain/User.model';
import {mapRemoteData} from '../../common/domain/RemoteData.model';

const GET_SELF_ENDPOINT = 'user/me';
const GET_SELF_DETAILS_ENDPOINT = 'account/userdata';
const RESET_PASSWORD_ENDPOINT = 'user';

/**
 * @param {unknown} user
 * @returns {User}
 */
const parseUser = (user) => {
  let equivalenceCharges;
  if (user.recargo === 1) {
    equivalenceCharges = EquivalenceCharges.YES;
  } else if (user.recargo === 2) {
    equivalenceCharges = EquivalenceCharges.NO;
  } else if (user.recargo === 3) {
    equivalenceCharges = EquivalenceCharges.BY_CHOICE;
  }
  return { id: user.id, equivalenceCharges, code: user.num_cliente };
};


/**
 * @returns {Promise<RemoteData<User>>}
 */
export const fetchSelf = () => {
  return requestRemoteData(GET_SELF_ENDPOINT)
                  .then((it) => mapRemoteData(it, parseUser));
};

/**
 * @returns {Promise<RemoteData<UserDetails>>}
 */
export const getSelfDetails = () => {
  return requestRemoteData(GET_SELF_DETAILS_ENDPOINT);
};

/**
 * @param {string} password
 * @param {string} oldPassword
 * @returns {Promise<RemoteData<void>>}
 */
export const resetPassword = (password, oldPassword) => {
  return requestRemoteData(RESET_PASSWORD_ENDPOINT, {
    method: 'PUT',
    body: { password, oldPassword }
  });
};

export const signup = (userInfo, tenantId) => {
  return requestRemoteData('signup', {
    method: 'POST',
    headers: { 'Tenant-Id': tenantId },
    body: userInfo,
    useJwt: false
  });
};

export const onboard = (userInfo, tenantId) => {
  return requestRemoteData('signup/existent', {
    method: 'POST',
    headers: { 'Tenant-Id': tenantId },
    body: userInfo,
    useJwt: false
  });
}