import {OrderRecapUI} from './OrderRecapUI';
import {useUserContext} from '../../../user/infrastructure/UserContext';
import {EquivalenceCharges} from '../../../user/domain/User.model';
import {useCartContext} from '../../../cart';

export const OrderRecap = () => {
  const { cart, loading } = useCartContext();
  const { equivalenceCharges } = useUserContext();

  return (
    loading ? (
      <div className="text-center m-4">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    ) : (
      !!cart && (
        <OrderRecapUI cart={cart}
                      hasSurcharge={[EquivalenceCharges.YES, EquivalenceCharges.BY_CHOICE].includes(equivalenceCharges)}
                      deliveryCostThreshold={120}
        />
      )
    )
  );
};