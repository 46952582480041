import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Indicador, ParrafoIndicador} from '../../ui';
import {ContainerArticulos} from '../../components/Theme/components/cardArticulo.elements';
import {AddToFavoritesButton} from './AddToFavoritesButton';
import {getFavorites} from '../infrastructure/productApi';

// TODO: this is a catalog of favorite products. Move it to the catalog domain
// TODO: the product, in the other hand should remain at the product domain
function FavoriteProducts() {
  const [isHovered, setIsHovered] = useState({});
  const [favProducts, setFavProducts] = useState([]);
  const navigate = useNavigate();

  const getfavProduct = async () => {
    setFavProducts(await getFavorites());
  };

  useEffect(() => {
    getfavProduct()
    .catch(error => console.log(error));
  }, []);

  return (
    <div>
      <Indicador indicador={'Mis productos favoritos'} />
      <ParrafoIndicador parrafo={'Administra los productos de tu preferencia.'} />
      <ContainerArticulos>
        {favProducts.map((product, index) => (
            <div className="card border-0 shadow-sm"
                 key={index}
                 style={{ width: '270px', height: '290px', cursor: 'pointer' }}
                 onClick={() => navigate(`/articulos/${encodeURIComponent(product.codigo_articulo)}`)}
                 onMouseEnter={() => setIsHovered(product.id)}
                 onMouseLeave={() => setIsHovered(null)}
            >
              <div className="card-img-top">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="position-absolute">
                    <AddToFavoritesButton data={{
                      productCode: product.codigo_articulo,
                      show: isHovered === product.id,
                      isFavorite: true
                    }}
                      actions={{
                      afterChangeFavorite: getfavProduct
                    }} />
                  </div>
                  <img src={product.imagen_principal}
                       className="rounded-2"
                       style={{ maxHeight: '180px' }}
                       alt="Imagen del producto"
                  />
                </div>
              </div>
              <div className="card-body">
                <div className="card-title">
                  <span>{product.nombre_articulo}</span>
                </div>
              </div>
            </div>
          )
        )}
      </ContainerArticulos>
    </div>
  )
    ;
}

export {FavoriteProducts};