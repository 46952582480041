import React from 'react';
import styled from 'styled-components';
import {truncate} from '../../helpers/truncate';

const TruncateWrapper = styled.div`
  display: inline-block;
  position: relative;
  cursor: pointer;
  white-space: normal;
  word-wrap: break-word; 

  &:hover .mytooltip {
    border-color: red;
    visibility: visible;
    opacity: 1;
  }
`;

const Tooltip = styled.div`
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1;
  transition: opacity 0.3s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
  }
`;

const TextContainer = styled.div`
  display: inline;
  word-wrap: break-word;
`;

const TruncateTooltipUI = ({ text, maxLength }) => {
  const truncatedText = truncate(text, maxLength);

  return (
    <TruncateWrapper>
      <TextContainer>
        {truncatedText}
      </TextContainer>
      {truncatedText !== text && (
        <Tooltip className="mytooltip">{text}</Tooltip>
      )}
    </TruncateWrapper>
  );
};


export default TruncateTooltipUI;
