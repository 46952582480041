export const VetPrescriptionFilterUI = ({ data, actions }) => {
  const { filters, disabled } = data;
  const { changeSelection } = actions;
  return (
    <select disabled={disabled} onChange={(e) => changeSelection({ ...filters, vetPrescription: e.target.value || null })}
            className="form-select taxonomy-categories">
      <option value="">Prescripción veterinaria</option>
      <option value={true} defaultValue={filters?.vetPrescription}>
        Sólo con prescripción veterinaria
      </option>
      <option value={false} defaultValue={filters?.vetPrescription}>
        Sin prescripción veterinaria
      </option>
      <option value="">
        Indiferente
      </option>

    </select>
  );
};