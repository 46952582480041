import styled, {createGlobalStyle} from "styled-components";

export const Navsearch = styled.form`
  max-width: 65rem;
  max-height: 3rem;
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #E7E7E7;
  border-radius: .5rem;
  overflow: hidden;

  .searchIcon {
    position: absolute;
    right: 1rem;
    cursor: pointer;
  }
  
  @media (max-width: 1024px) {
    width: 100%;
  }
`

export const NavInputSearch = styled.input`
  width: 100%;
  height: 3rem;
  border: none;
  padding: 0 2rem 0 1rem;

  ::placeholder {
    color: #9C9C9C;
  }

  :focus, :hover {
    outline: none;
  }

  @media (max-width: 768px) {
    margin-left: 0;
  }
`

export const Li = styled.li`
  padding: 0;
  list-style: none;
  ul{
    padding: 0;
  }
  a{
    text-decoration: none;
    color: #787878;
  }
`

export const A = styled.a`
  text-decoration: none;
  color: var(--color-fuente);
  :hover {
    color: ${(props) => props.background};
  }

`

export const CartContenido = styled.div`
  text-align: left;

  .vertAlign {
    vertical-align: middle;
  }

  .title {
    margin: .5rem;
  }
  .unidades{
    text-align: center;
  }
`
export const BtnCloseCarrito = styled.button`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  position: absolute;
  right: .5rem;
  top: 1rem;
  border: none;
  color: white;
  background-color: var(--bs-primary);
  opacity: 0.5;

  svg {
    width: 1rem;
  }
`

export const HasNotification = styled.div`
  width: .75rem;
  height: .75rem;
  position: absolute;
  right: .75rem;
  bottom: .75rem;
  border-radius: 50%;
  background-color: red;
  @media (max-width: 768px) {
    right: 32px;
    bottom: 28px;
  }
`

export const ImgDrop = styled.img`
  width: 5%;
`
export const TextCartInfoDrop = styled.h1`
  font-size: 18px;
  color: #D1D1D1;
`

export const ImgPro = styled.img`
  width: 3.5rem;
  object-fit: contain;
  margin-right: 5px;
  
  @media (max-width: 768px) {
    overflow-x: scroll;
    display: none;
  }
`

export const ContainerProductsCar = styled.div`
  .precios {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: .5rem 0;

    & > div:not(:first-child) {
      border-left: 1px solid #D1D1D1;
    }
  }

`

export const TableProducts = styled.div`
  border-top: var(--border);
  overflow-x: auto;
`
