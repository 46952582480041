import {RoutePathBreadcrumb} from '../../ui';
import React, {useEffect, useMemo, useState} from 'react';
import {useManufacturerContext} from '../context/ManufacturerContext';

const useManufacturerBreadcrumb = () => {
  const { details } = useManufacturerContext();
  const [breadcrumb, setBreadcrumb] = useState(null);

  useEffect(() => {
    if (details) {
      const { nombre, id } = details;
      setBreadcrumb({
        [id]: { name: nombre || id, path: `/laboratorios/${id}` }
      });
    }
  }, [details]);

  return useMemo(() => ({ breadcrumb }), [breadcrumb]); };

export const ManufacturerBreadcrumb = () => {
  const { breadcrumb } = useManufacturerBreadcrumb();

  return (
    <>
      {breadcrumb && <RoutePathBreadcrumb customBones={breadcrumb} />}
    </>
  );
};
