import {useEffect, useState} from 'react';
import {initial, isSuccess} from '../../common/domain/RemoteData.model';
import {onboard as _onboard} from '../infrastructure/UserApi';
import {remoteDataWithSetter} from '../../common/utils/remoteDataWithSetter';
import {useNavigate} from 'react-router-dom';

export const useOnboard = () => {
  const [onboardRemoteData, setOnboardRemoteData] = useState(initial(null));
  const navigate = useNavigate();

  const onboard = async (userInfo, tenantId) => {
    const dataSetterCallback = () => _onboard(userInfo, tenantId);
    await remoteDataWithSetter(setOnboardRemoteData, dataSetterCallback);
  }

  useEffect(() => {
    if (isSuccess(onboardRemoteData)) {
      setTimeout(() => { navigate('/iniciar-sesion'); }, 3000);
    }
  }, [onboardRemoteData]);

  return { onboardRemoteData, onboard };
}
