import {requestRemoteData} from '../../API/api';

const FILTER_DATA_ENDPOINT = 'product/pagination';

export const fetchFilterData = () => {
  return requestRemoteData(
    FILTER_DATA_ENDPOINT,
    {
      method: 'GET'
    }
  );
};
