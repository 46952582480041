import React from "react";
import styled from 'styled-components';

const H1Indicador = styled.h1`
  font-size: 20px;
  color: var(--bs-primary);
  @media (max-width: 992px) {
    margin: 1.5rem 0;
  }
`

function Indicador(props) {
    return(
        <React.Fragment>
            <H1Indicador>{props.indicador}</H1Indicador>
        </React.Fragment>
    );
}

export {Indicador};