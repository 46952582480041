import { Notification } from '../models/Notification';
import ApiClient from '../../API/api';

const GET_NOTIFICATIONS_ENDPOINT = 'notificationsapi';
const READ_STATUS_ENDPOINT = 'notificationsapi/marcar';

export class NotificationService {
  static getAll(): Promise<Notification[]> {
    return ApiClient.performRequest(GET_NOTIFICATIONS_ENDPOINT);
  }

  static changeReadStatus(notificationId, readState): Promise<void> {
    return ApiClient.performRequest(
      READ_STATUS_ENDPOINT,
      {
        method: 'GET',
        queryParams: { id_cod: notificationId, estado: readState }}
    );
  }
}