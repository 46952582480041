/**
 * @typedef {Object} Address
 * @property {number} id
 * @property {string} name
 * @property {string} mainInfo
 * @property {string} complementInfo
 * @property {string} location
 * @property {string} zip
 * @property {string} country
 * @property {string} phone
 * @property {boolean} defaultDeliveryAddress
 * @property {boolean} invoicingAddress
 */

/**
 * Only one address could be set as default delivery address
 * @param id - Address id to set as default delivery address
 * @param {Array<Address>} addresses - list of address to update the delivery address
 * @returns {Array<Address>} - updated delivery address
 */
export const updateDefaultDeliveryAddress = (id, addresses) =>
  addresses.map(address => ({ ...address, defaultDeliveryAddress: address.id === id }));
