import styled from "styled-components";

export const ImgLabs = styled.img`
  height: 8rem;
`
export const ImgLabsNoPng = styled.img`
  height: 8rem;
`

export const ContenedorCarruselesLabs = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 209px;
  margin-bottom: 3rem;
`