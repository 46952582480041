import {Indicador} from '../../ui';
import {Address} from './Address';

export const AddressOverview = ({ data, actions }) => {
  const { billingAddress, deliveryAddresses, loading} = data;
  const { markAsDefault } = actions;
  return (
    <>
      {
        loading ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="row gap-2 gap-md-0">
            <div className="col-md-6">
              <Indicador indicador={'Direcciones de entrega'} />
              {
                deliveryAddresses.length === 0 ? (
                  <div className="alert alert-warning" role="alert">
                    No se encontró ninguna dirección de entrega. Contacte con atención al cliente.
                  </div>
                ) : (
                  <>
                    {
                      deliveryAddresses.map(address => (
                        <div className="my-2" key={address.id}>
                          <Address data={{ address: address }} actions={{ markAsDefault: () => markAsDefault(address) }} />
                        </div>
                      ))
                    }
                  </>
                )
              }
            </div>
            <div className="col-md-6">
              <Indicador indicador={'Dirección de facturación'} />
              {
                !billingAddress ? (
                  <div className="alert alert-warning" role="alert">
                    No se encontró ninguna dirección de facturación. Contacte con atención al cliente.
                  </div>
                ) : (
                  <Address data={{ address: billingAddress }} actions={{ markAsDefault: () => markAsDefault(billingAddress) }} />
                )
              }
            </div>
          </div>
        )
      }
    </>
  );
};