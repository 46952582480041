import ApiClient from '../../API/api';
import type {Invoice} from '../models/Invoice';

const GET_INVOICES = 'invoice';
const GET_INVOICE = 'invoice/:id'

export class InvoiceService {
  static getInvoices = (): Promise<Invoice[]> => {
    return ApiClient.performRequest(GET_INVOICES);
  }

  static getPDFInvoice = (id): Promise<Blob> => {
    return ApiClient.performRequest(
      GET_INVOICE,
      {
        pathParams: { id },
        headers: { 'accept': 'application/pdf', 'Content-Type': 'application/pdf' }
      });
  }
}

