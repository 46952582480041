import styled from "styled-components";

export const ContainerInfo = styled.div`
  @media (max-width: 575px) {
    display: flex;
    flex-direction: column;
    div:first-child{
      order: 3;
    }
  }
`
export const ContainerBtns = styled.div`
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
`
export const ARegistro = styled.a`
  width: 100%;
`

export const ContainerTitle = styled.div`
  display: flex;
  padding: 3rem 0;
  width: 90%;
  text-transform: uppercase;
  margin: auto;
  @media (max-width: 991px) {
    width: 90%;
  };
`

export const SubTitleLearning = styled.h1`
  color: ${(props) => props.color};
  font-size: 18px;
  margin: .25rem .5rem;

  @media (max-width: 991px) {
    font-size: 18px;
  };
  @media (max-width: 598px) {
    font-size: 18px;
  };
  @media (max-width: 398px) {
    font-size: 18px;
  };
`

export const HorizontalLineSubTitle = styled.div`
  width: 60%;
  padding-left: 58px;
  margin-left: -30px;
  border-bottom: .5px solid ${(props) => props.background};
  position: relative;
  @media (max-width: 598px) {
    width: auto;
    margin-left: -20px;
  };
  @media (max-width: 991px) {
    width: auto;
    margin-left: -20px;
  };
`
export const Organizador = styled.h2`
  font-size: 14px;
  margin: .5rem;
`


export const VerticalLineSubTitle = styled.div`
  height: 70px;
  border-left: .5px solid ${(props) => props.background};
  position: absolute;
  @media (max-width: 598px) {
    height: 75px;
  };
  @media (max-width: 991px) {
    height: 75px;
  };
`

export const BtnRegistroDos = styled.button`
  background: ${(props) => props.color};
  font-size: 14px;
  font-weight: 500;
  color: var(--white);
  border: 1.7px solid ${(props) => props.color};
  border-radius: 7px;
  margin-top: 1rem;
  padding: 2% 0 2% 0;
  width: 100%;
  height: 3rem;
  :hover {
    background: ${(props) => props.background};
    color: var(--white);
  }
  @media (max-width: 991px) {
    width: 90%;
    margin: 1rem;
  };
`

export const ImgFormacion = styled.img`
  width: 432px;
  height: auto;
  float: right;
  padding: 0 5px 0 20px;
  @media (max-width: 575px) {
    margin-top: 5.5rem;
    padding: 0;
  }
`

export const LiInfoFormador = styled.li`
  list-style: none;
  font-size: 14px;
`

export const TextoFormacion = styled.div`
  width: 100%;
  font-size: 13px;
  padding-top: 6rem;
`

// Estilos para la barra de busqueda
export const ContainerBusqueda = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 2rem;
  @media (max-width: 991px) {
    gap: 2rem;
   flex-direction: column;
    padding: 0 1rem;
  }
`

export const Search = styled.div`
  width: 100%;
  position: relative;
  display: flex;

  > span {
    position: absolute;
    top: -22px;
    left: 4px;
    color: #606060;
  }

  @media (max-width: 991px) {
    width: 100%;
  }
`

export const InputSearch = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 3rem;
  overflow: hidden;
  input{
    width: 100%;
    padding-left: 1rem;
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
    border: 1px solid #F1F1F1;
    ::placeholder {
      color: #9C9C9C;
    }
    :focus, :hover {
      outline: none;
    }
  }
`

export const IconBusqueda = styled.button`
  width: 3rem;
  height: 100%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props)=> props.background};
  border-radius: 0 10px 10px 0;
`

// Filters inputs
export const ContainerElements = styled.div`
  width: 100%;
  height: auto;
  @media (max-width: 992px) {
    /*height: 0;
    transition: height 0.5s;
    overflow: hidden;*/
  }
  select{
    width: 100%;
    height: 40px;
    color: #6c757d;
    border: 1px solid #F1F1F1;
    border-radius: 5px;
    option{
      border-radius: 50%;
      color: #6c757d;
    }
  }
`

export const ContainerFilterWord = styled.div`
  margin-top: .3rem;
  a{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const LabelCategoria = styled.label`
  overflow-wrap: break-word;
`

export const InputFilter = styled.input`
  border-radius: 5px;
  border: 1px solid #F1F1F1;
  width: 100%;
  padding: .5rem;
  margin-right: 10px;
  margin-top: 3px;
`
export const ButtonFiltrar = styled.button`
  border-radius: 8px;
  border: 1px solid;
  padding: 8px;
  color: var(--white);
  width: 80%;
  margin: 1rem auto auto auto;
  background: ${(props) => props.background};
`
export const ButtonFiltrarDel = styled.button`
  border-radius: 8px;
  border: 1px solid;
  padding: 8px;
  color: var(--white);
  width: 20%;
  margin: 1rem auto auto auto;
  background: ${(props) => props.background};
`
export const InputCat = styled.input`
  border: 1px solid ${(props) => props.background};
  margin-right: 10px;
`

export const ButtonFilter = styled.button`
  width: 128px;
  height: 2.2rem;
  color: white;
  border: none;
  border-radius: 0.3rem;
  font-size: 12px;
  background: ${(props) => props.background};
  :hover{
    background: ${(props) => props.hover};
  }
`
export const Order = styled.div`
  position: relative;
  padding-left: 2rem;
  >span{
    position: absolute;
    top: -20px;
    color: #606060;
  }
  @media (max-width: 991px) {
    padding: 0;
  }

`
export const AOrdenar = styled.select`
  font-size: 12px;
  color: ${(props) => props.color};
  background: none;
  border: 1px solid ${(props) => props.color};
  border-radius: 5px;
  height: 40px;
  bottom: 0;
  margin: 2px;
  padding: 0 10px;
  :focus{
    outline: none;
  }
  @media (max-width: 575px) {
    padding: 5px;
  }
`

export const NoticiasRecientesImg = styled.img`
  width: 60px;
  height: 50px;
`
export const TituloNoticia = styled.h5`
  font-size: 16px;
  margin-bottom: 0;
  a{
    text-decoration: none;
    color: #444444;
  }
`
export const FechaNoticia = styled.span`
  font-size: 12px;
  margin-bottom: 0;
`

export const InfoFormador = styled.div`
  div {
    text-align: center;
  }
  img{
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    object-fit: cover;
  }
  ul{
    padding: 0;
    margin: 0;
  }
  ul li:not(:last-child){
    padding-bottom: .5rem;
  }
  @media (max-width: 575px) {

  }
`

export const ContainerFormacionesDescripcion = styled.div`
  width: 100%;
  padding-left: 1rem;

  .formaciones__banner {
    height: 283px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

    }
  }

  .container__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      width: 50%;
    }

    .container__button {
      position: relative;
      text-align: end;
    }
  }

  .container__descripcion {
    position: relative;

    .bandera {
      position: absolute;
      width: calc(100% - 432px);
      border-top: 1px solid #D3D3D3;

      div:first-child {
        right: 0;
      }

      p {
        font-size: 1rem;
        font-weight: 400;
        padding: .25rem 0 1rem;
        margin: 0;
      }

      svg {
        width: 2rem;
        height: 2rem;
        margin-right: .5rem;
      }
    }

    @media (max-width: 575px) {
      .bandera{
        width: 100%;
      }
    }
  }

  @media (max-width: 575px) {
    padding: 0;
    img{
      width: 100%;
    }
    .container__title{
      flex-direction: column;
      a{
        display: none;
      }
      >div{
        width: 100%;
      }
      >div > div{
        padding: 2rem 0;
      }
    }
  }
`
export const LinkCompartir = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${(props) => props.color};
  :hover{
    color: ${(props) => props.color};
  }
`

export const Modalidad = styled.div`

  position: ${(props) => (props.inline ? "relative" : "absolute")};
  width: 77px;
  height: 77px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  padding-top: 1rem;
  background-color: ${(props) => props.background};
  right: ${(props) => (props.inline ? "0" : "1rem")};
  bottom: ${(props) => (props.inline ? "0" : "1rem")};

  p {
    font-size: 12px;
    width: 100%;
    text-align: center;
    color: var(--white);
  }
`
export const ButtonRegisterLearning = styled.button`
  background: ${(props) => props.color};
  font-size: 16px;
  font-weight: 500;
  color: var(--white);
  border: 1.7px solid ${(props) => props.color};
  border-radius: 7px;
  width: 14rem;
  height: 3rem;
  :hover {
    background: ${(props) => props.background};
    color: var(--white);
  }
  @media (max-width: 991px) {
    width: 90%;
    margin: 1rem;
  };
`