import React from 'react';
import {useParams} from 'react-router-dom';
import {ManufacturerDetails} from '../details/ManufacterDetails';
import {ManufacturerContextProvider} from '../../context/ManufacturerContext';
import {ManufacturerProducts} from '../products/ManufacturerProducts';
import {ManufacturerBreadcrumb} from '../ManufacturerBreadCrumb';

function ManufacturerPage() {
  const { id } = useParams();

  return (
    <ManufacturerContextProvider>
      <div className="container">
        <div className="row">
          <div className="col-12 my-4">
            <h2>Detalles del fabricante</h2>
            <ManufacturerBreadcrumb />
          </div>

          <div className="col-12 my-4">
            <ManufacturerDetails id={Number(id)} />
          </div>

          <div className="col-12">
            <ManufacturerProducts />
          </div>
        </div>
      </div>
    </ManufacturerContextProvider>
  );
}

export {ManufacturerPage};