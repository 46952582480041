import {truncate} from '../../helpers/truncate';

interface Props {
  data: {
    description: string,
    maxLength: number,
    suffix: string,
  };
  actions: {
    handleShowMore: () => void,
  };
}

export const ProductDescriptionUI = ({ data, actions }: Props) => {
  const { description, maxLength, suffix } = data;
  const { handleShowMore } = actions;

  return (
    <div>
      <span>
        <div dangerouslySetInnerHTML={{__html: truncate(description, maxLength, suffix)}}/>
      </span>
      {
          description.length > maxLength &&
          <a className="ms-2" href="#" onClick={handleShowMore}>
          ver más
        </a>
      }
    </div>
  );
};
