import {Modal} from 'react-bootstrap';
import {NotificationDetailUI} from './notification-detail/NotificationDetailUI';

interface Props {
  data: {
    notification: any; // TODO: create interface
    show: any;
  };
  actions: {
    handleClose: () => void,
  }
}

export const NotificationDetailsModalUI = ({ data, actions }: Props) => {
  const { notification, show } = data;
  const { handleClose } = actions;

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Detalles de la noficiacion
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <NotificationDetailUI notification={notification} />
      </Modal.Body>
    </Modal>
  )
}