import React from 'react';
import {ContainerTitle, HorizontalLineSubTitle, Organizador, SubTitleLearning, VerticalLineSubTitle} from '../../../ui';
import {useTenantContext} from '../../../tenant/TenantContext';


function SubTitleFormaciones(props) {
    const {
        secondaryColor,
    } = useTenantContext();
    return (
        <React.Fragment>
            <ContainerTitle>
                <div className="col-xl-12">
                    <VerticalLineSubTitle background={secondaryColor}/>
                    <div>
                        <SubTitleLearning color={secondaryColor}>{props.title}</SubTitleLearning>
                    </div>
                    <HorizontalLineSubTitle background={secondaryColor}/>
                    <Organizador>Organizador: {props.organizador}</Organizador>
                </div>
            </ContainerTitle>
        </React.Fragment>
    );
}

export {SubTitleFormaciones};