import {useEffect, useState} from 'react';
import {fetchAddresses, setDefaultDeliveryAddress} from '../infrastructure/AddressApi';
import {updateDefaultDeliveryAddress} from '../domain/Address.model';

export const useAddress = () => {
  const [billingAddress, setBillingAddress] = useState(null);
  const [deliveryAddresses, setDeliveryAddresses] = useState([]);

  const [loading, setLoading] = useState(true);

  const parseAddresses = addresses => {
    setBillingAddress(addresses.find(address => address.invoicingAddress === true));
    setDeliveryAddresses(addresses.filter(address => address.invoicingAddress === false) ?? []);
  };

  const changeDefaultDeliveryAddress = (addressId) => {
    setDeliveryAddresses(prevState => updateDefaultDeliveryAddress(addressId, deliveryAddresses));
  };

  const markAsDefault = async (address) => {
    await setDefaultDeliveryAddress(address.id);
    changeDefaultDeliveryAddress(address.id);
  };

  useEffect(() => {
    fetchAddresses().then(addresses => {
      parseAddresses(addresses);
      setLoading(false);
    });
  }, []);

  return ({ billingAddress, deliveryAddresses, loading, markAsDefault });
};