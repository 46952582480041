import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
`;

const DropdownTitle = styled.span`
  font-size: 16px;
  margin-right: 10px;
`;

const DropdownMenu = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  border-radius: 5px;
  padding: 10px;
  left: ${(props) => props.position}px;
  top: -10px;
`;

const DropdownItem = styled.div`
  display: block;
  padding: 8px 0;
  color: black;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    background-color: #f1f1f1;
  }
`;

const OVERFLOW_PX_THRESHOLD_DEFAULT = 300;

export const useDropdown = (containerRef, delay, overflowThreshold = OVERFLOW_PX_THRESHOLD_DEFAULT) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState(0);
  const [openToLeft, setOpenToLeft] = useState(false);
  const closeTimeoutRef = useRef(null);

  const calculateDropdownPosition = (parentRect, windowWidth) => {
    if (parentRect.right + overflowThreshold > windowWidth) {
      setOpenToLeft(true);
      setDropdownPosition(-parentRect.width); // Open to the left
    } else {
      setOpenToLeft(false);
      setDropdownPosition(parentRect.width); // Open to the right
    }
  };

  const handleMouseEnter = () => {
    clearTimeout(closeTimeoutRef.current);

    if (containerRef.current && containerRef.current.parentElement) {
      const parentRect = containerRef.current.parentElement.getBoundingClientRect();
      const windowWidth = window.innerWidth;

      calculateDropdownPosition(parentRect, windowWidth);
    }

    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    closeTimeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, delay);
  };

  useEffect(() => {
    return () => {
      clearTimeout(closeTimeoutRef.current);
    };
  }, [delay]);

  return {
    isOpen,
    dropdownPosition,
    openToLeft,
    handleMouseEnter,
    handleMouseLeave,
  };
};



export const FloatingDropdown = ({ children, delay = 300 }) => {
  const containerRef = useRef(null);
  const { isOpen, dropdownPosition, handleMouseEnter, handleMouseLeave } = useDropdown(containerRef, delay);

  const allChildren = React.Children.toArray(children);
  let titleComponent = allChildren.find(({ type }) => type === FloatingDropdown.Title);
  let itemsComponent = allChildren.find(({ type }) => type === FloatingDropdown.Items);

  const hasItems = itemsComponent && React.Children.count(itemsComponent.props.children) > 0;

  return (
    <DropdownContainer
      ref={containerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <DropdownHeader>
        {titleComponent}
      </DropdownHeader>
      {hasItems && (
        <DropdownMenu show={isOpen} position={dropdownPosition}>
          {itemsComponent}
        </DropdownMenu>
      )}
    </DropdownContainer>
  );
};

FloatingDropdown.Title = ({ children }) => <DropdownTitle>{children}</DropdownTitle>;

FloatingDropdown.Items = ({ children }) => (
  <>
    {React.Children.map(children, (child) => (
      <DropdownItem>{child}</DropdownItem>
    ))}
  </>
);
