import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';

export const Address = ({ data, actions }) => {
  const { address } = data;
  const { markAsDefault } = actions;
  return (
    <div className="card">
      <div className="card-body">
        <div className="card-title">
          <strong>{address.name}</strong>
        </div>
        <div className="d-grid gap-1">
          <div className="d-flex flex-row gap-2">
            <LocationOnIcon />
            <span>{address.mainInfo} {address.zip} {address.location} {address.country}</span>
          </div>
          {address.complementInfo ? <span className="text-muted">{address.complementInfo}</span> : <></>}
          <div className="d-flex flex-row gap-2">
            <CallIcon />
            {address.phone ? <span>{address.phone}</span> : <span>N/A</span>}
          </div>
        </div>
        {
          address.invoicingAddress || (
            <div className="mt-3 d-flex flex-row gap-2">
              {
                address.defaultDeliveryAddress ? (
                  <strong>Dirección de entrega predeterminada</strong>
                ) : (
                  <button type="button" className="btn btn-primary" onClick={markAsDefault}>
                    Marcar como predetermidado
                  </button>
                )
              }
            </div>
          )
        }
      </div>
    </div>
  );
};