import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import {BtnCloseCarrito, ImgPro} from '../../ui/components/navbar/NavbarStyles.elements';
import {ContainerNuevo, TableHistoriar} from '../../user/ui/cuenta.elements';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import {Contenido, EncabezadoModal, ModalContent, Modales} from '../../ui';

// DEPRECATED - TODO move to order domain
export const ProductsModal = ({ title, products, onModalClose }) => {
  return (
    <>
      <Modales>
        <ModalContent>
          <EncabezadoModal>
            <span className="text-primary">{title}</span>
          </EncabezadoModal>
          <BtnCloseCarrito onClick={() => onModalClose()}><CloseIcon /></BtnCloseCarrito>
          <Contenido>
            <ContainerNuevo className="col-md-12">
              <TableHistoriar className="table">
                <thead>
                <tr>
                  <th>Imagen</th>
                  <th>Producto</th>
                  <th>Código</th>
                  <th>Unidades</th>
                  <th>Precio compra</th>
                </tr>
                </thead>
                <tbody>
                {
                  products.map((product, index) => (
                    <tr key={index}>
                      <td>
                        {
                          !!product.packParentId ? (
                            <CardGiftcardIcon className="text-primary" fontSize="large" />
                          ) : (
                            <ImgPro src={product.image} alt="ImgProduct" />
                          )
                        }
                      </td>
                      <td>{product.name}</td>
                      <td>{product.code}</td>
                      <td>{product.quantity}</td>
                      <td>{product.sellingPrice.toFixed(2)}€</td>
                    </tr>
                  ))
                }
                </tbody>
              </TableHistoriar>
            </ContainerNuevo>
          </Contenido>
        </ModalContent>
      </Modales>
    </>
  );
};

