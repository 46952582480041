import {Link, useLocation} from 'react-router-dom';
import {Breadcrumbone} from '../../../components/Theme/theme.elements';
import {useMemo} from 'react';

const defaultBreadcrumbBones = {
  home: { name: 'Inicio', path: '/' },
  checkout: { name: 'Carrito', path: '/checkout' },
  cuenta: { name: 'Cuenta', path: '/cuenta' },
  transacciones: { name: 'Detalles transaccionales', path: '/cuenta/transacciones' },
  usuario: { name: 'Datos de usuario', path: '/cuenta/usuario' },
  direcciones: { name: 'Direcciones', path: '/cuenta/direcciones' },
  Pedidos: { name: 'Pedidos', path: '/cuenta/Pedidos' },
  Facturas: { name: 'Facturas', path: '/cuenta/Facturas' },
  Favoritos: { name: 'Favoritos', path: '/cuenta/Favoritos' },
  Alertas: { name: 'Alertas', path: '/cuenta/Alertas' },
  articulos: { name: 'Articulos', path: '/articulos' },
  laboratorios: { name: 'Fabricantes', path: '/laboratorios' },
};

const buildBreadcrumbs = (rootBone, path) => {
  return [
    rootBone,
    ...path.split('/').filter(it => it).map(it => decodeURIComponent(it))
  ];
}

export function RoutePathBreadcrumb({ customBones }) {
  const breadcrumbs = buildBreadcrumbs(
    'home',
    decodeURI(useLocation().pathname)
  );

  const breadcrumbBones = useMemo(() =>
    ({
      ...defaultBreadcrumbBones,
      ...customBones
    })
  , [customBones]);

  return (<nav aria-label="breadcrumb">
    <ol className="breadcrumb">
      {
        breadcrumbs.map((breadcrumb, index) => (
            <li
              key={index}
              className={`breadcrumb-item ${index === breadcrumbs.length - 1 ? 'active' : ''}`}
              aria-current={index === breadcrumbs.length - 1 ? 'page' : undefined}>
              {
                index === breadcrumbs.length - 1 ? (
                  breadcrumbBones[breadcrumb]?.name
                ) : (
                  <Breadcrumbone as={Link} to={breadcrumbBones[breadcrumb]?.path}>
                    {breadcrumbBones[breadcrumb]?.name}
                  </Breadcrumbone>
                )
              }
            </li>
          )
        )
      }
    </ol>
  </nav>);
}
