import {NameProduct} from '../../ui';
import React, {useState} from 'react';
import {ProductPriceUI} from './ProductPriceUI';
import {AddToFavoritesButton} from './AddToFavoritesButton';
import {AddToCartProxy} from './AddToCartProxy';
import {NumberInputUI} from '../../ui/components/NumberInputUI';
import {AvailabilityUI} from '../../common/ui/AvailabilityUI';
import {useEnhancedItem} from '../../catalog/application/useEnhancedItem';
import {TagListUI} from '../../common/ui/TagListUI';
import {useNavigate} from 'react-router-dom';

export const ProductFlatPurchaseCardUI = ({ data }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { details } = data;
  const [quantity, setQuantity] = useState(1);
  const enhancedItem = useEnhancedItem(details, quantity);
  const navigate = useNavigate();

  return (
    <>
      <div className='rounded-2 p-1 shadow-sm'
           onMouseEnter={() => setIsHovered(true)}
           onMouseLeave={() => setIsHovered(false)}
      >
        <div className="row justify-content-start align-items-center">
          <div className="col-12 col-md-2 col-xl-auto">
            <div className="d-flex justify-content-center align-items-center">
              <div className="position-absolute">
                <AddToFavoritesButton data={{
                  productCode: enhancedItem.pro_codigo,
                  show: isHovered,
                  isFavorite: enhancedItem.pro_favorito
                }} />
              </div>
              <img src={enhancedItem.pro_imagen}
                   onClick={() => navigate(`/articulos/${encodeURIComponent(enhancedItem.pro_codigo)}`)}
                   style={{ height: '65px', width: '65px' }}
                   className="rounded"
                   alt="Imagen del producto"
              />
            </div>
          </div>

          <div className="col-12 col-md-8 col-xl">
            <div className="row gap-2 gap-lg-0">
              <div className="col-12 col-lg-8 col-xl-8">
                <div className="d-flex flex-row justify-content-between"
                     style={{ cursor: 'pointer' }}
                     onClick={() => navigate(`/articulos/${encodeURIComponent(enhancedItem.pro_codigo)}`)}>
                  <div className="d-flex flex-column">
                    <TagListUI data={{ tags: enhancedItem.tags }} />
                    <NameProduct>
                      {enhancedItem.pro_nombre}
                      <small className="text-muted ms-1">Ref: {enhancedItem.pro_codigo}</small>
                    </NameProduct>
                    <AvailabilityUI data={{ availability: enhancedItem.availability }} />
                  </div>

                  <ProductPriceUI price={{
                    sellingPrice: details.priceInformation?.bestPrice,
                    basePrice: details.priceInformation?.originalPrice
                  }} />
                </div>
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <div className="d-flex flex-row gap-2 justify-content-center">
                  <div className="">
                    <NumberInputUI data={{ value: quantity }} actions={{ setValue: setQuantity }} />
                  </div>

                  <div className="">
                    <AddToCartProxy
                      data={{
                        details: enhancedItem,
                        quantity: quantity,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};