import {buildDealTag, buildInfoTag} from '../../common/domain/Tag.model.ts';
import {mapRemoteData} from '../../common/domain/RemoteData.model';
import {fetchCatalogItemPrices} from '../infrastructure/CatalogApi';

export const generateItemTags = (catalogItem) => {
  const tags = [];
  if (catalogItem?.pro_nuevo) {
    tags.push(buildInfoTag('Nuevo'));
  }
  if (catalogItem?.pro_destacado) {
    tags.push(buildInfoTag('Destacado'));
  }
  if (catalogItem?.pro_mas_vendido) {
    tags.push(buildInfoTag('Más vendido'));
  }
  if (catalogItem?.priceInformation?.offerAvailable) {
    tags.push(buildDealTag('Ofertas'));
  }
  return tags;
}

// TODO: createa js docs
export const findItemAvailability = (
  catalogItem,
  requiredQuantity?
) => {
  if (!catalogItem) {
    return null;
  }
  if (shouldShowLastUnitsMessage(catalogItem)) {
    return createAvailability(true, getLastUnitsMessage(catalogItem));
  }
  if (isOutOfStock(catalogItem)) {
    return createAvailability(false, getOutOfStockMessage(catalogItem));
  }
  if (shouldCheckStock(catalogItem, requiredQuantity)) {
    return createAvailability(true, 'Producto disponible');
  }
  if (isInsufficientStock(catalogItem, requiredQuantity)) {
    return createAvailability(false, getInsufficientStockMessage(catalogItem));
  }
  return createAvailability(true, `${catalogItem.pro_unidades_disponibles} unidades disponibles`);
};

const shouldShowLastUnitsMessage = (catalogItem) => {
  return catalogItem.mostrar_mensaje_ultimas_unidades;
};

const getLastUnitsMessage = (catalogItem) => {
  return catalogItem.texto_mensaje_ultimas_unidades || 'Últimas unidades disponibles';
};

const isOutOfStock = (catalogItem) => {
  return catalogItem.pro_unidades_disponibles <= 0;
};

const getOutOfStockMessage = (catalogItem) => {
  return catalogItem.stockText || 'Disponible próximamente';
};

const shouldCheckStock = (catalogItem, requiredQuantity) => {
  return catalogItem.showStock === false && (!requiredQuantity || requiredQuantity <= catalogItem.pro_unidades_disponibles);
};

const isInsufficientStock = (catalogItem, requiredQuantity) => {
  return !!requiredQuantity && requiredQuantity > catalogItem.pro_unidades_disponibles;
};

const getInsufficientStockMessage = (catalogItem) => {
  return catalogItem.stockText || 'Stock insuficiente';
};

const createAvailability = (isAvailable, msg) => {
  return { isAvailable, msg };
};

const convertToMap = (remoteData) => {
  return mapRemoteData(
    remoteData,
    it => it?.reduce((acc, { code, basePrice, sellingPrice }) => {
      acc[code] = { basePrice, sellingPrice };
      return acc;
    }, {})
  );
};

/**
 * @param {Array<CatalogItem>} catalogItems
 * @returns {Promise<*>}
 */
export const getCatalogItemPrices = (catalogItems) => {
  const codes = catalogItems.map(it => it.pro_codigo);
  return fetchCatalogItemPrices(codes).then(convertToMap);
};

