import React, {useEffect, useMemo, useState} from 'react';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import {HasNotification} from '../../ui/components/navbar/NavbarStyles.elements';
import {PendingNotificationsSidebar} from '../../notification';
import {useNotificationContext} from '../contexts/NotificationContext';

export const PendingNotificationPreviewButton = () => {
  const { pendingNotifications, loadNotifications } = useNotificationContext();
  const [drawerNotification, setDrawerNotification] = useState(false);

  const hasPendingNotifications = useMemo(
    () => pendingNotifications.length > 0,
    [pendingNotifications]
  );

  useEffect(() => {
    loadNotifications();
  }, []);

  return (
    <div>
      <button className="btn p-0" onClick={() => setDrawerNotification(true)}>
        <NotificationsActiveOutlinedIcon fontSize="large" className="text-primary" />
        { hasPendingNotifications && <HasNotification /> }
      </button>
      <PendingNotificationsSidebar open={drawerNotification} close={() => setDrawerNotification(false)} />
    </div>
  );
};