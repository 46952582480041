import {DealType} from '../domain/DealSelection.model';

export const ExclusiveDealsSelectorUI = ({
  exclusiveDeals = [],
  onDealSelection,
  selection
}) => {
 return (
   <div>
     {
       exclusiveDeals?.length > 0 && (
         <div>
           <strong className="m-0 text-primary">Ofertas exclusivas</strong>
           <div className="table-responsive">
             <table className="table table-borderless">
               <thead>
               <tr>
                 <th>{/*Checkbox*/}</th>
                 <th>Cantidad minima</th>
                 <th>Descuento</th>
                 <th>Precio neto</th>
               </tr>
               </thead>
               <tbody className="fw-normal">
               {
                 exclusiveDeals.map((deal, index) => (
                   <tr key={index}>
                     <td>
                       <input type="radio"
                              name="exlusiveDeal"
                              checked={selection?.value === deal}
                              onChange={() => onDealSelection({ type: DealType.OFFER, value: deal })}
                       />
                     </td>
                     <td>{deal.cantidad} Uds</td>
                     <td>{deal.descuento}</td>
                     <td>{deal.price.toFixed(2)}€</td>
                   </tr>
                 ))
               }
               </tbody>
             </table>
           </div>
         </div>
       )
     }
   </div>
 );
};