import {createContext, useContext, useEffect, useState} from 'react';
import {fetchSelf} from './UserApi';
import {isSuccess} from '../../common/domain/RemoteData.model';

const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);

export const UserContextProvider = ({children}) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    const fetch = async () => {
      const remoteData = await fetchSelf();
      if (isSuccess(remoteData)) {
        setUser(remoteData.value);
      }
    }
    fetch().then();
  }, []);

  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  );
};