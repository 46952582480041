import ApiClient from '../../API/api';

const GET_ADDRESSES_ENDPOINT = 'account/address';
const SET_DEFAULT_DELIVERY_ADDRESS_ENDPOINT = 'account/address/:addressId/delivery'

/**
 *
 * @returns {Promise<Array<Address>>}
 */
export const fetchAddresses = () => {
  return ApiClient.performRequest(GET_ADDRESSES_ENDPOINT);
}

/**
 * @param addressId
 * @returns {Promise<*>}
 */
export const setDefaultDeliveryAddress = (addressId): Promise<void> => {
  return ApiClient.performRequest(SET_DEFAULT_DELIVERY_ADDRESS_ENDPOINT, {
    method: 'PUT',
    pathParams: { addressId }
  });
};
