import React from 'react';
import {HorizontalLine, TitleContainer, TitleH1, TitleSpam, VerticalLine} from './theme.elements';

/**
 * Componente que representa un conjunto de títulos con líneas decorativas.
 * @param {Object} props - Propiedades del componente.
 * @param {string} props.textuno - Texto principal del título.
 * @param {string} props.textdos - Texto secundario del título.
 * @returns {JSX.Element} - Elemento JSX que representa el componente Titles.
 */
function Titles(props) {
    return(
        <React.Fragment>
                <TitleContainer>
                    <div>
                        <VerticalLine />
                        <div className="container-fluid ">
                            <TitleH1>{props.textuno}<br/>
                                <TitleSpam>{props.textdos}</TitleSpam></TitleH1>
                        </div>
                        <HorizontalLine />
                    </div>
                </TitleContainer>
        </React.Fragment>
    );
}

export {Titles};