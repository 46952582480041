import {useEffect, useState} from 'react';
import {fetchRelatedProducts} from '../infrastructure/CatalogApi';
import {initial, mapRemoteData} from '../../common/domain/RemoteData.model';
import {remoteDataWithSetter} from '../../common/utils/remoteDataWithSetter';

/**
 * @param productCode
 * @returns {{changeFavorite: ((function(CatalogItem): Promise<void>)|*), enrichedCatalogItems: {itemTags: string, itemAvailability: {msg: *, isAvailable: *}, catalogItem: *}[]}}
 */
export const useRelatedCatalogItems = (productCode) => {
  const [relatedCatalogItemsRemoteData, setRelatedCatalogItemsRemoteData] = useState(initial([]));

  useEffect(() => {
    const fetchProducts = async () => {
      const remoteData = await fetchRelatedProducts(productCode, { page: 0, amount: 15 });
      return mapRemoteData(remoteData, it => it?.content);
    };
    remoteDataWithSetter(setRelatedCatalogItemsRemoteData, fetchProducts).then();
  }, [productCode]);

  return { relatedCatalogItemsRemoteData };
};
