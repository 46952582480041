import ApiClient from '../../API/api';
import type {Order} from '../models/Order';

const ORDER_ENDPOINT = 'account/orders';

const parseOrder = (unsafeOrder): Order => {
  if (!('id' in unsafeOrder)) {
    throw new Error('Invalid Order.');
  }
  if (!('code' in unsafeOrder)) {
    throw new Error('Invalid Order.');
  }
  return unsafeOrder;
};

export class OrderService {
  static placeOrder(addressId: number, userComment: string): Promise<Order> {
    return ApiClient.performRequest(
      ORDER_ENDPOINT,
      { method: 'POST', body: { addressId, userComment } }
    ).then(parseOrder);
  };

  static getAll(): Promise<Order[]> {
    return ApiClient.performRequest(ORDER_ENDPOINT);
  }
}
