import {TitleSeccion} from '../../components/Theme/theme.elements';
import {Outlet} from 'react-router-dom';
import React from 'react';
import {RoutePathBreadcrumb} from '../../ui';
import {AccountMenu} from './AccountMenu';

export const Account = () => {
  return (
    <div className="container">
        <div className="my-4">
          <TitleSeccion>Mi cuenta</TitleSeccion>
          <RoutePathBreadcrumb />
        </div>
        <div className="row pt-3">
          <div className="col-lg-3">
            <AccountMenu />
          </div>
          <div className="col-lg-9">
            <Outlet />
          </div>
        </div>
    </div>
  );
};