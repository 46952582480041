import styled from "styled-components";

export const ContainerLabs = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
`

export const SectionImgLabs = styled.img`
  width: 185.2px;
`

export const ContainerImgLabs = styled.div`
  width: 17.18rem;
  height: 16.75rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 11px 0 #E2E2E2;

  img {
    width: 100%;
    height: auto;
  }
`